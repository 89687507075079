import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FilterItem } from '../../filters.component';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit, OnChanges {
  _filter: FilterItem;
  get filter(): FilterItem {
      return this._filter;
  }
  @Input()
  set filter(value: FilterItem) {
    this._filter = value;
  }
  @Input() value: any;
  @Output() onChange = new EventEmitter();
  lastSelectedIndex: number;
  prices = [
    { symbol: '$', checked: false, range: [0, 50], hint: '$0 - $50' },
    { symbol: '$$', checked: false, range: [50, 100], hint: '$50 - $100' },
    { symbol: '$$$', checked: false, range: [100, 200], hint: '$100 - $200' },
    { symbol: '$$$$', checked: false, range: [200, 99999999], hint: '$200 & above' }
  ];
  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes) {
    this.ref.detectChanges();
    if(changes.value?.currentValue) {
      this.prices = this.prices.map( price => {
        price.checked = changes.value.currentValue.find( i => JSON.stringify(i) === JSON.stringify(price.range));
        return price;
      });
    }
  }

  togglePrices(index: number) {
    this.prices[index].checked = !this.prices[index].checked;
    this.onChange.next({ ...this._filter , value: this.prices.filter(pr=>pr.checked).map(pr=>pr.range)});
  }

}
