import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AppService } from 'src/app/shared/services/app.service';
import { map } from 'rxjs/operators';
import { LocalStorageService, STORAGE_CONSTANTS } from 'src/app/shared/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  
  baseUrl: string;
  selectedStylist: any;

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private storageService: LocalStorageService 
  ) { 
    this.baseUrl = `${this.appService.getAppChatBaseUrl()}`;
  }

  friendsList(search: string='', size: number=25, page: number=0): Observable<any> {
    const advanceFilters = this.storageService.getObject(STORAGE_CONSTANTS.CHAT_ADVANCE_FILTERS, {});
    if(advanceFilters.keywords){
      advanceFilters.keywords = advanceFilters.keywords.join(',');
    }
    return this.http.get(`${this.baseUrl}/friends`, {params:{search,limit:size,offset:page,...advanceFilters}});
  }

  getMessageHistory(friend_id: String, limit: number=20, offset: number=0): Observable<any> {
    return this.http.get(`${this.baseUrl}/friends/${friend_id}/messages?limit=${limit}&offset=${offset}`)
  }

  getRecentMessages(friend_id: String, last_message_at: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/friends/${friend_id}/messages?last_message_at=${last_message_at}`)
  }

  sendMessage(friend_id: String, data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/friends/${friend_id}/message`, data);
  }

  markSeen(unreadMessages: any, friend_id?): Observable<any>  {
    return this.http.patch(`${this.baseUrl}/friends/${friend_id}/messages/mark-read`, unreadMessages);
  }

  markAllSeen(unreadMessages: any, friend_id?): Observable<any>  {
    return this.http.patch(`${this.baseUrl}/friends/${friend_id}/messages`, unreadMessages);
  }

  getPreDefinedMessages(friend_id) {
    return this.http.get(`${this.baseUrl}/friends/${friend_id}/preset-messages`)
    .pipe(
      map( (res: any) => res?.preset_texts)
    );
  }
}
