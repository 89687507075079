<div class="bg-pink">
  <div class="quiz-style-1">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-md-3">
          <div class="card-body my-auto text-center card-center q-page-header vpx-3">
            <h3 class="card-title q-page-header-title" [class.mt-0]="!isDesktop">Which of these styles can you see yourself wearing? <span class="required">*</span></h3>

            <div>
              <p class="card-text q-page-header-sub-title vmb-3" [class.vmt-5]="isDesktop">Choose at least 3 to continue.</p>
              <button [disabled]="selectedStylesCount<3 || loading" class="btn btn-yellow d-flex m-auto quiz_style_1_next" (click)="next()" [ngClass]="{'fb-rb' : !isDesktop}">
                <span class="spinner-border spinner-border-sm my-auto vmr-1" role="status" aria-hidden="true" *ngIf="loading"></span>
                Next
                <!-- <i class="nc-icon nc-minimal-right"> </i> -->
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-9 style-grid" [ngClass]="{'mt-n5' : !isDesktop}">

          <div *ngIf="ploading" class="text-center vmt-5">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span>
          </div>

          <!-- Photo Grid -->
          <div class="row vmb-3" *ngIf="!ploading" [class.px-0]="isMobile">
            <div class="column p-0" *ngFor="let bucket of buckets" [style]="columnStyle">
              <div class="card" [ngClass]="{'shadow': item.checked}" *ngFor="let item of bucket"
                (click)="toggleStyle(item)" ngbTooltip="{{item.style_hint}}" placement="bottom">
                <!-- <i class="fa fa-check selected" aria-hidden="true" *ngIf="item.checked"></i> -->
                <img alt="{{item.style_hint}}" class="card-img-top qatch-styles {{item.bucket_name}} quiz_style_1_image_click" 
                lazyLoadImage url="{{ item.style_image }}" defaultUrl="" id="{{item.style_id}}" [class.selected-img]="item.checked"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>