import { environment } from 'src/environments/environment';

export class Logger {
    
    static debug(message?: any, ...optionalParams: any[]) {
        if(!environment.production) {
            console.debug(message, optionalParams);
        }
    }

    static debugError(message?: any, ...optionalParams: any[]) {
        if(!environment.production) {
            console.error(message, optionalParams);
        }
    }

    static info(message?: any, ...optionalParams: any[]) {
        console.info(message, optionalParams);
    }

    static error(message?: any, ...optionalParams: any[]) {
        console.error(message, optionalParams);
    }

    static warn(message?: any, ...optionalParams: any[]) {
        Logger.error(message, optionalParams);
    }
}