import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QatchComponent } from 'src/app/components/qatch.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Fireworks } from 'fireworks-js'
import { AppService } from 'src/app/shared/services/app.service';
import { User } from "src/app/shared/models/user";
import { ButtonModule } from 'primeng/button';
import { QButtonComponent } from 'src/app/components/q-button/q-button.component';

@Component({
    selector: 'app-you-are-in',
    templateUrl: './you-are-in.component.html',
    styleUrls: ['./you-are-in.component.scss'],
    imports: [CommonModule, ButtonModule, QButtonComponent],
    providers: [DeviceDetectorService],
    standalone: true
})

export class YouAreInComponent extends QatchComponent implements AfterViewInit, OnDestroy {
   
    fireworks: Fireworks;
    constructor (
        protected deviceDetectorService: DeviceDetectorService,
        protected override authService: AuthService,
        private appService: AppService
    ) {
        super(deviceDetectorService, authService);
    }

    override ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        this.authService.guideTour$.next(15000);
        const container = document.querySelector('.fireworks')
        this.fireworks = new Fireworks(container);
        this.fireworks.start();
        setTimeout(() => {
            this.fireworks.updateSize();
        },100)
    }

    override ngOnDestroy(): void {
        this.fireworks.clear();
        super.ngOnDestroy();
    }

    override postAuth(user: User){
        this.isVIPUser = user.is_premium;
    }

    onExplore() {
        this.appService.navigateWithPreserve('discover/collections', {replaceUrl: true});
        this.authService.guideTour$.next(0);
    }

    onSkipTour() {
        this.authService.guideTour$.next(-1);
        this.appService.navigateWithPreserve('discover/collections', {replaceUrl: true});
    }

}

