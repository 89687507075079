<div class="modal-content">
    <q-modal-header title="Change Role" (close)="onClose()" ></q-modal-header>
    <div class="modal-body p-2">
        <div class="w-90 mx-auto">
            <ul class="list-group my-3">
                <li class="list-group-item" *ngFor="let role of roles;" 
                [innerText]="role" [class.active-role]="activeRole === role"
                (click)="onSelectRole(role)"></li>
              </ul>
        </div>
    </div>
</div>