import { Component } from "@angular/core";
import { User } from "src/app/shared/models/user";
import { AppService } from "src/app/shared/services/app.service";

@Component({
    templateUrl: './thank-you.component.html',
})
export class ThankYouComponent {

    ploading: boolean = true;
    IS_RELOADED: string = 'IS_RELOADED';
    user: User;

    constructor(
        private appService: AppService
    ) { 
        this.appService.navigateWithPreserve('you-are-in');
    }
}