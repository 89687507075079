import { QuestionBase } from '../question-base';
import { QuestionBaseConfig } from '../question-base-config';
import { NouiFormatter } from 'ng2-nouislider';

export class CurrencyFormatter implements NouiFormatter {
  to(value: number): string {
      return `$ ${Math.floor(value)}`;
  };

  from(value: string): number {
      return parseInt(value);
  }
}

export class SliderQuestion extends QuestionBase<string> {
  override controlType = 'slider';
  override settings = {
    step: 10,
    connect: true,
    tooltips: [new CurrencyFormatter(), new CurrencyFormatter()],
    range: {
        min: 0,
        max: 500
    },
    margin: 10,
  }

  constructor(config: QuestionBaseConfig<string> = {}) {
    super(config);
    if (config.settings) {
      this.settings = {
        ...config.settings
      };
      if (config.settings.mode && config.settings.mode === 'single') {
        this.settings['tooltips'] = [new CurrencyFormatter()];
      } else {
        this.settings['tooltips'] = [new CurrencyFormatter(), new CurrencyFormatter()];
      }
    }
    try {
      this.answer = JSON.parse(config.answer || config.default_value);
    } catch (error) {
      this.answer = [50];
      console.error(error);
    }
  }
}
