<div class="row g-1 q-card-list">
    <div class="col p-0 m-0 qatch uikit-products-list" *ngFor="let columnProducts of products; let colIndex = index">
        <ng-container *ngFor="let product of columnProducts; let isFirst = first; let rowIndex = index">
            <div class="vp-1 qatch" *ngIf="!product.hasImageLoadingError">
                <uikit-product [productMeta]="productMeta" [loading]="loading" [data]="product" [index]="isFirst ? colIndex : 10" 
                [maxShowIndex]="3" (updateReaction)="onUpdateReaction($event, colIndex, rowIndex)" 
                 lovedCount="{{productMapping[product.product_id]}}" (onError)="product.hasImageLoadingError = true;"
                 (onClick)="onProductClick($event)">
                </uikit-product>
            </div>
        </ng-container>
    </div>
    <div *ngIf="!rawData?.length && !ploading" class="no-record-message">
        <h3>{{displayEmptyMessage}}</h3>
    </div>
</div>

<app-loader [visible]="ploading" class="vmt-5" [text]="loadingText"></app-loader>

<button class="btn btn-zblack btn-just-icon position-fixed" *ngIf="hasScrolled"
    (click)="gotoTop();" style="bottom: 40px;right: 10px;z-index: 1;">
    <i class="bi bi-arrow-up"></i>
</button>