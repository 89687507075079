import _ from 'lodash';
import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { UikitFormModule } from 'src/app/shared/modules/uikit-form/uikit-form.module';
import { QUICKSIGNIN_FORM_FIELDS } from './config.const';
import { RouterModule } from '@angular/router';
import { UikitFormComponent } from 'src/app/shared/modules/uikit-form/uikit-form.component';
import { QModalHeaderComponent } from 'src/app/components/modal/q-modal-header.component';
import { QButtonComponent } from 'src/app/components/q-button/q-button.component';
import { SignupQuestionaireComponent } from '../signup-with-questionaire/signup.component';
import { QuickSignInComponent } from '../quick-sign-in/quick-sign-in.component';

@Component({
    standalone: true,
    selector: 'app-quick-sign-up',
    templateUrl: './quick-sign-up.component.html',
    styleUrls: ['./quick-sign-up.component.scss'],
    imports: [CommonModule, QModalHeaderComponent, UikitFormModule, QButtonComponent, RouterModule],
})
export class QuickSignUpComponent extends SignupQuestionaireComponent {
    formFields = _.cloneDeep(QUICKSIGNIN_FORM_FIELDS);
    @ViewChild(UikitFormComponent) uikitFormComponent: UikitFormComponent;

    override ngOnInit() {}

    override ngAfterViewInit(): void {
        this.convoForm = this.uikitFormComponent.form;
    }

    onClose() {
        this.activeModal.dismiss();
    }

    openSigninPopup() {
      this.onClose();
      this.ngbModal.open(QuickSignInComponent, {
        centered: true, size: 'md', windowClass: 'quick-popup'
      });
    }

}
