// Qatch Constansts

export const QATCH_CONSTS = {
    //PASSWORD_REGEX: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    PASSWORD_REGEX: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,

    STORAGE_KEYS : {
        USER_DETAILS: 'user_details',
        QUIZ_TYPE: 'quiz_type',
        QUIZ_TYPE_SHORT: 'sh',
        QUIZ_TYPE_LONG: 'ln',
        OTA_TOKEN: 'ota_token',
        HOTJAR_USER_ID: '_hjSessionUser_1754892',
    }
}
