<div class="modal-body row m-0 p-0" style="overflow: hidden;border-radius: 10px;">
    <div class="col-md-6 p-0">
        <div class="w-100 h-100 left-banner"
            style="background-image: url(https://firebasestorage.googleapis.com/v0/b/qatchcode-production.appspot.com/o/qatch-home%2FBecomeAVip.png?alt=media);">
        </div>
    </div>
    <div class="col-md-6 text-center p-3 d-flex">
        <div class="m-auto">
            <h5> Become a Qatch Vip for a more personal styling experience </h5>
            <p class="vmt-3">For just $4.99/month, you'll get access to one-on-one styling with a Qatch stylist and
                will be able to request specific outfits and styles as often as you'd like </p>
            <button class="btn rounded-pill border-0 vmy-3 vpx-6 text-dark" style="background-color: #ed9cbb;"
                (click)="goToStripePayment();">
                Upgrade to VIP
            </button>
            <u class="d-block cursor-pointer" (click)="close();">Maybe later</u>
        </div>
    </div>
</div>