<div class="qatch-chat" *ngIf="user && adapter">
	<ng-chat [adapter]="adapter" [userId]="user.uid" [hideFriendsList]="false" (onMessagesSeen)="messageSeen($event)"
		[isViewportOnMobileEnabled]="true" [chatWindowHeaderTemplate]="chatWindowHeaderTemplate"
		[friendsListWindowHeaderTemplate]="friendsListWindowHeaderTemplate" [hssChatConfig]="hssChatConfig"
		[dashboardView]="dashboardView" [isCollapsed]="!dashboardView" [theme]="theme"
		[dashboardHeaderTemplete]="dashboardHeaderTemplete" [dashboardChatHeaderTemplete]="dashboardChatHeaderTemplete">
	</ng-chat>
</div>

<ng-template #msgFooterTemplate let-message="message">
	<div class="chat-msg-footer" *ngIf="message?.reactions?.length">
		<div class="reactions-list">
			<ng-container *ngIf="message.reactions">
				<div class="reaction" *ngFor="let reaction of message.reactions | slice:0:2" [innerHTML]="reaction">
				</div>
				<div class="reaction" *ngIf="message.reactions.length > 2">+ {{message.reactions.length - 2}}</div>
			</ng-container>
		</div>
	</div>
</ng-template>

<ng-template #chatWindowHeaderTemplate let-chatWindowRef="window" let-self="self">
	<div class="ng-chat-title shadowed justify-content-between secondary-background hss-chat-window-header">
		<div class="ng-chat-title-visibility-toggle-area">
			<div *ngIf="!chatWindowRef.getChatWindowAvatar()" class="icon-wrapper">
				<i class="user-icon"></i>
			</div>
			<img *ngIf="chatWindowRef.getChatWindowAvatar()" alt="" class="avatar user-profile-img" height="25"
				width="25" [src]="chatWindowRef.getChatWindowAvatar()" />
			<strong [title]="chatWindowRef.participant.displayName"
				[innerText]="chatWindowRef.participant.displayName"></strong>
			<span *ngIf="_hssChatConfig.showAvailabilityStatus"
				[ngClass]="{'ng-chat-participant-status': true, 'online': chatWindowRef.participant.status == ChatParticipantStatus.Online, 'busy': chatWindowRef.participant.status == ChatParticipantStatus.Busy, 'away': chatWindowRef.participant.status == ChatParticipantStatus.Away, 'offline': chatWindowRef.participant.status == ChatParticipantStatus.Offline}"
				[title]="chatParticipantStatusDescriptor(chatWindowRef.participant.status)"></span>
			<span *ngIf="self.unreadMessagesTotal(chatWindowRef).length > 0"
				class="ng-chat-unread-messages-count unread-messages-counter-container primary-text"
				(click)="self.scrollDownToUnseenMessages()">
				{{ self.unreadMessagesTotal(chatWindowRef) }}
				<i class="pi pi-arrow-down"></i>
			</span>
		</div>
		<i class="pi pi-ellipsis-v my-auto mx-1" (click)="settingDropdown.toggle($event)"></i>
		<p-overlayPanel #settingDropdown baseZIndex="999999" styleClass="chat-window-setting-overlay-panel">
			<ng-template pTemplate>
				<p-listbox [options]="chatWindowsettings"
					(onChange)="onSettingSelect($event,self);settingDropdown.hide();" optionLabel="title"
					optionValue="id"></p-listbox>
			</ng-template>
		</p-overlayPanel>
		<i class="pi pi-chevron-{{chatWindowRef.isCollapsed ? 'up' : 'down'}} toggle-chat-window mx-1"
			(click)="self.onChatWindowClicked(chatWindowRef)"></i>
		<i class="pi pi-times close-chat-window mx-1" (click)="self.onCloseChatWindow()"></i>
	</div>
</ng-template>

<ng-template #friendsListWindowHeaderTemplate let-self="self">
  <div class="d-flex justify-content-between align-items-center ng-chat-title shadowed">
    <span [innerText]="_hssChatConfig.dashboard.title"></span>
    <div class="d-flex align-items-center">
		<div class="d-flex align-items-center position-relative" *ngIf="!self.isCollapsed">
		  <app-remote-dropdown [filter]="remoteUserSearchConfig" [isIndividual]="true"
		    (onChange)="onFiltersValueChange($event)" [config]="filtersConfig">
		  </app-remote-dropdown>
		  <button pButton pRipple title="New chat" type="button" icon="bi bi-envelope-plus"
		    class="p-button-rounded p-button-text p-button-plain p-button-lg ms-1"
			(click)="onNewChatClick($event);"></button>
		  <button pButton pRipple title="Advance filter" type="button" icon="bi bi-funnel"
		    class="p-button-rounded p-button-text p-button-plain p-button-lg ms-1"
		    (click)="openAdvanceChatFilterView();"></button>
		</div>
      <div class="d-flex" *ngIf="!dashboardView">
        <div class="d-flex border m-1" *ngIf="!self.isCollapsed"></div>
        <button pButton pRipple title="Open in new tab" type="button" icon="pi pi-window-maximize"
          class="p-button-rounded p-button-text p-button-plain p-button-lg ms-1" (click)="openDashboardView();">
        </button>
        <button pButton pRipple title="{{self.isCollapsed? 'Maximize':'Minimize'}}" type="button"
          icon="bi bi-chevron-{{self.isCollapsed? 'down':'up'}}"
          class="p-button-rounded p-button-text p-button-plain p-button-lg ms-1" (click)="self.onChatTitleClicked()">
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="self.currentActiveOption" class="ng-chat-people-actions"
    (click)="self.onFriendsListActionCancelClicked()">
    <a href="javascript:void(0);" class="ng-chat-people-action">
      <i class="remove-icon"></i>
    </a>
    <a href="javascript:void(0);" class="ng-chat-people-action" (click)="self.onFriendsListActionConfirmClicked()">
      <i class="check-icon"></i>
    </a>
  </div>
</ng-template>

<ng-template #dashboardHeaderTemplete let-self="self">
	<div class="flex justify-content-between align-items-center p-2 h-full">
		<div class="flex col-6 justify-content-start">
			<div class="dashboard-sidebar-toggler my-auto" (click)="self.sidebarVisible = !self.sidebarVisible;">
				<i class="pi pi-bars p-1" style="font-size: 1.5rem"></i>
			</div>
			<!-- <strong class="my-auto">Qatch</strong> -->
			<img class="my-auto logo-image" src="./assets/img/qatch-logo.png">
		</div>
		<div class="flex col-6 justify-content-end">
			<p-avatar [label]="userNameFirstChar" styleClass="mr-2" size="medium" shape="circle">
			</p-avatar>
		</div>
	</div>
</ng-template>

<ng-template #dashboardChatHeaderTemplete let-window="window">
	<div style="z-index: 2;">
		<p-avatar icon="{{window.getChatWindowAvatar() ? '' : 'pi pi-user'}}" [image]="window.getChatWindowAvatar()"
			styleClass="mr-1" shape="circle"></p-avatar>
		<span *ngIf="_hssChatConfig.showAvailabilityStatus"
			[ngClass]="{'ng-chat-participant-status': true, 'online': window.participant.status == chatParticipantStatus.Online, 'busy': window.participant.status == chatParticipantStatus.Busy, 'away': window.participant.status == chatParticipantStatus.Away, 'offline': window.participant.status == chatParticipantStatus.Offline}"
			[title]="chatParticipantStatusDescriptor(window.participant.status)"></span>
	
		<span class="pr-3 mr-2" [title]="window.participant.displayName"
			[innerText]="window.participant.displayName"></span>
		<i class="pi pi-ellipsis-v my-auto mx-1" (click)="settingDropdown.toggle($event)"></i>
		<p-overlayPanel #settingDropdown baseZIndex="999999" styleClass="chat-window-setting-overlay-panel">
			<ng-template pTemplate>
				<p-listbox [options]="chatWindowsettings"
					(onChange)="onSettingSelect($event,{window});settingDropdown.hide();" optionLabel="title"
					optionValue="id"></p-listbox>
			</ng-template>
		</p-overlayPanel>
	</div>
</ng-template>