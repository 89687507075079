import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersComponent } from './filters.component';
import { SharedModule } from '../shared.module';
import { ReactionComponent } from './filter-types/reaction/reaction.component';
import { DropdownComponent } from './filter-types/dropdown/dropdown.component';
import { PriceComponent } from './filter-types/price/price.component';
import { CheckboxComponent } from './filter-types/checkbox/checkbox.component';
import { DatepickerComponent } from './filter-types/datepicker/datepicker.component';
import { TextInputComponent } from './filter-types/text-input/text-input.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { RemoteSearchDropdownComponent } from './filter-types/remote-search/dropdown.component';
import { DropdownSwitchComponent } from './filter-types/dropdown-switch/dropdown-switch.component';

const FILTER_COMPONENTS = [
  FiltersComponent,
  ReactionComponent,
  DropdownComponent,
  PriceComponent,
  CheckboxComponent,
  DatepickerComponent,
  TextInputComponent,
  DropdownSwitchComponent
]

@NgModule({
  declarations: FILTER_COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    RemoteSearchDropdownComponent,
    NgbDatepickerModule
  ],
  exports: [RemoteSearchDropdownComponent, ...FILTER_COMPONENTS]
})
export class FiltersModule { }
