import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IConvoCard } from './q-convo-card/q-convo-card.component';
import { AppService } from 'src/app/shared/services/app.service';

export type QConvoTypes = 'CAROUSEL' | 'FORM';

@Component({
  selector: 'q-convo',
  templateUrl: './q-convo.component.html',
  styleUrls: ['./q-convo.component.scss']
})
export class QConvoComponent implements OnChanges {
  @Input() isMobile = false;
  @Input() loading = false;
  @Input() type: QConvoTypes = 'FORM';
  @Input() convoCards: IConvoCard[] = [];
  @Input() colors: string[] = ['#ba9ff4', '#eb602f', '#be7cc6', '#9da561'];
  @Output() onUpdate = new EventEmitter<{index: number, convoCards: IConvoCard[]}>();
  constructor(
    protected appService: AppService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['convoCards']?.currentValue) {
       this.convoCards = this.convoCards.map( (card, index) => {
          if(!card.bg_color){
            card.style = {backgroundColor: this.getColor(index)};
          }
          else {
            card.style = {backgroundColor: card.bg_color}
          }
          return card;
       });
    }
  }

  getColor(index) {
    const color = this.colors[index];
    if(color) {
      return color;
    } else {
      let rmdr = index%this.colors.length;
      return this.colors[rmdr];
    }
  }

  onCardClick(indx?) {
    this.onUpdate.emit({index: indx, convoCards: this.convoCards});
  }

  onImageLabelClick(event) {
    this.appService.navigate( 'vip', { queryParamsHandling: "merge" });
  }

}
