import { Component, Input } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'uikit-wave',
  templateUrl: './uikit-wave.component.html',
  styleUrls: ['./uikit-wave.component.scss']
})
export class UikitWaveComponent {
  @Input() svgWaveText: any[] = _.fill(Array(80), '');
}
