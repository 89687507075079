<div class="input-group form-group-no-border filter-item d-flex justify-content-between" [class.individual-filter]="isIndividual">
    <label class="label text-end" [title]="filter.label" [innerText]="filter.label" 
    [class.required-field]="filter.required"></label>
    <ngb-dropdown [data]="data" 
        (onSelect)="valueChange()" 
        (onDeSelect)="valueChange()"
        (onDeSelectAll)="valueChange()"
        [(ngModel)]="value"
        class="bg-default shadow {{ isIndividual ? '' : 'w-200px' }}  mr-n2" [settings]="dropdownSettings">
    </ngb-dropdown>
</div>