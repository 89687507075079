import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, map } from 'rxjs'
import { LocalStorageService } from './storage.service'
import { Style } from '../models/style'
import { AppService } from './app.service'
import { QuestionBase } from 'src/app/components/questions/types/question-base'

export interface IQuizResponse {
  questions: QuestionBase<string>[], 
  user_answers: any[]
}

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  KEY_STYLES = 'local.quiz.styles'
  KEY_STYLE_CHOICE = 'local.quiz.style.choice'
  KEY_TEXT_LIKE_BFF_PAGE_INFO = 'local.quiz.style.textlikebff'
  KEY_ONBOARDING_QUIZ = 'local.quiz.onboarding'
  KEY_EXTRA_INFO = 'local.quiz.extra_info'
  KEY_PHONE_VERIFIED = 'local.quiz.phone.verified'

  baseUrl: string;

  constructor (
    private http: HttpClient,
    private storageService: LocalStorageService,
    private appService: AppService
  ) {
    this.baseUrl = `${this.appService.getAppBaseUrl()}/quiz`;
  }

  getStyles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/styles`)
  }

  getOnboardingQuestions(type?: string): Observable<any> {
    if (type && type.length > 3) {
      return this.http.get(`${this.baseUrl}/onboarding-questions?quiz_page=${type}`);
    }
    return this.http.get(`${this.baseUrl}/onboarding-questions`);
  }

  getQuestionAnswers(page?: string): Observable<IQuizResponse> {
    if (page && page.length > 3) {
      return this.http.get<IQuizResponse>(`${this.baseUrl}/onboarding-questions?quiz_page=${page}`);
    }
    return this.http.get<IQuizResponse>(`${this.baseUrl}/onboarding-questions`);
  }

  getOnboardingBrands(type?: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/onboarding-brands`);
  }

  writeStyleChoice(styleChoice: any) {
    this.storageService.set(this.KEY_STYLE_CHOICE, JSON.stringify(styleChoice));
  }

  readStyleChoice(): any {
    return JSON.parse(this.storageService.get(this.KEY_STYLE_CHOICE));
  }

  writeTextLikeBffPageInfo(textLikeBffPageInfo: any) {
    this.storageService.set(this.KEY_TEXT_LIKE_BFF_PAGE_INFO, JSON.stringify(textLikeBffPageInfo));
  }

  readTextLikeBffPageInfo(): any {
    return JSON.parse(this.storageService.get(this.KEY_TEXT_LIKE_BFF_PAGE_INFO));
  }

  writeStyles(styles: Style[]) {
    this.storageService.set(this.KEY_STYLES, JSON.stringify(styles));
  }

  readStyles(): Style[] {
    return JSON.parse(this.storageService.get(this.KEY_STYLES));
  }

  writeQuizOnboardingInfo(quizOnboardingInfo: object) {
    this.storageService.set(this.KEY_ONBOARDING_QUIZ, JSON.stringify(quizOnboardingInfo));
  }

  readQuizOnboardingInfo() {
    return JSON.parse(this.storageService.get(this.KEY_ONBOARDING_QUIZ));
  }

  writeExtraInfo(extraInfo: object) {
    this.storageService.set(this.KEY_EXTRA_INFO, JSON.stringify(extraInfo));
  }

  readExtraInfo() {
    return JSON.parse(this.storageService.get(this.KEY_EXTRA_INFO));
  }

  writePhoneVerified() {
    this.storageService.set(this.KEY_PHONE_VERIFIED, 'true');
  }

  readPhoneVerified() {
    return this.storageService.get(this.KEY_PHONE_VERIFIED) != null ? true : false;
  }

  clearAll() {
    this.storageService.remove(this.KEY_EXTRA_INFO);
    this.storageService.remove(this.KEY_ONBOARDING_QUIZ);
    this.storageService.remove(this.KEY_STYLES);
    this.storageService.remove(this.KEY_STYLE_CHOICE);
    this.storageService.remove(this.KEY_TEXT_LIKE_BFF_PAGE_INFO);
    this.storageService.remove(this.KEY_PHONE_VERIFIED);
  }
}
