<div class="page-header">
    <div class="container">
        <div class="row">
            <div class="mx-auto" *ngIf="verifyForm">
                <div class="author text-center w-100 q-page-header">
                    <ng-container *ngIf="isDesktop">
                        <h1 class="title mt-0 vmb-2 q-page-header-title">Verify Your Phone</h1>
                        <span class="underline-text-dark w-250px"></span>
                    </ng-container>
                    <label class="label vmt-2 text-center" *ngIf="phoneNumberAccepted">
                        The verification code was sent to <span class="phone-number">{{maskedPhone}}</span>
                        <br /><div class="vmt-1">Please enter the verification code to proceed!</div>
                    </label>
                    <label class="label vmt-2 text-center" *ngIf="!phoneNumberAccepted">
                        A verification code will be sent to your device.
                    </label>
                </div>
                <div class="vmy-3 mt-0 q-page-container">
                    <form class="register-form vmt-6 mx-auto" [formGroup]="verifyForm" (ngSubmit)="submit()">
                        <div id="captcha-container"></div>

                        <div *ngIf="!phoneNumberAccepted">
                            <div class="form-group mb-0">
                                <label class="label">Phone Number *</label>
                                <div class="input-group form-group-no-border bg-default shadow">
                                    <span class="input-suffix">{{countryCode}}</span>
                                    <input type="text" class="form-control box-shadow-none verify_number_verify_my_number" appPhoneMask
                                        formControlName="phone_number" placeholder="(xxx)xxx-xxxx">
                                </div>
                            </div>
                            <div *ngIf="phone_number && !phone_number.valid && (phone_number.dirty || phone_number.touched)"
                                class="alert alert-danger">
                                <div *ngIf="phone_number.errors">
                                    Please enter valid phone number.
                                </div>
                            </div>
                        </div>

                        <div *ngIf="phoneNumberAccepted" class="text-center">
                            <div class="form-group-no-border">
                                <ng-otp-input (onInputChange)="onOtpChange($event)"
                                    [config]="{length:6,allowNumbersOnly:true}" class="mx-auto otp verify_number_verify_otp">
                                </ng-otp-input>
                            </div>
                        </div>

                        <div class="form-row justify-content-center">
                            <button *ngIf="phoneNumberAccepted" [disabled]="!verifyForm.valid || loading || ploading"
                                type="submit" class="btn btn-danger btn-move-right vml-1 vmt-6 verify_number_submit_otp_code">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="loading || ploading"></span>
                                Verify Code
                            </button>
                            <button *ngIf="!phoneNumberAccepted" [disabled]="!phone_number.valid || loading || ploading"
                                (click)="sendVerificationCode()" class="btn btn-danger btn-move-right vml-1 vmt-6 verify_number_send_verify_code">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="loading || ploading"></span>
                                Send Verification Code
                            </button>
                        </div>

                        <div class="text-center" *ngIf="phoneNumberAccepted">
                            <p class="vmt-3">
                                <label class="label">
                                    Didn’t receive a verification code?
                                    <br/>
                                    <a *ngIf="isDesktop; else resendCode" class="btn btn-neutral vmt-1 txt-dark verify_number_resend_verify_otp" (click)="sendVerificationCodeOnMobile()">
                                        <u>Resend Verification Code</u>
                                    </a>
                                    <ng-template #resendCode>
                                        <a class="btn vmt-1 btn-danger">
                                            Resend Verification Code
                                        </a>
                                    </ng-template>
                                </label>
                            </p>
                        </div>

                        <div class="text-center vmt-3">
                            <a (click)="previousPage()" class="btn btn-dark btn-link verify_number_back">
                                <u class="verify_number_back">Back</u>
                            </a>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    </div>
    <div class="top-left-img">
        <img src="./assets/img/organic/Shape-3.png" />
    </div>
    <div class="bottom-right-img">
        <img src="./assets/img/organic/Shape-4.png" />
    </div>
</div>