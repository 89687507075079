import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-input-panel',
  templateUrl: './input-panel.component.html',
  styleUrls: ['./input-panel.component.scss'],
  standalone: true,
  imports:[CommonModule, PickerModule, NgbDropdownModule],
})
export class InputPanelComponent implements OnInit {
  @Input() cursorLocation = 0;
  sheetSize: 16 | 20 | 32 | 64 | 72 = 32;
  @Input() value = '';
  @Output() valueChange = new EventEmitter();
  @ViewChild('emojiDrop', { static: true }) emojiDrop: NgbDropdown;

  constructor() { }

  ngOnInit(): void {}

  addEmoji({ emoji }) {
    this.value = `${this.value?.slice(0, this.cursorLocation)}${emoji.native}${this.value.slice(this.cursorLocation)}`;
    this.valueChange.emit(this.value);
    this.close();
  }

  close(){
    this.emojiDrop.close();
  }
}