import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { APP_CONFIG } from 'src/app/app.const';
import { Debounce } from 'src/app/shared/decorators/debounce.decorator';
import { AppService } from 'src/app/shared/services/app.service';
import { FilterItem, HostFunction } from '../../filters.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AngularMultiSelect, AngularMultiSelectModule } from 'ngb-dropdown';
@Component({
  selector: 'app-remote-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule,
    AngularMultiSelectModule, 
  ]
})
export class RemoteSearchDropdownComponent implements OnInit, OnChanges {
  loading = false;
  _filter: FilterItem;
  get filter(): FilterItem {
      return this._filter;
  }
  @Input() 
  set filter(value: FilterItem) {
    this._filter = value;
  }
  @Input() isIndividual: boolean;
  @Output() onChange = new EventEmitter();
  data = [];
  @Input() value: any;
  @Input() config = {};
  url: string;
  dropdownSettings = {
    text: 'Please Select',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'ms-bg',
    singleSelection: true,
    limitSelection: 5,
    badgeShowLimit: 1,
    noDataLabel: '',
    resetOnOpen: false
  };
  @ViewChild(AngularMultiSelect) ngbDropdown: AngularMultiSelect;
  constructor(
    private http: HttpClient,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    if (this.config) {
      this.dropdownSettings = {
        ...this.dropdownSettings,
        ...this.config
      }
    }
    this.dropdownSettings.singleSelection = this.filter.singleSelection ? true : false;
    this.dropdownSettings.noDataLabel = this.filter.noDataLabel || '';
    if(!this.value?.length) {
      this.fetchOptionsList({target: {value: ''}}, null, false);
    }
  }

  formatData(tdata: any[]){
    return tdata.map( (i: { [x: string]: any; }) => {
      return {
        id: this.filter.filterKey ? i[this.filter.filterKey] : i,
        itemName: this.getItemName(i)
      }
    });
  }

  getItemName(item: { [x: string]: any; }) {
    let itemName;
    if(this.filter.displayKey) {
      itemName = item[this.filter.displayKey];
    } else if(this.filter.displayFunction) {
      itemName = this.filter.displayFunction(item);
    } else {
      itemName = item;
    }
    return itemName;
  }

  ngOnChanges(changes) {
    if (changes.value && changes.value.firstChange && changes.value?.currentValue?.length) {
      const value: string = changes.value.currentValue.map(op => op['id']).join(',');
      this.fetchOptionsList({target: {value}}, (data) => {
        this.value = data.filter(op => value.includes(op['id']));
        this.valueChange();
      }, true);
    }
  }

  valueChange(event?) {
    this.onChange.next({ ...this._filter , value: this.value});
  }

  @Debounce(APP_CONFIG.DEBOUNCE_INTERVAL_LARGE)
  onSearch(evt: any, cb?, prepopulate = false) {
    this.fetchOptionsList(evt, cb, prepopulate);
  }

  fetchOptionsList(evt: any, cb?, prepopulate = false) {
    if(!this._filter || !this._filter.url || !this._filter.url.length)return;
    this.url = this.getUrl();
    this.loading = true;
    const params = this.getParams(evt.target.value, prepopulate);
    this.http.get(this.url, { params }).subscribe((resp: any) => {
      const dataKey = this._filter.dataKey || '';
      const tdata = resp.hasOwnProperty(dataKey) ? resp[dataKey] : resp;
      const data: any = this.formatData(tdata);
      this.data = data;
      this.loading = false;
      cb ? cb(data) : '';
    });
  }

  getParams(searchText: string, prepopulate: boolean) {
    let params = new HttpParams();
    params = params.set('searchString', prepopulate ? '' : searchText)
    .set('selected_items', prepopulate ? searchText : '').set('limit', `100`);
    return params;
  }

  getUrl(){
    if(this._filter.host){
      switch(this._filter.host){
        case HostFunction.DASHBOARD:
          return `${(this.appService.getDashboardBaseUrl())}/${this._filter.url}`;
        case HostFunction.CHAT:
          return `${(this.appService.getAppChatBaseUrl())}/${this._filter.url}`;
        default:
          return `${(this.appService.getAppBaseUrl())}/${this._filter.url}`;
      }
    }else{
      return `${(this.appService.getAppBaseUrl())}/${this._filter.url}`;
    }
  }

  onOpen() {
     if(this.dropdownSettings.resetOnOpen) {
       this.value = [];
     }
  }

}
