<div id="layout-config" *ngIf="view==='sidebar'" class="layout-config" [ngClass]="{'layout-config-active': menuActive}"
    (click)="onConfigClick($event)">
    <a style="cursor: pointer" id="layout-config-button" class="layout-config-button"
        (click)="onConfigButtonClick($event)">
        <i class="pi pi-cog"></i>
    </a>
    <button pButton pRipple type="button" icon="pi pi-times"
        class="p-button-rounded p-button-danger p-button-text layout-config-close"
        (click)="onConfigButtonClick($event)"></button>
    <div class="layout-config-content">
        <h6 class="mt-0">Scale</h6>
        <div class="config-scale" *ngIf="config">
            <button pButton pRipple type="button" icon="pi pi-minus" (click)="decrementScale()"
                class="p-button-rounded p-button-text" [disabled]="config.scale === scales[0]"></button>
            <i *ngFor="let item of scales" class="pi pi-circle-fill"
                [ngClass]="{'scale-active': item === config.scale}"></i>
            <button pButton pRipple type="button" icon="pi pi-plus" (click)="incrementScale()"
                class="p-button-rounded p-button-text" [disabled]="config.scale === scales[scales.length - 1]"></button>
        </div>

        <h6>Theme</h6>
        <div class="grid">
            <div class="col-3 text-center">
                <p-avatar styleClass="mr-2 p-link" size="large" *ngIf="config.dark"
                    [style]="{'background-color': 'rgb(224 224 224)', 'color': 'rgb(7 21 37)', 'font-weight': 'bolder'}"
                    shape="circle" icon="pi pi-sun" (click)="config.dark = false;changeTheme()">
                </p-avatar>
                <p-avatar styleClass="mr-2 p-link" size="large" *ngIf="!config.dark"
                    [style]="{'background-color': 'rgb(92 89 89)', 'color': 'rgb(223 225 227)', 'font-weight': 'bolder'}"
                    shape="circle" icon="pi pi-moon" (click)="config.dark = true;changeTheme()">
                </p-avatar>
            </div>
        </div>
    </div>
</div>

<div *ngIf="view==='icon'" id="theme-config-icon" title="Change Theme" class="absolute cursor-pointer z-5"
    style="top: 0.55rem; right: 1.5rem;">
    <div class="text-center">
        <p-avatar styleClass="p-link" size="small" *ngIf="config.dark"
            [style]="{'background-color': '#00000000', 'color': 'rgb(224 224 224)', 'font-weight': 'bolder'}"
            shape="circle" icon="pi pi-sun" (click)="config.dark = false;changeTheme()">
        </p-avatar>
        <p-avatar styleClass="p-link" size="small" *ngIf="!config.dark"
            [style]="{'background-color': '#00000000', 'color': 'rgb(92 89 89)', 'font-weight': 'bolder'}"
            shape="circle" icon="pi pi-moon" (click)="config.dark = true;changeTheme()">
        </p-avatar>
    </div>
</div>