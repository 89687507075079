import { Component, OnInit, Input, OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';
import { Subscription, finalize, fromEvent, lastValueFrom, takeWhile } from 'rxjs';
import { GroupByColumnPipe } from 'src/app/shared/pipes/group-by-column.pipe';
import { ToastService } from 'src/app/shared/services/toast.service';
import { IProductMeta } from '../uikit-product/uikit-product.component';
import { environment } from 'src/environments/environment';
import { ProductService } from '../../services/product.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { LocalStorageService } from '../../services/storage.service';
import { QuickSignUpComponent } from 'src/app/pages/auth/quick-sign-up/quick-sign-up.component';
import { PRODUCT_CLICK_COUNT, PRODUCT_REACTION_CLICK_COUNT } from 'src/app/app.const';
import { QuickQuizComponent } from 'src/app/components/quick-quiz/quick-quiz.component';

@Component({
  selector: 'uikit-product-list',
  templateUrl: './uikit-product-list.component.html',
  styleUrls: ['./uikit-product-list.component.scss'],
  providers: [GroupByColumnPipe]
})
export class UikitProductListComponent implements OnInit, OnDestroy {
  products: any[] = [];
  windowScrollRef: Subscription;
  limit = 40;
  page = 1;
  ploading = true;
  loading = false;
  hasMoreData = true;
  rawData = [];
  hasScrolled = false;
  @Input() fetchProducts: Function;
  @Input() productMeta: IProductMeta;
  @Input() resultKey = 'data';
  @Input() loadingText = 'loading...';
  @Input() displayEmptyMessage = 'No matching product found!!!';
  @Output() updateReaction = new EventEmitter();
  isDestroyed = false;
  productMapping = {};
  productClickCount = 0;
  productReactionClickCount = 0;
  maxClicksAllow = 2;
  loggedInUser: any;

  constructor(
    private toastService: ToastService,
    private groupByColumn: GroupByColumnPipe,
    private elmRef: ElementRef,
    private productService: ProductService,
    private modalService: NgbModal,
    private userService: UserService,
    protected authService: AuthService,
    private lss: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.windowScrollRef = fromEvent(window, 'scroll').subscribe(() => {
      this.hasScrolled = window.pageYOffset > 500;
      const productElms = this.elmRef.nativeElement.querySelector('.uikit-products-list')?.querySelectorAll('uikit-product');
      if (productElms?.length > 2) {
        const secLastProductLoaded = productElms.item(productElms.length - 3).querySelector('.Q_ITEM_LOADED');
        if (secLastProductLoaded && this.hasMoreData && !this.ploading) {
          this.loadLazyProducts(true);
        }
      }
    });
    this.loadLazyProducts(false);
    this.authService.user$$.subscribe(async (user) => {
      this.loggedInUser = user;
    });
  }

  loadLazyProducts(onScroll = false) {
    this.ploading = true;
    if (!onScroll) {
      this.page = 1;
      this.hasMoreData = true;
      this.rawData = [];
      this.products = [];
    }
    this.fetchProducts({ limit: this.limit, page: this.page })
      .pipe(
        takeWhile(i => !this.isDestroyed),
        finalize(() => {
          this.ploading = false;
        })
      )
      .subscribe({
        next: res => {
          const data = res[this.resultKey];
          if (data?.length) {
            this.getProductsWithLoveCount(data.map(p => p.product_id));
          }
          this.rawData = [...this.rawData, ...data];
          this.runDuplicateTest();
          const transFormedProducts: any[] = this.groupByColumn.transform(res[this.resultKey] || []);
          if (this.page > 1) {
            transFormedProducts.forEach((column, index) => {
              column.forEach(product => {
                this.products[index].push(product);
              });
            });
          } else {
            this.products = transFormedProducts;
          }
          this.page = this.page + 1;
          this.hasMoreData = data?.length ? true : false;
        },
        error: (err) => {
          this.toastService.error(`Unable to load list. Please try again.`);
        },
      });
  }

  getProductsWithLoveCount(productIds) {
    this.productService.getProductsWithLoveCount(productIds)
      .pipe(takeWhile(() => !this.isDestroyed))
      .subscribe((res: { product_id: string, loved: number }[]) => {
        this.productMapping = (res || []).reduce((o, n) => {
          o[n.product_id] = n.loved;
          return o;
        }, this.productMapping);
      });
  }

  async runDuplicateTest() {
    if (!environment.production) {
      var collectionsSize = this.rawData.length;
      var collections = this.rawData.map(coll => coll.product_id);
      var set = new Set(collections);
      var dset = new Set(collections);
      collections.filter(item => {
        if (dset.has(item)) {
          dset.delete(item);
        } else {
          return item;
        }
      });
      if (collectionsSize !== set.size) {
        console.error('Duplicate check failed');
      }
    }
  }

  async onUpdateReaction({product, requestData}, columnIndex, rowIndex) {
    this.productClickCounter('productReactionClickCount', PRODUCT_REACTION_CLICK_COUNT);
    
    if (this.productReactionClickCount > this.maxClicksAllow && !this.loggedInUser['style_quiz_complete']) {
      this.openQuizPopup();
    } else{
      try {
        this.loading = true;
        await lastValueFrom(this.userService.addToMyQatchWithReaction(requestData));
        product.reaction_type = requestData['reaction_type'];
        if (this.productMeta.page === 'my-qatch' && product.reaction_type === 'Disliked') {
          this.products[columnIndex].splice(rowIndex, 1);
          const transFormedProducts: any[] = this.groupByColumn.transform(this.products.reduce((all, current) => {
            all = [...all, ...current];
            return all;
          }, []));
          this.products = transFormedProducts;
        }
        this.loading = false;
      } catch (err) {
        this.toastService.error(err, `Your reaction couldn't be updated.`);
        this.loading = false;
      } finally {
        this.updateReaction.emit(this.page);
      }
    }
  }

  openQuizPopup() {
    this.modalService.open(QuickQuizComponent, {
      centered: true, size: 'lg', windowClass: 'quick-popup'
    });
  }

  gotoTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  openQuickSignUp() {
    this.modalService.open(QuickSignUpComponent, {
      centered: true, size: 'md', windowClass: 'quick-popup'
    });
  }

  productClickCounter(key: string, storageKey: string) {
    this[key] = parseInt(this.lss.get(storageKey, '0'));
    this[key]++;
    this.lss.set(storageKey, `${this[key]}`);
  }

  onProductClick(product: any) {
    const isLoggedIn = this.authService.isLoggedIn();
    if (product.availability_id === 2 || !product.affiliate_link) return;
    this.productClickCounter('productClickCount', PRODUCT_CLICK_COUNT);
    if (this.productClickCount > this.maxClicksAllow && !isLoggedIn) {
      this.openQuickSignUp();
    } else {
      window.open(product.affiliate_link, '_blank');
      this.userService.logUserClick(product).subscribe();
    }
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
    this.windowScrollRef?.unsubscribe();
  }
}
