import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrls: ['./manage-roles.component.scss']
})
export class ManageRolesComponent implements OnInit {
  @Input() roles = [];
  @Output() close = new EventEmitter();
  activeRole = '';
  constructor(private activeModal: NgbActiveModal, private authService: AuthService) { 
    
  }

  ngOnInit(): void {
    this.activeRole = this.authService.getActiveRole();
    if (!this.roles.includes(this.activeRole)) {
      this.activeRole = this.roles[0];
    }
  }

  onClose() {
    this.activeModal.dismiss('Cross click');
  }

  onSelectRole(role) {
    if (this.activeRole !== role) {
      this.authService.setActiveRole(role); 
    }
    this.onClose();
  }

}
