import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Carousel } from 'primeng/carousel';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

export interface SMSProps {
  title: string;
  subTitle: string;
  message: string;
  icon: string;
  time: string;
}

export enum SMSAnimationDirection {
   LTR = 'left-to-right',
   RTL = 'right-to-left',
   ALR = 'alternate'
}

@Component({
  selector: 'lib-sms-gallery',
  templateUrl: './sms-gallery.component.html',
  styleUrls: ['./sms-gallery.component.scss']
})
export class SmsGalleryComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() smsList: SMSProps[] = [];
  @Input() direction: SMSAnimationDirection = SMSAnimationDirection.LTR;
  @ViewChild(Carousel) carousel: Carousel;
  isComponentDestroyed = false;
  moveDefDir = true;
  isMobile = false;
  constructor() { 
    this.isMobile = (innerWidth < 600);
  }

  ngOnInit(): void {
    interval(( this.isMobile ? 4 : 6) * 1000).pipe(takeWhile(() => !this.isComponentDestroyed)).subscribe(() =>{
      if (this.carousel.isForwardNavDisabled()) {
        this.moveDefDir = false;
      }
      if (this.carousel.isBackwardNavDisabled()) {
        this.moveDefDir = true;
      }
      if (this.moveDefDir) {
        this.carousel.navForward(null);
      } else {
        this.carousel.navBackward(null);
      }
    })
  }

  ngAfterViewInit(): void {
    if (this.direction === SMSAnimationDirection.RTL) {
      this.carousel.page = this.carousel.totalDots();
    }
  }

  ngOnDestroy(): void {
      this.isComponentDestroyed = true;
  }

}
