import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LocalStorageService } from 'src/app/shared/services/storage.service';
import { QATCH_CONSTS } from 'src/app/shared/utils/qatch-constants';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppCookieService } from '../shared/services/cookie.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  TOKEN_HEADER_KEY = 'Authorization';
  OTA_TOKEN_HEADER_KEY = 'ota-token';
  ota_token: string;

  constructor(
    private authService: AuthService,
    private storageService: LocalStorageService,
    private route: ActivatedRoute,
    private deviceDetectorService: DeviceDetectorService,
    private cookieService: AppCookieService
  ) {
    this.route.queryParams.subscribe(params => {
      this.ota_token = this.storageService.get(QATCH_CONSTS.STORAGE_KEYS.OTA_TOKEN) || params['ota_token'];
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = this.extendedHeaders();
    const body = this.extendedBody(request);

    if (headers || body) {
      const authReq = request.clone({
        headers: headers || request.headers,
        body: body || request.body
      });
      return next.handle(authReq);
    } else {
      return next.handle(request);
    }
  }

  extendedHeaders() {
    const reqHeaders = [];

    const accessToken = this.authService.getAuthToken();
    if (accessToken) {
      reqHeaders.push({ key: this.TOKEN_HEADER_KEY, value: `Bearer ${accessToken}` });
    }

    if (this.ota_token) {
      reqHeaders.push({ key: this.OTA_TOKEN_HEADER_KEY, value: `${this.ota_token}` });
    }

    
    const HOTJAR_USER_ID = this.extractHotjarUserIdIfNew();
    if(HOTJAR_USER_ID){
      reqHeaders.push({ key: 'HOTJAR_USER_ID', value: HOTJAR_USER_ID });
    }

    if (reqHeaders.length > 0) {
      let headers = new HttpHeaders();
      reqHeaders.forEach(header => {
        headers = headers.set(header.key, header.value);
      });
      return headers;
    }
    return null;
  }

  extendedBody(request: HttpRequest<any>) {
    let extendedBody = null;
    if (['post', 'patch', 'delete', 'put'].includes(request.method.toLowerCase()) && this.isSubscriberEndpoint(request)) {
      if (request.body instanceof FormData) {
        //do nothing. May be we want to update in future
      } else {
        extendedBody = { 
          ...request.body, 
          device_meta: this.deviceDetectorService.getDeviceInfo()
        }
      }
    }
    return extendedBody;
  }

  isSubscriberEndpoint(request: HttpRequest<any>){
    return !request.url.includes('/v1/stylist') && !request.url.includes('/v1/admin') && !request.url.includes('/v1/inventory') && !request.url.includes('/api/admin');
  }

  extractHotjarUserIdIfNew(){
    const cookie = this.getHotjarUserSession();
    let hotjarUserId = this.storageService.get(QATCH_CONSTS.STORAGE_KEYS.HOTJAR_USER_ID);
    const cookieId = cookie.id;
    if(hotjarUserId !== cookieId && cookieId){
      this.storageService.set(QATCH_CONSTS.STORAGE_KEYS.HOTJAR_USER_ID, cookieId);
      return cookieId;
    }
  }

  getHotjarUserSession(){
    const cookie = this.cookieService.get(QATCH_CONSTS.STORAGE_KEYS.HOTJAR_USER_ID);
    return cookie ? JSON.parse(atob(cookie)) : {};
  }
}
