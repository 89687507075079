import {Component, ElementRef, TemplateRef, ViewChild} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsContainer  {

  @ViewChild('content') content: ElementRef;

  message = '';
  classname = '';
  isSuccess = false;
  autoClose = true;

  constructor(
    public toastService: ToastService, 
    private modalService: NgbModal
  ) {
    this.toastService.modal.subscribe( (config: any) => {
      const {textOrTpl, success, autoClose} = config;
      this.message = textOrTpl;
      this.isSuccess = success;
      this.autoClose = autoClose === undefined ? true : autoClose;
      const modalRef = this.open(this.autoClose);
      if(this.autoClose && modalRef){
        setTimeout(() => modalRef.close(), 3000);
      }
    });
  }

  open(autoClose: boolean): NgbModalRef {
    return this.modalService.open(this.content, {
      animation: true,
      centered: !autoClose
    });
  }

}
