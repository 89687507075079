<div class="animation-infinite-wrapper">
    <div class="sms-gallery-list animation-infinite py-3 px-2">
        <p-carousel #pCarouselRef [value]="smsList"  [circular]="false" 
        [showIndicators]="false"
        [numVisible]="isMobile ? 1 : 5" [numScroll]="1"
        [showNavigators]="false" effect="fade">
            <ng-template let-sms pTemplate="item">
                <p-card
                    styleClass="sms-view p-2 mx-4">
                    <ng-template pTemplate="header">
                        <div class="d-flex justify-content-between">
                            <span class="d-flex">
                                <img class="sms-icon" [src]="sms.icon" />
                                <!-- <span class="px-2" [innerText]="sms.title"></span> -->
                                <h6 class="text-none Now-Bold mt-1 ms-1" [innerText]="sms.subTitle"></h6>
                            </span>
                            <span [innerText]="sms.time"></span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="body">
                        <!-- <h6 class="text-none Now-Bold" [innerText]="sms.subTitle"></h6> -->
                        <p class="Now-Regular msg-description" [innerText]="sms.message"></p>
                    </ng-template>
                </p-card>
            </ng-template>
        </p-carousel>
    </div>
</div>