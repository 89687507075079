import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterItem } from '../../filters.component';

@Component({
  selector: 'app-dropdown-switch',
  templateUrl: './dropdown-switch.component.html',
  styleUrls: ['./dropdown-switch.component.scss']
})
export class DropdownSwitchComponent implements OnInit {

  @Input() isIndividual: boolean;
  @Input() filter: FilterItem;
  @Output() onChange = new EventEmitter();

  data: any[] = [];
  @Input() value: any;
  dropdownSettings = {
    text: 'Please Select',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'ms-bg',
    singleSelection: true,
    limitSelection: 5,
    badgeShowLimit: 1,
  };

  constructor() { }

  ngOnInit(): void {
    this.setDropdownData();
  }

  setDropdownData() {
    this.data = [
      {
        id: 'yes',
        itemName: 'YES'
      },
      {
        id: 'no',
        itemName: 'NO'
      }
    ];
  }

  valueChange() {
    this.onChange.next({ ...this.filter , value: this.value});
  }

}
