import { QuestionBaseConfig } from '../question-base-config';
import { MultiSelectQuestion } from './question-MultiSelect';

export class SingleSelectQuestion extends MultiSelectQuestion {
  override controlType = 'single-select';

  constructor(config: QuestionBaseConfig<string> = {}) {
    super(config);
    this.settings.singleSelection = true;
  }

}
