import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { BehaviorSubject, Observable, lastValueFrom, of } from "rxjs";
import { environment } from "src/environments/environment";
import { LocalStorageService, STORAGE_CONSTANTS } from "./storage.service";
@Injectable({
  providedIn: 'root',
})
export class AppService {
  private static CHAT_ROUTE = 'chat';
  private static DASHBOARD_ROUTE = 'dashboard';
  baseUrl = `${environment.baseUrl}/v1`;
  chatBaseUrl: string;
  dashboardBaseUrl: string;
  
  private pageTitle$ = new BehaviorSubject<string|null>(null);
  private appConfig$ = Promise.resolve(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: LocalStorageService
  ) {
    this.chatBaseUrl = this.deriveFunctionUri(environment['chatBaseUrl'], AppService.CHAT_ROUTE);
    this.dashboardBaseUrl = this.deriveFunctionUri(environment['dashboardBaseUrl'], `${AppService.DASHBOARD_ROUTE}`);
  }

  deriveFunctionUri(funcBaseUrl: string, routeName: string){
    if(!funcBaseUrl || funcBaseUrl.length<1) {
      return this.baseUrl.replace('/api/', `/${routeName}/api/`);
    }
    return funcBaseUrl;
  }

  getAppConfig(params?: any): Promise<any> {
    if(!this.appConfig$){
      this.appConfig$ = lastValueFrom(this.http.get(`${this.getAppBaseUrl()}/app-config`, { params: params })).then((resp:any) => {
        return resp;
      });
    }
    return this.appConfig$;
  }

  setTitle(title: string) {
    this.pageTitle$.next(title);
  }

  getTitle(): Observable<string|null>  {
    return this.pageTitle$.asObservable();
  }

  getAppBaseUrl(): string  {
    const fromStorage = this.storageService.get(STORAGE_CONSTANTS.APP_BASE_URL)
    return fromStorage ? `${fromStorage}/v1` : this.baseUrl;
  }

  getAppChatBaseUrl(): string  {
    const fromStorage = this.storageService.get(STORAGE_CONSTANTS.APP_CHAT_BASE_URL)
    return fromStorage ? `${fromStorage}/v1` : `${this.chatBaseUrl}`;
  }

  getDashboardBaseUrl(): string  {
    const fromStorage = this.storageService.get(STORAGE_CONSTANTS.APP_DASHBOARD_BASE_URL)
    return fromStorage ? `${fromStorage}` : `${this.dashboardBaseUrl}`;
  }

  navigate(url: string, options: NavigationExtras={}){
    url = url.startsWith('/') ? url : `/${url}`;
    this.router.navigate([url], {...options});
  }

  navigateWithMerge(url: string, options: NavigationExtras={}) {
    this.navigate(url, { queryParamsHandling: 'merge', ...options });
  }

  navigateWithPreserve(url: string, options: NavigationExtras={}) {
    this.navigate(url, { queryParamsHandling: 'preserve', ...options });
  }

  navigateByURL(url: string) {
    this.router.navigateByUrl(url);
  }

  navigateAndReplace(url: string, options: NavigationExtras={}) {
    this.navigate(url, { queryParamsHandling: 'merge', replaceUrl: true, ...options });
  }

  navigateToExternalUrl(url?: string | URL, target?: string, features?: string){
    window.open(url, target, features);
  }

  getConvoCardsData(): Observable<{convos:any}> {
    return this.http.get<{convos:any}>(`${this.getAppBaseUrl()}/convos`)
  }

}
