import { HttpClient, HttpParams } from '@angular/common/http';
import { APP_CONFIG } from 'src/app/app.const';
import { Debounce } from 'src/app/shared/decorators/debounce.decorator';
import { AppService } from 'src/app/shared/services/app.service';
import { IFormField, HostFunction } from '../uikit-form.component';
import { AbstractControl } from '@angular/forms';


export class RemoteSearchDropdown {
  constructor(
    public http: HttpClient,
    public appService: AppService
  ) {}

  @Debounce(APP_CONFIG.DEBOUNCE_INTERVAL_LARGE)
  onRemoteSearchDropdownSearch(searchString: string = '', field: IFormField, control?: AbstractControl, prepopulate = false) {
    this.getRemoteSearchDropdownOptions(searchString, field, control, prepopulate);
  }

  getRemoteSearchDropdownOptions(searchString: string = '', field: IFormField, control?: AbstractControl, prepopulate = false) {
    if(!field?.url?.length)return;
    const url = this.getRemoteSearchDropdownUrl(field);
    field.loading = true;
    const params = this.getParams(searchString, prepopulate);
    this.http.get(url, { params }).subscribe((resp: any) => {
      const dataKey = field.dataKey || '';
      let data = resp.hasOwnProperty(dataKey) ? resp[dataKey] : resp;
      if(field.displayFunction) {
        data = data.map((item)=>{
           item.customName = field.displayFunction(item);
           return item;
        })
      }
      field.data = data;
      field.loading = false;
      if(prepopulate) {
        const vals = searchString.split(',');
        control.patchValue(data.filter(op => {
          const mtch = vals.includes(op[field.primaryKey || 'id'].toString());
          return mtch;
        }), {emitEvent: false});
      }
    });
  }

  getParams(searchText: string, prepopulate: boolean){
    let params = new HttpParams();
    params = params.set('searchString', prepopulate ? '' : searchText)
    .set('selected_items', prepopulate ? searchText : '').set('limit', `100`);
    return params;
  }

  private getRemoteSearchDropdownUrl({url, host}: IFormField){
    if(host){
      switch(host){
        case HostFunction.DASHBOARD:
          return `${(this.appService.getDashboardBaseUrl())}/${url}`;
        case HostFunction.CHAT:
          return `${(this.appService.getAppChatBaseUrl())}/${url}`;
        default:
          return `${(this.appService.getAppBaseUrl())}/${url}`;
      }
    }else{
      return `${(this.appService.getAppBaseUrl())}/${url}`;
    }
  }
}
