<div class="qatch qatch-product" [attr.data-product-id]="data.product_id">

    <div class="qatch-img {{ getAnalyticClass('click') }}" (click)="goToLink()">
        <img lazyLoadImage url="{{ data.image_url | encodeImageUri }}"
            class="card-lazy-img w-100 {{getAnalyticClass('click')}}" />

        <div class="overlay bg-dark-trans desktop-view" *ngIf="data.added">
            <div class="text-light" [class.d-flex]="isMobile">
                <p class="text-center out-off-stock m-auto">Added to your Qatch!</p>
            </div>
        </div>

        <div class="overlay py-0  {{ getAnalyticClass('click') }}" [class.desktop-view]="isDesktop"
            *ngIf="data.availability_id !== 2 && !data.added">
            <div class="justify-content-around reactions {{ getAnalyticClass('click') }} {{isDesktop ? 'text-light' : 'd-flex py-1'}}" 
            *ngIf="isLoggedIn">
                <button type="button" class="btn btn-purple btn-just-icon {{ getAnalyticClass('love_click') }}"
                    (click)="addToQatchWithReaction('Loved', $event)" [class.btn-lg]="isDesktop"
                    [class.active-reaction]="data.reaction_type == 'Loved'" title="Love it">
                    <i class="fa fa-thin fa-heart {{ getAnalyticClass('love_click') }}"></i>
                </button>
                <button type="button" class="btn btn-purple btn-just-icon {{ getAnalyticClass('dislike_click') }}"
                    (click)="addToQatchWithReaction('Disliked', $event)" [class.btn-lg]="isDesktop"
                    [class.active-reaction]="data.reaction_type == 'Disliked'" title="Don't Like it">
                    <i class="fa fa-thin fa-thumbs-down {{ getAnalyticClass('dislike_click') }}"></i>
                </button>
            </div>
        </div>

        <div class="overlay bg-dark-trans desktop-view" *ngIf="!data.added && data.availability_id == 2">
            <div class="text-light" [class.d-flex]="isMobile">
                <p class="text-center out-off-stock m-auto">Out of Stock!</p>
            </div>
        </div>
        <!-- <ng-container *ngIf="data && (data.index || data.index === 0)">
            <div class="arrow-right shadow" *ngIf="type == 'trending-product' && data.index < maxShowIndex">
                <span class="fire">🔥</span>
            </div>
        </ng-container> -->
        <div class="arrow-right shadow" *ngIf="isDesktop && data.reaction_type != 'New'">
            <span>
              <i class="bi bi-heart-fill" *ngIf="data.reaction_type == 'Loved'"> </i>
              <i class="bi bi-hand-thumbs-down-fill" *ngIf="data.reaction_type == 'Disliked'"> </i>
              <i class="bi bi-hand-thumbs-up-fill" *ngIf="data.reaction_type == 'Liked'"> </i>
            </span>
        </div>
    </div>
    <div class="text-dark text-center pt-2 promo-code" *ngIf="data.promo_code">
        <span class="fw-bold">{{ data.promo_code }}</span>
    </div>

    <!-- <ng-container>
        <div class="d-flex {{(type !== 'trending-product' && isLoggedIn) ? 'justify-content-between' : 'justify-content-center'}} align-items-center {{ getAnalyticClass('share_click') }}"
            (click)="isMobile && shareWithFriends(data)" [class.mt-2]="!isLoggedIn">
            <span class="btn-magnify {{ getAnalyticClass('share_click') }}"
                (click)="isDesktop && shareWithFriends(data)" title="{{shareBtn.text}}"
                *ngIf="type !== 'trending-product' && isLoggedIn"><i
                    class="bi {{shareBtn.icon}} {{ getAnalyticClass('share_click') }}"></i></span>
        </div>
    </ng-container> -->
</div>