import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { QatchComponent } from 'src/app/components/qatch.component';
import { AppService } from 'src/app/shared/services/app.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LocalStorageService, STORAGE_CONSTANTS } from 'src/app/shared/services/storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { QATCH_CONSTS } from 'src/app/shared/utils/qatch-constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends QatchComponent {

    resetPasswordForm: FormGroup;
    loading = false;
    override isDesktop: boolean;
    isSubmittedOnce: boolean;

    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        protected override authService: AuthService,
        private toastService: ToastService,
        protected override deviceService: DeviceDetectorService,
        private storageService: LocalStorageService,
        private appService: AppService
    ) {
        super(deviceService, authService);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.resetPasswordForm = this.fb.group({
            password: ['', [ Validators.required, Validators.pattern(QATCH_CONSTS.PASSWORD_REGEX) ]],
            c_password: ['', [ Validators.required, Validators.minLength(8) ]]
        });
    }

    async submit() {
        this.isSubmittedOnce = true;
        const value = this.resetPasswordForm.value;
        if (this.resetPasswordForm.valid && value.password === value.c_password) {
            this.loading = true;
            try {
                const meta = this.storageService.getFromAppCache(STORAGE_CONSTANTS.USER_META);
                const uri = this.isLoggedIn ? 'password' : 'forgot-password';
                await lastValueFrom(this.userService.updatePassword(uri, {
                    ...meta,
                    ...value
                }));
                this.storageService.removeFromAppCache(STORAGE_CONSTANTS.USER_META);
                this.storageService.remove(STORAGE_CONSTANTS.NEXT_ROUTE);
                this.toastService.success('Your password has been updated. Please login to see your Qatches.');
                if(this.isLoggedIn) {
                    this.appService.navigateWithPreserve('/general/edit-account');
                } else {
                    this.appService.navigateWithPreserve('/signin');
                }
            } catch (errorResp) {
                this.toastService.error(errorResp);
            } finally {
                this.loading = false;
            }
        }
    }

    get password() { return this.resetPasswordForm.get('password'); }
    get c_password() { return this.resetPasswordForm.get('c_password'); }
}
