import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title q-page-header-sub-title mt-0">
        Spread the Word and Earn $$ To Shop
      </h4>
    </div>
    <div class="modal-body text-center my-2">
      <div class="mt-0">
        For every 5 members who sign up through your referral link, you will earn <span class="text-bold currency">10</span> towards a digital gift card of your choice. Redeem each time you reach <span class="text-bold currency">10</span>, or save up.
        <p class="mt-3 cursor-pointer btn-link" (click)="copyToClipboard()"><u>Click here to copy referral link</u></p>
        <p class="mt-3 cursor-pointer btn-link txt-dark small" (click)="goToProfile()">
          <u>Check your referral count here</u>
        </p>
      </div>
    </div>
  `
})
export class ReferalProgramModalContent {

  referral_link: string;
  text: string;

  constructor(
    private clipboardService: ClipboardService,
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private router: Router
  ) { }

  copyToClipboard() {
    this.clipboardService.copy(`${this.text} ${this.referral_link}`);
    this.toastService.success('Referral link has been copied.');
    this.activeModal.dismiss('cancel');
  }

  goToProfile(){
    this.activeModal.dismiss('cancel');
    this.router.navigate(['/general/edit-account'], { queryParamsHandling: 'preserve' });
  }
}