import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export enum BUTTONS {
    CONFIRM = 'Confirm',
    DONE = 'Done',
    CANCEL = 'Cancel',
}

export enum ALIGN_TITLE {
    LEFT = 'start',
    CENTER = 'center',
    RIGHT = 'end',
}

@Component({
    selector: 'q-modal-header',
    standalone: true,
    imports: [CommonModule],
    template: `<div class="modal-header-seprator d-flex justify-content-between align-items-center py-2 px-3">
                 <h5 class="m-0 w-100 text-{{alignTitle}}">{{title}}</h5>

                 <button type="button" class="close" (click)="close.emit($event)">&times;</button>
               </div>`,
})
export class QModalHeaderComponent {
    @Input() title: string;
    @Input() alignTitle: ALIGN_TITLE = ALIGN_TITLE.LEFT ;
    @Output() close = new EventEmitter<any>();

    constructor( public activeModal: NgbActiveModal ) { }

}