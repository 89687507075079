import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByColumn'
})
export class GroupByColumnPipe implements PipeTransform {

  transform(array: any[]): any[] {
    const columnsCount = this.getCoulumnsCount();
    const columnItemCount = parseInt(`${array.length/columnsCount}`) || 1;
    const lastRowElms = array.slice((columnsCount * columnItemCount) , array.length);
    const result = [];
    for(let column = 0; column < columnsCount; column++) {
      const slice = array.slice(column * columnItemCount, (column * columnItemCount) + columnItemCount);
      if(slice) {
        result.push(slice);
      }
      if(lastRowElms.length > column){
        result[column].push(lastRowElms[column]);
      }
    }
    return result;
  }

  getCoulumnsCount() {
    if(innerWidth < 768) {
      return 2;
    } else if(innerWidth < 992) {
      return 3;
    } else if(innerWidth < 1400) {
      return 4;
    } else {
      return 5;
    }
  }

}
