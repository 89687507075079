import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';

@Component({
    selector: 'app-choose-cancel-reason',
    templateUrl: './choose-cancel-reason.component.html',
    styleUrls: ['./choose-cancel-reason.component.scss'],
    standalone: true,
    imports: [CommonModule, ComponentsModule]
})
export class ChooseCancelReasonComponent implements OnInit {
  loading: boolean;
  ploading: boolean;
  form: FormGroup;
  @Input() email: string;
  @Output() close = new EventEmitter();
  possible_reasons = [
      { 'id': 0, 'itemName': 'Too many texts' },
      { 'id': 1, 'itemName': 'No need to shop right now' },
      { 'id': 2, 'itemName': 'The products were not my style' },
      { 'id': 3, 'itemName': 'Not interested in the service' },
      { 'id': 4, 'itemName': 'Other' }
  ];
  reasonSettings = {
      text: 'Please Select',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: 'ms-bg',
      singleSelection: true
  };

  constructor(
      private toastService: ToastService,
      protected userService: UserService,
      private fb: FormBuilder,
      private activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    // this.getProfile();
    this.form = this.fb.group({
      reason: [[], [Validators.required]],
      message: ['', [Validators.required, Validators.minLength(10)]]
    });
    this.form.get('reason').valueChanges
      .subscribe(reason => {
        const messageControl = this.form.get('message');
        if (reason && reason.length && reason[0].id === 4) {
          messageControl.enable();
        } else {
          messageControl.reset();
          messageControl.disable();
        }
    });
  }

  submit() {
      if (this.form.valid) {
          this.loading = true;
          const { message, reason } = this.form.value;
          const msg = message ? message : reason[0].itemName
          this.userService.raiseSupportRequest({
              message: msg,
              email: this.email,
              subject: 'Unsubscribe',
              reason: msg
          }).subscribe({
            next: () => {
              this.loading = false
              this.activeModal.close();
              this.close.next('');
          },
            error: () => {
              this.loading = false;
              this.toastService.error(`Request couldn't be submitted. Please try again.`);
            }
          }
        );
      }
  }

  onClose() {
    this.activeModal.dismiss('Cross click');
  }

  get reason() { return this.form.get('reason'); }
  get message() { return this.form.get('message'); }
}
