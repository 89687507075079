import { QuestionBase } from '../question-base';
import { QuestionBaseConfig } from '../question-base-config';

export class ChipQuestion extends QuestionBase<string> {

    override settings = {
        classes: 'ms-bg',
        singleSelection: false,
    };

    constructor(config: QuestionBaseConfig<string> = {}) {
        super(config);
        let index = 0;
        this.options = config.options ? config.options.map(option => {
            return { 'id': index++, 'itemName': option };
        }) : [];
        this.answer = this.parseValue(config.answer);
        const hasAnswered = this.answer && Array.isArray(this.answer) && this.answer.length;
        if (!hasAnswered && config.default_value?.length) {
            this.answer = config.default_value;
        }
        this.answer = this.answer.map(ans => {
            ans = this.options.find(opt => opt.itemName === ans) || {};
            return {...ans, checked: true}
        });
        // this.options = this.options.map( item => {
        //     item.checked = this.answer.find(opt => opt.itemName === item.itemName) ? true : false
        //     return item;
        // });
    }

    parseValue(val) {
        if (val && typeof val === 'string') {
            return JSON.parse(`${val}`);
        } else {
            return [];
        }
    }

}
