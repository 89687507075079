import { Validators } from "@angular/forms";
import { FormFieldTypes, IFormField } from "src/app/shared/modules/uikit-form/uikit-form.component";

export const QUICKSIGNIN_FORM_FIELDS: IFormField[] = [
    {
      label: '',
      type: FormFieldTypes.textinput,
      fieldId: 'name',
      placeholder:'Enter your name',
      validators: [Validators.required, Validators.minLength(3), Validators.pattern('^[a-zA-Z ]+$')]
    },

    {
      label: '',
      type: FormFieldTypes.phone,
      fieldId: 'phone_number',
      placeholder:'Enter phone number',
      validators: [Validators.required, Validators.pattern('^[0-9]{10}$')]
      
    },
    {
        label: '',
        type: FormFieldTypes.toggle_switch,
        fieldId: 'agree_to_terms',
        validators: [Validators.required],
        toggleSwitch: {
            offLabel: ' ',
          onLabel: `By signing up, you agree to our Terms of Service. By selecting “I agree,” you are opting into Qatch’s text service. Qatch may receive commission from products purchased through links sent via text. To take a break, just text us “PAUSE” and to cancel at any time, text us “STOP.” Message and Data rates may apply. Read our Terms here :  `,
        }
    }
  ]