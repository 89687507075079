import { QuestionBaseConfig } from './question-base-config';

export class QuestionBase<T> {
    default_value: any;
    key: string;
    label: string;
    order: number;
    controlType: string;
    type: string;
    options: any[] = [];
    question_id: number;
    validators = [];
    placeholder: string;
    helpText: string;
    settings: any;
    answer: any;
    required: boolean;
    visible: boolean;
    visible_rule: string;

    constructor(config: QuestionBaseConfig<T> = {}) {
      this.default_value = config.default_value;
      this.key = config.key || '';
      this.label = config.label || '';
      this.order = config.order === undefined ? 1 : config.order;
      this.controlType = config.controlType || '';
      this.type = config.type || '';
      this.options = config.options || [];
      this.question_id = config.question_id;
      this.validators = config.validators || [];
      this.placeholder = config.placeholder || '';
      this.helpText = config.helpText || '';
      this.settings = config.settings || {};
      this.answer = config.answer || {};
      this.required = config.required || false;
      this.visible = config.visible === undefined ? true : false;
      this.visible_rule = config.visible_rule || '';
    }
}
