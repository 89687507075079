import { Component, Input, OnChanges, Output, EventEmitter, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DROPDOWNS_SETTINGS } from './input-types/uikit-form.consts';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DropdownSettings } from 'ngb-dropdown/lib/multiselect.interface';
import { RemoteSearchDropdown } from './input-types/remote-dropdown.component';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../../services/app.service';
import { APP_CONFIG } from 'src/app/app.const';
import { isNull, isUndefined } from 'lodash';
import { PhoneUtil } from '../../utils/phone-utils';

export enum FormFieldTypes {
  dropdown = 'dropdown',
  multi_dropdown = 'multi_dropdown',
  remote_dropdown = 'remote_dropdown',
  checkbox = 'checkbox',
  tristate_checkbox = 'tristate_checkbox',
  datepicker = 'datepicker',
  date_time_picker = 'date_time_picker',
  textinput = 'textinput',
  dropdown_switch = 'dropdown_switch',
  toggle_switch = 'toggle_switch',
  textarea = 'textarea',
  textinput_chips = 'textinput_chips',
  editor = 'editor',
  colorPicker = 'colorpicker',
  phone = 'phone'
}

export enum HostFunction{
  SUBSCRIBER = 'SUBSCRIBER',
  DASHBOARD = 'DASHBOARD',
  CHAT = 'CHAT'
}

export interface IFormField {
   label: string,
   type: FormFieldTypes,
   link?: string,
   data?: any[],
   displayKey?: string,
   primaryKey?: string,
   fieldId: string,
   host?: HostFunction,
   url?: string,
   dataKey?: string,
   displayFunction?: Function
   noDataLabel?: string,
   placeholder?: string,
   validators?: ((control: AbstractControl<any, any>) => ValidationErrors)[]
   settings?: DropdownSettings,
   loading?: boolean,
   required?: boolean,
   disabled?: boolean,
   defaultValue?: any,
   toggleSwitch?: {
     onLabel?: string,
     offLabel?: string,
     onText?: string,
     offText?: string,
     onValue?: string,
     offValue?: string
   }
}

@Component({
  selector: 'uikit-dform',
  templateUrl: './uikit-form.component.html',
  styleUrls: ['./uikit-form.component.scss']
})
export class UikitFormComponent extends RemoteSearchDropdown implements OnChanges {
  formFieldTypes = FormFieldTypes;
  @Input() fields: IFormField[];
  @Input() isSubmittedOnce = false;
  @Input() formData: any;
  @Input() isNew = false;
  @Input() autoReinitializeForm = false;
  form: FormGroup = new FormGroup({});
  @Output() onBlurEvent = new EventEmitter();
  @Output() onValueChanges = new EventEmitter();
  countryCode = PhoneUtil.getCountryCode();

  constructor(
    private datePipe: DatePipe,
    protected deviceService: DeviceDetectorService,
    public override http: HttpClient,
    public override appService: AppService,
    public elementRef: ElementRef
  ) {
    super(http, appService);
  }

  ngOnChanges(changes) {
    if(changes.fields.firstChange || (!changes.fields.firstChange && this.autoReinitializeForm)) {
      this.initializeForm(changes.fields.firstChange);
    }
  }

  initializeForm(firstChange) {
    if(this.formData && !firstChange && this.autoReinitializeForm) {
      this.formData = {
        ...this.formData,
        ...this.getRawValues()
      }
    }
    this.form = new FormGroup({});
    this.fields.forEach((formField) => {
      const control = this.updateFormField(formField);
      this.bindFormValueChanges(control, formField);
    });
    if(!this.isNew) {
      this.form.markAllAsTouched();
    }
  }

  bindFormValueChanges(control: AbstractControl, formField) {
    control.valueChanges.subscribe((value) => {
      this.onValueChanges.emit({formField, value});
    })
  }

  updateFormField(formField, isNew = true, forceFetchRemoteOptions = false): AbstractControl {
    const value = this.transformRawToForm(formField);
    let control: AbstractControl;
    if(isNew) {
      control =  new FormControl(value, formField.validators || []);
      formField.required = control.hasValidator(Validators.required);
      this.form.addControl(formField.fieldId, control);
      formField.settings = this.getDropdownSettings(formField);
    } else {
      control = this.form.get(formField.fieldId);
    }
    if (formField.type === FormFieldTypes.remote_dropdown) {
      const searchString = value?.length ? value.join(',') : '';
      if(!this.isNew && !searchString && !forceFetchRemoteOptions) return control;
      this.getRemoteSearchDropdownOptions(
        searchString, 
        formField,
        control,
        !this.isNew
      );
    } else {
      control.patchValue(value, {emitEvent: false});
    }
    return control;
  }

  transformRawToForm(formField) {
    let value = this.formData ? this.formData[formField.fieldId] : ((formField.defaultValue || formField.defaultValue === false || formField.defaultValue === 0) ? formField.defaultValue : null);
    if(value) {
      if ([FormFieldTypes.dropdown, FormFieldTypes.multi_dropdown].includes(formField.type) && formField.data) {
        let values = FormFieldTypes.multi_dropdown === formField.type ? value : [value];
        values = values.map(v => this.toLowerCase(v));
        const res = formField.data.filter( option => {
          let val = this.toLowerCase((option[formField.primaryKey || 'id'] || option));
          return values.includes(val);
        });
        value = res.length ? res : null;
      }
      if (formField.type === FormFieldTypes.remote_dropdown) {
        value = Array.isArray(value) ? value : [value];
      }
      if(formField.type === FormFieldTypes.datepicker) {
        if (value && Array.isArray(value)) {
          value = value[0];
        }
        if (!(value && typeof value == 'object')) {
          const tokens = value ? value.split('-').map((tk: string) => parseInt(tk)) : [];
          value = tokens.length ? {year: tokens[0], month: tokens[1], day: tokens[2] } : null;
        }
      }
      if(formField.type === FormFieldTypes.toggle_switch) {
        value = ((value === formField.toggleSwitch?.onValue) || (typeof value === 'boolean' && value)) ? true : false;
      }
    }
    return value;
  }

  toLowerCase(val) {
    return (val && typeof val === 'string') ? val.toLowerCase() : val;
  }

  getDropdownSettings(field: IFormField) {
     let defaultSetting = DROPDOWNS_SETTINGS[field.type];
     defaultSetting = {
      ...defaultSetting,
      ...field.settings,
      labelKey: field.displayKey || 'id',
      primaryKey: field.primaryKey || 'itemName'
    }
    return defaultSetting;
  }

  getRawValues() {
    let formRawValues = this.form.getRawValue();
    for(let field of this.fields) {
      let value = formRawValues[field.fieldId];
      if(!isNull(value) && !isUndefined(value)){
        switch (field.type) {
          case FormFieldTypes.dropdown:
            value = (value?.length) ? value[0][field.primaryKey] : undefined;
          break;
          case FormFieldTypes.toggle_switch:{
            value = value ? (field.toggleSwitch?.onValue || value) : (field.toggleSwitch?.offValue || value);
            break;
          }
          case FormFieldTypes.dropdown_switch:
          case FormFieldTypes.multi_dropdown:
          case FormFieldTypes.remote_dropdown:
            value = (value?.length) ? value.map(i => i[field.primaryKey]).join(',') : undefined;
            break;
          case FormFieldTypes.datepicker:{
            const { year, month, day} = value || {};
            let sdate;
            if(year && month) {
              sdate = this.datePipe.transform(new Date(year, month-1, day), APP_CONFIG.DATES.DATE_FORMAT);
            } else {
              value = undefined;
            }
            break;
          }
          default:
            value = value;
          break;
        };
        formRawValues[field.fieldId] = value;
      }else{
        delete formRawValues[field.fieldId];
      }
    }
    return formRawValues;
  }

  ontoggleLabelClick(formControl: string) {
    this.form.patchValue({ [formControl]: !this.controls[formControl].value })
  }

  get controls() {
    return this.form.controls;
  }

}
