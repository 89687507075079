import { Component, Renderer2 } from '@angular/core';
import { User } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { QatchComponent } from '../qatch.component';
import { Location } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends QatchComponent {

    test: Date = new Date();
    isScoll: boolean;
    referralLink: string = '';
    user: User;

    constructor(
        public location: Location,
        private renderer: Renderer2,
        protected override deviceService: DeviceDetectorService,
        protected override authService: AuthService,
        private userService: UserService
    ) {
        super(deviceService, authService);
    }

    override ngOnInit() {
        super.ngOnInit();
        this.renderer.listen('window', 'scroll', (event) => {
            this.isScoll = true;
        });
    }

    override postAuth(user: User){
        this.user = user;
    }

    goToLink() {
        //window.open('https://qatchbeta.paperform.co/', '_blank');
        if(this.referralLink){
            this.referAFriend(this.referralLink);
        } else if(this.user){
            this.userService.generateReferralLink().subscribe(resp => {
                if(resp.referral_link) {
                    this.referAFriend(this.user.referral_link || '');
                }
            })
        }
    }

    isHome() {
        const path = this.location.prepareExternalUrl(this.location.path());
        return path.includes('/home') || location.pathname === '/' || !this.isLoggedIn;
    }

    referAFriend(referral_link: string){
        this.userService.referAFriend(referral_link);
    }
}
