<div class="modal-content">
  <q-modal-header title="Advance filter" (close)="onClose()"></q-modal-header>
  <div class="modal-body p-4">
    <uikit-dform [isNew]="isNew" [fields]="formFields" [formData]="formData"></uikit-dform>
    <app-loader [visible]="loading" type="full-page"></app-loader>
  </div>
  <div class="modal-footer py-2">
    <div class="d-flex justify-content-end align-items-center">
      <button [disabled]="loading" class="btn btn-default me-2" (click)="onClose()">Close
      </button>
      <button class="btn btn-default me-2" (click)="clearAll()" [disabled]="clearAllButtonValidation() || loading">Clear
        All
      </button>
      <button class="btn btn-danger" (click)="applyFilter()" [disabled]="uikitFormComponent?.form?.invalid || loading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>Apply
        Filter
      </button>
    </div>
  </div>
</div>