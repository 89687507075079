import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import {
  PUBLIC_MODULE_COMPONENTS
} from '.'
import { FiltersModule } from 'src/app/shared/modules/filters/filters.module';
import { AuthModule } from '../auth/auth.module';

@NgModule({
    imports: [
      SharedModule,
      FiltersModule,
      AuthModule
    ],
    declarations: PUBLIC_MODULE_COMPONENTS,
    exports: PUBLIC_MODULE_COMPONENTS
})
export class PublicModule { }
