<div class="modal-content">
    <div class="modal-body p-0">
        <button type="button" class="close" (click)="onClose()">&times;</button>
        <div class="text-center pt-4">
            <div class="home-heading-shadow">
                <div class="title home-heading pb-3">Finish the Style Quiz so we know what personalized styles to send you!</div>
            </div>
            <div class="m-0 app-profile-area">
                <app-profile [quickQuiz]="true" (quizAnswered)="onClose(true)"></app-profile>
            </div>
        </div>
    </div>
</div>