<ng-container *ngIf="type === 'CAROUSEL'">
  <lib-q-slider [sItems]="convoCards" [itemTemplate]="itemTemplate"></lib-q-slider>
  <ng-template #itemTemplate let-card="subItem">
    <q-convo-card [loading]="loading" [card]="card" [style]="card.style" [selection]="false" (onClick)="onCardClick(null)" (onImageLabelClick)="onImageLabelClick($event)"></q-convo-card>
  </ng-template>
</ng-container>

<ng-container *ngIf="type !== 'CAROUSEL'">
  <div class="d-flex flex-wrap convo-list-view justify-content-center">
    <div *ngFor="let convoCard of convoCards;index as indx;">
      <q-convo-card [loading]="loading" [card]="convoCard" [style]="convoCard.style" (onClick)="onCardClick(indx)" [isMobile]="isMobile"></q-convo-card>
    </div>
  </div>
</ng-container>