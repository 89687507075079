<div class="modal-content">
    <div class="modal-body  p-md-2 p-3 overflow-auto ">
        <button type="button" class="close" (click)="onClose()">&times;</button>
       <div class="text-center pt-3">
        <div class="title home-heading">Whoops! Looks like you’re not signed in.</div>
        <p class="title fs-5 text-bold home-description vpt-1 ">To view, rate, and save more items, sign up below (it’s free)!</p>
        <p class="sub-description ">Already a member? <a class="a-link" (click)="openSigninPopup()">Sign in Here!</a></p>
       </div>
        <div class="row m-0 p-0 py-2 px-md-5">
            <uikit-dform  [isNew]="true" [fields]="formFields" [formData]="{}"></uikit-dform>
            <a class="d-flex justify-content-center" style="font-size: smaller;" [routerLink]="['/terms']">https://www.joinqatch.com/terms</a>
        </div>
        <div class="text-center vmt-2 vmb-3 mx-auto">
            <q-button [disabled]="uikitFormComponent?.form?.invalid || loading" 
             title="START SHOPPING" (click)="validatePhoneNumber();" [loading]="loading">
            </q-button>
        </div>
    </div>

</div>