<p-button *ngIf="type === 'button'" [disabled]="disabled || loading" (click)="onClick()"
  styleClass="p-button-raised {{buttonStyleClass?? 'p-button-primary'}} p-button-rounded p-button-lg vpx-6 position-relative">
  {{title}}
  <span class="spinner-border spinner-border-sm position-absolute" role="status" 
    aria-hidden="true" *ngIf="loading" style="right: 5px;"></span>
</p-button>

<a *ngIf="type === 'arrowButton'" class="btn btn-rounded border-0 {{buttonStyleClass?? 'btn-white'}} 
ps-4 pe-0 py-1 d-flex justify-content-between align-items-center position-relative"
  [class.btn-lg]="isDesktop" [class.disabled]="disabled || loading" (click)="onClick()">
  <span>{{title}}</span>
  <span class="spinner-border spinner-border-sm position-absolute" role="status" 
    aria-hidden="true" *ngIf="loading" style="right: 40px;"></span>
  <button class="btn btn-just-icon vml-7 {{iconStyleClass?? 'p-button p-button-primary'}} border-0" [disabled]="disabled || loading">
    <i class="m-auto pi {{icon?? 'pi-angle-right'}}"></i>
  </button>
</a>