<ng-template #content let-modal>
    <div class="toast-content">
        <button *ngIf="!autoClose" type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"
            style="position: absolute;right: 12px;top: 12px;outline: none !important;z-index: 999;"></button>
        <div class="modal-body" data-aos="zoom-in" data-aos-duration="1000">
            <div class="row pt-2">
                <div class="col-2 d-flex {{ isSuccess ? 'success' : 'error'}}"><i
                        class="my-auto fa fa-{{ isSuccess ? 'check' : 'close'}}"></i></div>
                <div class="col-10 d-flex">
                    <div class="my-auto" [innerHtml]="message"></div>
                </div>
            </div>
        </div>
        <div class="modal-footer" *ngIf="!autoClose">
            <button type="button" class="btn btn-ok" (click)="modal.close()">Close</button>
        </div>
    </div>
</ng-template>