import { NgModule } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { CommonModule } from '@angular/common';
import { HThemeComponent } from './h-theme.component';
import { ButtonModule } from 'primeng/button';


@NgModule({
  declarations: [HThemeComponent],
  imports: [
    CommonModule,
    AvatarModule,
    ButtonModule
  ],
  exports: [
    HThemeComponent
  ]
})
export class HThemeModule { }
