import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/shared/services/app.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { LocalStorageService, STORAGE_CONSTANTS } from 'src/app/shared/services/storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PhoneUtil } from 'src/app/shared/utils/phone-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BaseVerifyPhone } from './base-verify-phone';
import { Auth } from '@angular/fire/auth';
import { PRODUCT_CLICK_COUNT } from 'src/app/app.const';

// Mask the global 'window' for this snippet file
const window = {
  recaptchaVerifier: undefined
};

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss']
})
export class VerifyPhoneComponent extends BaseVerifyPhone implements OnInit {
  showBackBtn: boolean;
  maskedPhone: string;
  onSuccess: Function;
  onError: Function;

  constructor (
      firebaseAuth: Auth,
      protected fb: FormBuilder,
      protected userService: UserService,
      authService: AuthService,
      toastService: ToastService,
      route: ActivatedRoute,
      quizService: QuizService,
      protected override storageService: LocalStorageService,
      protected override deviceDetectorService: DeviceDetectorService,
      private appService: AppService
  ) {
      super(firebaseAuth, authService, toastService, route, quizService, storageService, deviceDetectorService);
      this.showBackBtn = this.storageService.get(STORAGE_CONSTANTS.ALLOW_BACK_BUTTON) != null;
      if(!this.storageService.has(STORAGE_CONSTANTS.NEXT_ROUTE)){
        console.log(`Next route not defined.`);
      }
  }

  override ngOnInit(): void {
    super.ngOnInit();
    const phone = this.withCountryCode();
    this.maskedPhone = `${PhoneUtil.getCountryCode()} ${PhoneUtil.maskPhone(phone)}`;
    if (!phone) {
        this.previousPage();
    } else {
      this.verifyForm = this.fb.group({
        otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
      });
    }
    this.watchPhoneNumberVerification();
  }

  onOtpChange(value) {
    this.verifyForm.patchValue({ otp: value})
  }

  async submit() {
      if (this.verifyForm.valid) {
          this.loading = true;
          super.verifyOTP({}, 'user/phone/confirm');
      }
  }

  override postOTPVerifySuccess(data: any){
    const meta = this.storageService.popFromAppCache(STORAGE_CONSTANTS.USER_META);
    if(data.token && data.token.token){
      localStorage.removeItem(PRODUCT_CLICK_COUNT);
      this.authService.signInWithCustomToken(data.token);
      this.storageService.storeInAppCache(STORAGE_CONSTANTS.JUST_LOGGED_IN_EVENT, 'true');
      if(this.onSuccess) {
        this.onSuccess();
      }
    } else if(meta && meta.phone_number && meta.password) {
      this.toastService.success(`Your phone ${data.phone_number} has been verified succesfully.`);
      this.authService.loginWithUser(PhoneUtil.emailFromPhone(PhoneUtil.withCountryCode(meta.phone_number)), meta.password.trim());
      this.storageService.storeInAppCache(STORAGE_CONSTANTS.JUST_LOGGED_IN_EVENT, 'true');
    } else if(this.authService.isLoggedIn()) {
      this.authService.me(true).then( () => {
        this.authService.toNextRoute();
      })
    } else if(meta && meta.phone_number) {
      this.appService.navigateWithPreserve('/thank-you');
    } else {
      this.appService.navigateWithPreserve('/signin');
    }
  }

  override postOTPVerifyFailed() {
    this.loading = false;
    this.onError();
  }

  previousPage() {
    this.storageService.pop(STORAGE_CONSTANTS.ALLOW_BACK_BUTTON);
    history.back();
  }
}
