export const DROPDOWNS_SETTINGS = {
    common: {
        text: 'Please Select',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: 'ms-bg',
        singleSelection: false,
        limitSelection: 5,
        badgeShowLimit: 2,
        tagToBody: false
    },
    dropdown: {},
    multi_dropdown: {},
    remote_dropdown: {}
}

DROPDOWNS_SETTINGS.dropdown = {
    ...DROPDOWNS_SETTINGS.common,
    singleSelection: true
}

DROPDOWNS_SETTINGS.multi_dropdown = {
    ...DROPDOWNS_SETTINGS.common
}

DROPDOWNS_SETTINGS.remote_dropdown = {
    ...DROPDOWNS_SETTINGS.common,
    noDataLabel: ''
}