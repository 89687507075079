<div *ngIf="!readonly" class="input-group form-group-no-border">    
    <div class="bg-transparent input-group form-group-no-border">
      <div class="row w-100 g-0 gap-3 chips">
          <div *ngFor="let chip of data" (click)="toggle(chip)" class="col">
              <div class="chip-btn {{chip.id}}" [ngClass]="{'active': chip.checked}">
                  {{chip.itemName}}
              </div>
          </div>
      </div>
    </div>
</div>

<div *ngIf="readonly" class="badge {{itemName}} rounded-pill p-2">{{itemName}}</div>