import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterItem } from '../../filters.component';
import { uniqueId } from 'lodash';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  _filter: FilterItem;
  get filter(): FilterItem {
      return this._filter;
  }
  @Input()
  set filter(value: FilterItem) {
    this._filter = value;
  }
  @Input() filtered: boolean;
  @Input() value = false;
  @Input() isIndividual: boolean;
  @Output() onChange = new EventEmitter();
  componentId: any = uniqueId();

  constructor() { }
  ngOnInit(): void {
  }

  valueChange() {
    this.onChange.next({ ...this._filter , value: this.value});
  }

}
