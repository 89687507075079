import { FormFieldTypes, IFormField } from "../../../uikit-form/uikit-form.component";

export const TIME_INTERVALS = [
    {id: 1, value: 'Last 1 day'},
    {id: 2, value: 'Last 2 days'},
    {id: 7, value: 'Last 7 days'},
    {id: 15, value: 'Last 15 days'},
    {id: 30, value: 'Last 30 days'},
    {id: 60, value: 'Last 60 days'},
    {id: 90, value: 'Last 90 days'},
]

export const FORM_FIELDS: IFormField[] = [
    {
        label: 'Stylist',
        type: FormFieldTypes.dropdown,
        data: [],
        displayKey: 'stylist_name',
        primaryKey: 'stylist_id',
        fieldId: 'stylist'
    },
    {
        label: 'Keywords',
        type: FormFieldTypes.textinput_chips,
        fieldId: 'keywords',
    },
    {
        label: 'User Type',
        type: FormFieldTypes.dropdown,
        fieldId: 'paying_status_id',
        primaryKey: "id",
        displayKey: 'value',
        settings: { enableSearchFilter: false },
        data: [
            { id: 1, value: 'Vip' },
            { id: 2, value: 'Non Vip' },
        ]
    },
    {
        label: 'Time Interval',
        type: FormFieldTypes.dropdown,
        fieldId: 'interval',
        primaryKey: "id",
        displayKey: 'value',
        settings: { enableSearchFilter: false },
        data: TIME_INTERVALS
    }
]