import { QuestionBase } from '../question-base';
import { QuestionBaseConfig } from '../question-base-config';

export class MultiSelectQuestion extends QuestionBase<string> {
  override controlType = 'multi-select';
  override settings = {
    text: 'Please Select',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: 'ms-bg',
    singleSelection: false,
    maxHeight: 200,
    tagToBody: false
  };

  constructor(config: QuestionBaseConfig<string> = {}) {
    super(config);
    let index = 0;
    this.options = config.options ? config.options.map( option => {
        return { 'id': index++, 'itemName': option };
    }) : [];  
    this.answer = this.parseValue(config.answer);
    const hasAnswered = this.answer && Array.isArray(this.answer) && this.answer.length;
    if(!hasAnswered && config.default_value?.length) {
      this.answer = config.default_value;
    }
    this.answer = this.answer.map( ans => {
      return this.options.find(opt => opt.itemName === ans);
    });
  }

  parseValue(val) {
    if (val && typeof val === 'string') {
      return JSON.parse(`${val}`);
    } else {
      return [];
    }
  }

}
