import { ChatParticipantStatus, ChatParticipantType, Group, IChatGroupAdapter, Message, IMessageSeen, MessageType, PagedHistoryChatAdapter, ParticipantResponse } from "hss-chat";
import { map } from "rxjs";
import { ChatComponent } from "../chat.component";
import { ChatService } from "./chat.service";

export class ChatAdapter extends PagedHistoryChatAdapter implements IChatGroupAdapter {

    reactions = {
        'Loved': '❤️',
        'Disliked': '👎',
        'Liked': '👍',
        'Questioned': '❓',
        'Emphasized': '❗❗',
        'Laughed at': '😆',
    };

    constructor(private chatComponent: ChatComponent, private chatService: ChatService, private user: any) {
        super();
    }

    listFriends(search: string, pageSize: number, page: number): any {
        return this.chatService.friendsList(search, pageSize, (page-1)*pageSize).pipe(map(({ friends }) => {
            return friends.map((friend) => {
                return this.toParticipant(friend);
            });
        }));
    }

    getMessageHistory(destinataryId: any): any {
        return this.chatService.getMessageHistory(destinataryId).pipe(map(({ messages }) => {
            const r_messages = messages.map((message) => {
                return this.toMessage(message);
            });
            return r_messages.reverse();
        }));
    }

    getMessageHistoryByPage(destinataryId: any, size: number, page: number): any {
        let offset: number = (page - 1) * size;
        return this.chatService.getMessageHistory(destinataryId, size, offset).pipe(map(({ messages }) => {
            const r_messages = messages.map((message) => {
                return this.toMessage(message);
            });
            return r_messages.reverse();
        }));
    }

    override getRecentMessages(destinataryId: any, message: Message): any {
        message ? message : {};
        return this.chatService.getRecentMessages(destinataryId, (message.dateSent?.toString() || '')).pipe(map(({ messages }) => {
            const r_messages = messages.map((message) => {
                return this.toMessage(message);
            });
            return r_messages.reverse();
        }));
    }

    override getPresetMessages(destinataryId: any): any {
        return this.chatService.getPreDefinedMessages(destinataryId);
    }

    sendMessage(message: Message): void {
        this.chatService.sendMessage(message.toId, message).subscribe({
            next: message => {
                console.log(message);
            }
        });
    }

    public onMessageSeen({destinataryId, messages, success}: IMessageSeen, force = false)
    {   
        if(messages && messages.length) {
            const finalData = messages.filter( message => !message.dateSeen).map((msg: any) => { 
                return { 'type': msg.type, 'text_id': msg.id }
            });
            if(finalData.length) {
                this.chatService[force ? 'markAllSeen' : 'markSeen']({ messages: finalData}, destinataryId).subscribe(message => {
                    if(success) {
                        success();
                    }
                })
            }
        }
    }

    groupCreated(group: Group): void {
        
    }

    private toParticipant({ uid, first_name, last_name, text, create_date, unread_count, paying_status_id }): ParticipantResponse {
        let participantResponse = new ParticipantResponse();
        const name = `${first_name} ${last_name || ''}`.trim();
        participantResponse.participant = {
            participantType: ChatParticipantType.User,
            id: uid,
            displayName: `${uid} - ${name}`,
            avatar: null,
            status: paying_status_id === 2 ? ChatParticipantStatus.Offline : ChatParticipantStatus.Online
        };
        const isMedia = text && text.length ? false : true;
        participantResponse.metadata = {
            totalUnreadMessages: unread_count,
            recentMessage: {
                fromId: null,
                toId: null,
                message: isMedia ? 'Media' : text,
                dateSent: create_date
            }
        };
        return participantResponse;
    }

    private toMessage({ text_id, text, create_date, stylist_name, stylist_uid, uid, type, media_urls, read, reaction, destination_url }): Message {
        const message = {
            id: text_id,
            fromId: this.determineFromId(type, uid),
            toId: this.determineToId(type, uid),
            message: text,
            dateSent: create_date,
            dateSeen: read ? create_date: null,
            medias: media_urls?.map(media => { 
                return { type: MessageType.Image, url: media };
            }),
            destination_url,
            reactions: this.reactionsMapping(reaction)
        }
        return message;
    }

    private reactionsMapping(reaction_type) {
       return reaction_type ? [this.reactions[reaction_type]] : null;
    }

    private determineFromId(type, uid) {
        return (this.chatComponent.isAdmin || this.chatComponent.isStylist) && type === 'OUTBOUND' ? this.user.uid : uid;
    }

    private determineToId(type, uid) {
        return (this.chatComponent.isAdmin || this.chatComponent.isStylist) && type === 'INBOUND' ? uid : this.user.uid;
    }
}