import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-content',
  templateUrl: './become-a-vip.modal.html',
  styleUrls: ['./become-a-vip.modal.scss']
})
export class BecomeAVipModal {

  referral_link: string;
  text: string;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router
  ) {}

  close() {
    this.activeModal.dismiss('cancel');
  }

  goToStripePayment(){
    this.router.navigate(['/internal/stripe/session']);
    this.close();
  }

}