import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { HThemeConfig } from '.';
import { HThemeService } from './h-theme.service';

@Component({
    selector: 'h-theme',
    templateUrl: './h-theme.component.html'
})
export class HThemeComponent implements OnInit, OnDestroy {
    @Input() view: 'sidebar' | 'icon' = 'sidebar';
    @Output() toggleMenu = new EventEmitter();
    @Output() configClick = new EventEmitter();
    scales: any[] = [12, 13, 14, 15, 16];
    config: HThemeConfig;
    menuActive = false;
    subscription: Subscription;

    constructor(public hThemeService: HThemeService, public primengConfig: PrimeNGConfig) { }

    ngOnInit() {
        this.config = this.hThemeService.config;
        this.subscription = this.hThemeService.configUpdate$.subscribe(config => {
            this.config = config;
            this.applyConfig();
        });
        this.applyConfig();
    }

    applyConfig() {
        this.applyScale(false);
        this.changeTheme(false);
    }

    onConfigClick(event) {
        this.configClick.emit(event);
    }

    onConfigButtonClick(event) {
        this.menuActive = !this.menuActive;
        event.preventDefault();
        this.toggleMenu.emit(this.menuActive);
    }

    incrementScale() {
        this.config.scale++;
        this.applyScale();
    }

    decrementScale() {
        this.config.scale--;
        this.applyScale();
    }

    applyScale(sendEvent = true) {
        document.documentElement.style.fontSize = this.config.scale + 'px';
        if (sendEvent)
            this.hThemeService.updateConfig({ ...this.config });
    }

    changeTheme(sendEvent = true) {
        // Template Provided Themes
        let themeElement = document.getElementById('theme-css');
        document.body.className = `${document.body.className} fade-animation`;
        const activeTheme = this.config.dark ? 'dark' : 'light';
        themeElement.setAttribute('href', `assets/theme/md-${activeTheme}-indigo/theme.css`);
        // Theme
        let qthemeElement = document.getElementById('custom-theme-css');
        qthemeElement.setAttribute('href', `assets/theme/hss-theme/h-theme-${activeTheme}.css`);
        setTimeout(() => {
            document.body.className = document.body.className.replace(/ fade-animation/g, '');
        }, 1000);
        if (sendEvent)
            this.hThemeService.updateConfig({ ...this.config });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}