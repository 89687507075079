<div class="wrapper page-header non-transparent-header">
    <div class="quiz-personal-info">
        <div class="filter"></div>
        <div class="container">
            <div class="row">
                <div class="mr-auto ml-auto">
                    <div class="card card-register shadow-none">
                        <div class="text-center">
                            <h1 class="title mt-0">Create Your Account</h1>
                            <span class="underline-text-dark w-50"></span>
                            <label class="label vmt-3">
                                <small>
                                    Choose a password to finalize your account and
                                    <br />see your style profile!
                                </small>
                            </label>
                        </div>
                        <form class="register-form mx-auto" [formGroup]="createAccountForm" (ngSubmit)="submit()">
                            <div id="captcha-container"></div>

                            <label class="label vmt-6">Choose Password</label>
                            <div class="input-group form-group-no-border bg-default shadow">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="nc-icon nc-key-25"></i>
                                    </span>
                                </div>
                                <input type="password" class="form-control box-shadow-none" placeholder="Must be atleast 8 characters"
                                    formControlName="password">
                            </div>
                            <div *ngIf="password && !password.valid && (password.dirty || password.touched)"
                                class="alert alert-danger">
                                <div *ngIf="password.errors['required']">
                                    Password is required.
                                </div>
                                <div *ngIf="password.errors['pattern']">
                                    * Password must be 8 characters long
                                    <br />
                                    * Must include an Upper and lower case letters and a number
                                </div>
                            </div>

                            <label class="label vmt-6">Confirm Password</label>
                            <div class="input-group form-group-no-border bg-default shadow">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="nc-icon nc-key-25"></i>
                                    </span>
                                </div>
                                <input type="password" class="form-control box-shadow-none" placeholder="" formControlName="c_password"
                                placeholder="">
                            </div>
                            <div *ngIf="password.valid && (!c_password.valid || c_password.value !== password.value) && (c_password.dirty || c_password.touched)"
                                class="alert alert-danger">
                                <div>
                                    Passwords do not match.
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-6">
                                    <div class="form-group mb-0">
                                        <label class="label vmt-6">Join our news letter as well!</label>
                                        <small>
                                            <br />Enter your email to stay on top of Qatch’s latest
                                            <br />trends, special offers and exclusive promotions!
                                        </small>
                                        <div class="input-group form-group-no-border vmt-1">
                                            <input type="text" class="form-control bg-default" formControlName="email">
                                        </div>
                                        <div *ngIf="email && !email.valid && (email.dirty || email.touched)"
                                            class="alert alert-danger">
                                            <div *ngIf="email.errors['email']">
                                                Please enter valid email.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group mb-0">
                                        <label class="label vmt-6">Just curious... How did you hear about us?</label>
                                        <small>
                                            <br />Referred by an ambassador? Here’s your chance to
                                            <br />give her a shoutout!
                                        </small>
                                        <div class="input-group form-group-no-border vmt-1">
                                            <input type="text" class="form-control bg-default" formControlName="promo_code">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-row justify-content-center vmt-6">
                                <!-- <button class="btn btn-dark btn-move-right mt-0" (click)="previousPage()">
                                    <i class="nc-icon nc-minimal-left"> </i>
                                    BACK
                                </button> -->
                                <button [disabled]="!createAccountForm.valid || loading" type="submit"
                                    class="btn btn-danger btn-move-right vml-1 mt-0">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="loading"></span>
                                        Create Account
                                    <i class="nc-icon nc-minimal-right"> </i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="top-right-img">
        <img src="./assets/img/organic/Shape-6.png" />
    </div>
    <div class="bottom-left-img">
        <img src="./assets/img/organic/Shape-5.png" />
    </div>
</div>