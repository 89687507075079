import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { QatchComponent } from 'src/app/components/qatch.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-quiz-style-1',
  templateUrl: './quiz-style-1.component.html',
  styleUrls: ['./quiz-style-1.component.scss']
})
export class QuizStyle1Component extends QatchComponent {

  styles = [];
  buckets: any[];
  loading = false;
  ploading: boolean;
  selectedStylesCount = 0;
  columnStyle: string = "";

  constructor(
    private quizService: QuizService,
    private toastService: ToastService,
    private router: Router,
    private userService: UserService,
    authService: AuthService,
    deviceService: DeviceDetectorService
  ) {
    super(deviceService, authService);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.getStyles();
  }

  getStyles() {
    this.ploading = true
    this.quizService.getStyles().subscribe(res => {
      this.styles = res.styles;
      this.initBuckets();
      this.ploading = false
      this.markSelected(this.getUserStyles(res.user_styles)); // Mark selected styles
    }, () => {
      this.ploading = false
      this.toastService.error('Unable to load styles. Please try again.');
    }
    )
  }

  override postResize() {
    this.initBuckets();
  }

  initBuckets() {
    if (this.styles.length) {
      let bucketSize = 2;
      if (innerWidth > 620) {
        bucketSize = Math.round(innerWidth / 330);
        bucketSize = bucketSize>4 ? 4 : bucketSize;
      }
      const perc = 100/bucketSize;
      this.columnStyle = `-ms-flex: ${perc}%; flex: ${perc}%; max-width: ${perc}%`;
      this.buckets = Array(bucketSize).fill(0).map((v, i) => []);
      // assign buckets dynamically
      let bucketIndex = 0;
      for (let index = 0; index < this.styles.length;) {
        bucketIndex = bucketIndex > this.buckets.length - 1 ? 0 : bucketIndex;
        const bucket = this.buckets[bucketIndex++];
        bucket.push(this.styles[index++]);
      }
    }

  }

  getUserStyles(userStyles: any) {
    if (this.authService.isLoggedIn()) {
      return userStyles;
    } else {
      return this.quizService.readStyles() || [];
    }
  }

  markSelected(lStyles: any[]) {
    if (lStyles) {
      this.selectedStylesCount = 0;
      lStyles.forEach((lstyle: { style_id: any; system_id: any; }) => {
        const style: any = this.styles.find((style: any) => lstyle.style_id === style.style_id);
        if (style) {
          style.system_id = lstyle.system_id;
          style.checked = true;
          this.selectedStylesCount++;
        }
      });
    }
  }

  toggleStyle(obj: any) {
    if (!obj.checked) {
      this.selectedStylesCount++;
    } else {
      this.selectedStylesCount--;
    }
    obj.checked = !obj.checked;
  }

  async next() {
    try {
      this.loading = true;
      if (this.authService.isLoggedIn()) {
        await lastValueFrom(this.userService.updateStylePreferences(
          this.styles.filter((style: any) => style.checked).map((st) => { return {style_id: st.style_id, system_id: st.system_id}}),
          this.styles.filter((style: any) => !style.checked && style.system_id).map((st) => { return {style_id: st.style_id, system_id: st.system_id}})
        ));
        await this.authService.me(true);
        //this.toastService.success('Your style preferences has been updated.');
        this.router.navigate(['/brand-quiz'], { queryParamsHandling: 'merge' });
      } else {
        this.quizService.writeStyles(this.styles.filter((style: any) => style.checked));
        this.router.navigate(['/brand-quiz'], { queryParamsHandling: 'merge' });
      }
    } catch (error) {
      this.toastService.error(`Your style preferences couldn't be updated. Something went wrong. Please try again.`);
    } finally {
      this.loading = false;
    }
  }
}
