import { Component } from '@angular/core';
import { AuthService } from './shared/services/auth.service';

@Component({
    selector: 'qatch-app',
    templateUrl: './qatch-app.component.html'
})
export class QatchAppComponent {

    isReady: boolean;
    
    constructor(
        private authService: AuthService
    ) {
        // Check Firebase User Auth state
        this.getAuthState();
    }

    async getAuthState() {
        // Add listener for listening to Firebase auth state events
        await this.authService.writeAuthToken();
        this.authService.authState().subscribe(async (result) => {
            await this.authService.writeAuthToken();
            if (result) {
                // Set auto mode on for refreshing Auth token
                this.authService.setAutoRefreshAuthToken();
                this.authService.me(true).then(() => {
                }).finally(()=>{
                    this.isReady = true;
                });
            } else {
                this.isReady = true;
            }
        });
    }
}
