<div class="modal-content">
    <q-modal-header title="" (close)="onClose()" ></q-modal-header>
    <div class="modal-body pt-0">
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()" class="mx-auto">
            <label class="label">Reason *</label>
            <div class="input-group form-group-no-border">
                <ngb-dropdown [data]="possible_reasons" formControlName="reason"
                    class="w-100 bg-default shadow" [settings]="reasonSettings">
                </ngb-dropdown>
            </div>
            <div *ngIf="reason && !reason.valid && (reason.dirty || reason.touched)"
                class="alert alert-danger">
                <div *ngIf="reason.errors">
                    Please choose the reason.
                </div>
            </div>
            <!-- [class.invisible]="message.disabled || !reason.value.length" -->
            <label class="label mt-4">Message *</label>
            <div class="input-group form-group-no-border">
                <textarea class="form-control" rows="6" formControlName="message"> </textarea>
            </div>
            <div *ngIf="message && !message.valid && (message.dirty || message.touched)"
                class="alert alert-danger">
                <div *ngIf="message.errors">
                    Message should be least 10 characters long.
                </div>
            </div>
            <div class="form-row justify-content-center mt-4 pt-2">
                <button [disabled]="!form.valid || loading" type="submit" class="btn btn-danger" ngbAutofocus>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="loading"></span>
                    UnSubscribe
                </button>
            </div>
        </form>
    </div>
</div>