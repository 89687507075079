<div class="input-group form-group-no-border filter-item d-flex justify-content-between h-100" [class.individual-filter]="isIndividual">
    <label class="label text-end" [title]="filter.label" [innerText]="filter.label" 
    [class.required-field]="filter.required"></label>
    <div class="datepicker {{ isIndividual ? 'mr-n2' : '' }}">
        <input class="bg-default shadow mr-n2 pl-2 border-0 {{ isIndividual ? '' : 'w-200px' }}"
            placeholder="Please Select" readonly
            [(ngModel)]="value" ngbDatepicker #d="ngbDatepicker" 
            (click)="d.toggle()" (ngModelChange)="valueChange($event)" [minDate]="minDate">
        <i class="bi bi-x my-auto close-icon" *ngIf="value" (click)="valueChange($event)"></i>
    </div>
</div>