import { Component, OnInit, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { User } from 'src/app/shared/models/user';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ROLES } from 'src/app/app.const';
import { NAVBAR_MENU_CONFIG, QMenuItem } from './navbar.config';
import * as _ from 'lodash';
import { ManageRolesComponent } from '../manage-roles/manage-roles.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService, STORAGE_CONSTANTS } from 'src/app/shared/services/storage.service';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { AppService } from 'src/app/shared/services/app.service';
import { UserService } from 'src/app/shared/services/user.service';
import { GTMService } from 'src/app/shared/services/gtm.service';
import * as CryptoJS from 'crypto-js';
import { Subscription } from 'rxjs';
import { SummaryService } from 'src/app/shared/services/summary.service';
import { BecomeAVipModal } from 'src/app/shared/modal/become-a-vip/become-a-vip.modal';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    rolesList: string;
    domainSuffix = environment.production ? "" : "-uat";
    totalMenuItems: QMenuItem[] = NAVBAR_MENU_CONFIG(this);
    menuItems: QMenuItem[] = [];
    sidebarVisible: boolean;
    user: User = {};
    isLoggedIn: boolean;
    loading: boolean;
    isDesktop = false;
    headerTitle = '';
    activeRole = '';
    appTitle = 'Qatch - Personalized Shopper at your fingertips';
    _router: Subscription;
    logoSuffix: string;
    discoverPage = false;

    constructor(
        public location: Location,
        private element: ElementRef,
        private authService: AuthService,
        private router: Router,
        protected deviceService: DeviceDetectorService,
        private ngbModal: NgbModal,
        private storageService: LocalStorageService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private appService: AppService,
        private userService: UserService,
        private gtmService: GTMService,
        private renderer: Renderer2,
        private summaryService: SummaryService,
        private modalService: NgbModal
    ) {
        this.sidebarVisible = false;
        this.loading = true;
        //Subscriber for Title Changes
        this.appService.getTitle().subscribe(title => this.setTitle(title || ''));
        // Subscribe for Firebase User Auth state
        this.authService.user$$.subscribe({
            next: async (user) => {
            this.isLoggedIn = this.authService.isLoggedIn();
            this.user = {
                ...this.user,
                ...user
            };
            if (this.isLoggedIn && user) {
                this.getUserProfile();
                this.markLoggedIn();
                this.initCustomerDataForGTMTags(this.user);
                this.summaryService.summary(this.authService.getActiveRole());
            }
            this.filterMenuItems();
            this.loading = false;
            },
            error: () => {
                this.loading = false;
            }
        });
    }

    ngOnInit() {
        this.initWindowScroll();
        this.onResize();
        this.subscribeForTitleUpdates();
        this.authService.role$$.subscribe(role => {
            if (role) {
                this.activeRole = role;
                this.filterMenuItems();
                this.onRoleChange();
            }
        });
        this.summaryService.summary$$.subscribe((summary: any) => {
            this.summaryService.handleSummaryUpdates(summary, this.totalMenuItems);
            this.filterMenuItems();
        });
        this.logoSuffix = this.hasTransparentNavbar() ? '-white' : '';
    }

    initWindowScroll() {
        this._router = this.router.events.subscribe(() => {
            const document = window.document;
            if (window.outerWidth > 991) {
                document.children[0].scrollTop = 0;
            } else {
                document.activeElement.scrollTop = 0;
            }
            this.sidebarClose();
            this.handleScroll(); // for handling on route change
        });
        this.renderer.listen('window', 'scroll', (event) => {
            this.handleScroll();
        });
        this.handleScroll(); // for handling on app reload
    }

    isVisible() {
        const path = this.location.prepareExternalUrl(this.location.path());
        this.discoverPage = path.includes('/discover');
        return !path.includes('/signup') && !this.isLoggedIn;
    }

    markLoggedIn() {
        const justLoggedIn = this.storageService.popFromAppCache(STORAGE_CONSTANTS.JUST_LOGGED_IN_EVENT);
        // Mark Logged In
        if(justLoggedIn) {
            this.userService.markLoggedIn().subscribe(resp=>{});
        }
    }

    initCustomerDataForGTMTags(user: User) {
        this.gtmService.push({
            'qatch.customer_id': user.uid,
            'qatch.order_id': user.uid,
            'qatch.email_sha1': user.email ? CryptoJS.SHA1(user.email).toString() : ''
        });
    }

    subscribeForTitleUpdates() {
        this.setTitle(this.getTitleFromRoute());
        this.router
            .events.pipe(
                filter(event => event instanceof NavigationEnd),
                map(this.getTitleFromRoute.bind(this))
            ).subscribe((ttl: string) => {
                this.setTitle(ttl);
            });
    }

    getTitleFromRoute() {
        let child = this.activatedRoute?.firstChild;
        while (child?.firstChild) {
            child = child.firstChild;
        }
        if (child?.snapshot.data['title']) {
            return child.snapshot.data['title'].trim();
        }
        return null;
    }

    setTitle(title: string) {
        this.titleService.setTitle(title || this.appTitle);
        this.headerTitle = title || '';
    }

    onRoleChange() {
        const route = this.authService.getNextRoute();
        this.changeRoute(route);
        this.summaryService.summary(this.authService.getActiveRole());
    }

    changeRoute(url: string) {
        if(url && url.length){
            if(url.includes('?'))
                this.appService.navigateByURL(url);
            else 
                this.appService.navigateWithPreserve(url);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isDesktop = this.deviceService.isDesktop();
        this.filterMenuItems();
        this.handleScroll();
    }

    handleScroll() {
        const navbar: HTMLElement = this.element.nativeElement.children[0];
        if(!navbar) return;
        if (this.hasTransparentNavbar()) {
            const number = window.scrollY;
            if (number > 150 || window.pageYOffset > 150) {
                // add logic
                navbar.classList.remove('navbar-transparent');
                this.logoSuffix = '-black';
            } else {
                // remove logic
                navbar.classList.add('navbar-transparent');
                this.logoSuffix = '-white';
            }
        } else {
            navbar.classList.remove('navbar-transparent');
            this.logoSuffix = '';
        }
    }

    getUserProfile() {
        this.authService.me().then((res: any) => {
            this.user = {
                ...this.user,
                ...res
            };
            this.rolesList = res? res.roles.join(' ') : '';
            this.setActiveRole();
            this.filterMenuItems();
        });
    }

    setActiveRole() {
        this.activeRole = this.authService.getActiveRole();
        const roles = this.user.roles;
        if (!roles?.includes(this.activeRole)) {
            this.activeRole = roles ? roles[0] : '';
            this.authService.setActiveRole(this.activeRole);
        }
    }

    filterMenuItems() {
        this.menuItems = this.filterUsingGuard(_.cloneDeep(this.totalMenuItems));
    }

    filterUsingGuard(navItems: QMenuItem[]) {
        const items:QMenuItem[] = navItems
        .filter((item: QMenuItem) => this.isVisibleOnAnyPlatform(item))
        .filter((item: QMenuItem) => {
            if (item.visibility) {
                return item.visibility() && item.guard.includes(this.activeRole);
            }
            if (item.isFixed) {
                return true;
            }
            if (item.subItems && item.subItems.length) {
                item.subItems = this.filterUsingGuard(item.subItems);
            }
            if (item.guard && item.guard.includes(ROLES.NONE)) {
                return false;
            }
            if (item.guard && item.guard.includes(ROLES.ALL)) {
                return true;
            }
            if (this.isLoggedIn && this.user.uid) {
                if (this.user.roles && item.id === 'change-role') {
                    return (this.user.roles.length > 1);
                } else if (this.user.roles && item.guard && item.guard.length) {
                    return item.guard.includes(this.activeRole);
                } else {
                    return false;
                }
            } else {
                if (item.guard && item.guard.includes(ROLES.VISITOR)) {
                    return true;
                }
                const res = (!item.guard || !item.guard.length) ? true : false;
                return res;
            }
        });
        return items;
    }

    isVisibleOnAnyPlatform(item: QMenuItem){
        return (item.onlyForDesktop === undefined && item.onlyForMobile === undefined)
               || this.visibileOnDesktop(item) || this.visibileOnMobile(item);
    }

    visibileOnMobile(item: QMenuItem){
        return item.onlyForMobile && !this.isDesktop;
    }

    visibileOnDesktop(item: QMenuItem){
        return item.onlyForDesktop && this.isDesktop;
    }

    async logOut() {
        this.appService.navigateByURL('/signin');
        //this.toastService.success('You have been logged out.');
        this.authService.logOut();
    }

    sidebarOpen() {
        const html = document.getElementsByTagName('html')[0];
        setTimeout(() => {
            const navbarT = this.element.nativeElement.getElementsByClassName('navbar-toggler')[0];
            if(navbarT) navbarT.classList.add('toggled');
        }, 500);
        const selectedDropdownMenu: any = document.querySelector('.active-dropdown > .dropdown-toggle');
        if (selectedDropdownMenu && !this.isDesktop) {
            selectedDropdownMenu['click']();
        }
        if(html) html.classList.add('nav-open');
        this.sidebarVisible = true;
    };

    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        const toggleButton = this.element.nativeElement.getElementsByClassName('navbar-toggler')[0];
        if (html && toggleButton) {
            toggleButton.classList.remove('toggled');
            this.sidebarVisible = false;
            html.classList.remove('nav-open');
        }
    };

    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    hasTransparentNavbar() {
        return this.isDesktop && this.pathCheck(path => {
            return ['/home', '/vip', '/'].includes(path);
        });
    }

    pathCheck(cb: Function) {
        let path = this.location.prepareExternalUrl(this.location.path());
        if (cb(path)) {
            return true;
        } else {
            return false;
        }
    }

    onItemClick(menuItem: any) {
        this.sidebarClose();
        if (menuItem.href) {
            location.href = menuItem.href;
        } else if (menuItem.click) {
            menuItem.click();
        }
    }

    hasActiveMenu(subMenuItems: any) {
        return subMenuItems.filter((item: any) => item.route && item.route.includes(location.pathname)).length ? 'active-menu-item' : '';
    }

    manageRolesPopup() {
        const modalRef = this.ngbModal.open(ManageRolesComponent, { centered: true, windowClass: 'app-manage-roles' });
        modalRef.componentInstance.roles = this.user.roles;
    }

    onReferAFriend() {
        if(this.user.referral_link){
            this.userService.referAFriend(this.user.referral_link);
        } else {
            this.userService.generateReferralLink().subscribe(resp => {
                if(resp.referral_link) {
                    this.userService.referAFriend(resp.referral_link);
                }
            })
        }
    }

    becomeAVIP() {
        const modalRef = this.modalService.open(BecomeAVipModal, { centered: true, size: 'lg' });
        modalRef.result.then((result) => {
        }, (reason) => {});
    }

    isPaymentInfoHeaderVisible() {
        return this.authService.isLoggedIn() && this.user.style_quiz_complete && !this.user.is_premium;
    }

    isActiveUser() {
        return this.authService.isLoggedIn() && this.user.activity_status_id === 1;
    }

    isBasicUser() {
        return this.authService.isLoggedIn() && this.user.activity_status_id === 9;
    }

    isPremiumUser() {
        return this.authService.isLoggedIn() && this.activeRole === ROLES.SUBSCRIBER && this.user.is_premium;
    }
}
