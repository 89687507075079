import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TakeQuizModalContent } from 'src/app/pages/private/subscriber/preferences/modal/take-quiz.modal';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { LocalStorageService } from '../services/storage.service';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class QuizGuard  {

  private isRedirectedOnce: string = 'IS_REDIRECTED_ONCE';

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private appService: AppService,
    private storageService: LocalStorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.me()
      .then( (user: User) => {
        const path = route.routeConfig.path;
        if(!this.authService.isLoggedIn()) {
          this.appService.navigateWithPreserve('/home');
          return false;
        } else {
          if (!user.style_quiz_complete) {
            return this.handleAsPerQuizStatus(user, route);
          } else if (!user.is_premium) {
            this.appService.navigateWithPreserve(`/internal/stripe/session`);
            return false;
          } else {
            return true;
          }
        }
      })
      .catch(() => {
        this.appService.navigateWithPreserve('/home');
        return false;
      });
  }

  handleAsPerQuizStatus(user: any, route: any) {
    if (user) {
      const path = route.routeConfig.path;
      if(path == 'style-me'){
        this.takeUpToQuiz();
        return false;
      }

      // Allow navigating to quiz routes if once redirected to specific route
      const isRedirected = this.storageService.get(this.isRedirectedOnce);
      if(isRedirected) {
        return true;
      }

      let result: boolean;
      if (!user.is_style_quiz_answered) {
        result = this.ifMatchOtherwiseRedirect(path, 'style-quiz');
      } 
      else if (!user.is_brand_quiz_answered) {
        result = this.ifMatchOtherwiseRedirect(path, 'brand-quiz');
      } 
      else if (!user.is_answer_quiz_answered){
        result = this.ifMatchOtherwiseRedirect(path, 'brand-quiz');
      } else {
        result = true;
      }
      this.storageService.set(this.isRedirectedOnce, 'true');
      return result;
    }
    return false;
  }

  ifMatchOtherwiseRedirect(path: string, route: string) {
    if(path == route) {
      return true;
    } else {
      this.appService.navigateWithPreserve(`/${route}`);
      return false;
    }
  }

  takeUpToQuiz() {
    const modalRef = this.modalService.open(TakeQuizModalContent, { centered: true });
    modalRef.result.then((result) => {
      if (result === true) { }
    },(reason) => {});
  }
}
