import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IQuizResponse, QuizService } from 'src/app/shared/services/quiz.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { QuestionControlService } from '../questions/service/question-control.service';

@Component({
  selector: 'uikit-form',
  templateUrl: './uikit-form.component.html',
  styleUrls: ['./uikit-form.component.scss']
})
export class UikitFormComponent implements OnInit {

  loading = false;
  result: IQuizResponse;
  userAnswersMapping: any;
  questionsTypeMapping: any = {};
  @Input() uikitForm: FormGroup;
  @Input() quizPage: string;
  
  @Output() updateFormControl = new EventEmitter();
  
  constructor(
    private quizService: QuizService,
    private questionControlService: QuestionControlService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.getQuestionAnswers();
  }

  hasControls() {
     return Object.keys(this.uikitForm?.controls).length;
    // return this.uikitForm && this.uikitForm.controls ? Object.keys(this.uikitForm.controls).length > 0 : false;

  }

  getQuestionAnswers() {
    this.loading = true;
    this.quizService.getQuestionAnswers(this.quizPage).subscribe({
      next: (res) => {
        this.result = res;
        this.result.questions = this.questionControlService.toFormQuestions(this.result.questions, [...this.result.user_answers]);
        this.questionsTypeMapping = this.result.questions.reduce( (old, current) => {
          old[current.question_id] = current.type;
          return old;
        }, {})
        this.updateFormControl.emit(this.questionControlService.toFormGroup(this.result.questions, {}));
        this.onControlChanges('');
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.userAnswersMapping = null;
        this.toastService.error('Unable to load onboarding questions. Please try again.');
      }
    });
  }

  getAnswers() {
    const controls = this.uikitForm.controls;
    let form = {};
    for(let key in controls) {
      if(!controls[key].disabled) {
        form[key] = controls[key].getRawValue();
      }
    }
    const answers = [];
    for (const key in form) {
      let answer = form[key];
      if (key.startsWith('question-id-')) {
        const question_id = key.replace('question-id-', '');
        if (answer instanceof Array) {
          // If dropdown, find answers from selected values
          if (answer[0] instanceof Object) {
            if(this.questionsTypeMapping[question_id] === 'multi-select-chip' && answer) {
              answer = answer.filter( ans => ans.checked && ans.itemName);
            }
            answer = answer.map(a => a.itemName);
          }
        } else {
          // convert answers to array
          answer = answer ? [answer] : [];
        }
        const ansQue = this.findUsersAnswerToQuestion(parseInt(question_id));
        answers.push({
          question_id: question_id,
          answer: typeof answer === 'object' ? JSON.stringify(answer) : answer,
          system_id: ansQue.system_id
        });
      }
    }
    return answers;
  }

  findUsersAnswerToQuestion(question_id: number, answers?: any[]) {
    if (!answers && !this.userAnswersMapping) {
      answers = this.result.user_answers;
      this.userAnswersMapping = answers.reduce((old, current) => old[current.question_id] === current, {});
    }
    return this.userAnswersMapping[question_id] || {};
  }

  parseUsersAnswerToQuestion(question_id: number, answers?: any[]) {
    const qa = this.findUsersAnswerToQuestion(question_id, answers);
    if (qa && qa.answer && qa.answer.includes('[')) {
      return JSON.parse(qa.answer);
    }
    return qa.answer;
  }

  onControlChanges(event) {
    this.questionControlService.applyRules(this.result.questions, this.uikitForm);
  }

}
