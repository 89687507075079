import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ITableList } from './base-list';
import { FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HttpService, LazyLoadRequest, toRequestParams } from '@hss-m/uikit-common';
import { has, isNull, isUndefined } from 'lodash';
import { AppService } from "src/app/shared/services/app.service";

export abstract class BaseService {
  appBaseUrl: string;
  dashboardBaseUrl: string;
  cacheKey = '';
  abstract apiUrl(): string;
  collectionName: string;  // abstract
  disabledCache = false;
  cache: {[key: string]: any} = {};
  datePipe: DatePipe = new DatePipe('en-US');
  lastAppliedFilter = {};

  constructor(
    protected http: HttpClient,
    protected appService: AppService,
    protected httpService: HttpService 
    ){
    this.appBaseUrl = `${this.appService.getAppBaseUrl()}`;
    this.dashboardBaseUrl = `${this.appService.getDashboardBaseUrl()}`;
  }

  getFilters(filters: any): Observable<any> {
    return this.http.post(`${this.apiUrl()}/filters`, {filters});
  }

  private getCollection(collectionName?: string) {
    return (collectionName || this.collectionName);
  }

  async list(event: any, collectionName?: string, baseUrl = this.dashboardBaseUrl): Promise<ITableList<any>> {
    event =  this.transformFilters(event);
    const collection = this.getCollection(collectionName);
    const key = `${collection}-list${event ? `-${JSON.stringify(event)}` : ''}-${this.cacheKey}`;
    if(this.cache[key]) {
      return this.cache[key];
    }
    let reqParams: LazyLoadRequest = event;
    reqParams = toRequestParams(event as LazyLoadEvent);
    this.lastAppliedFilter = reqParams;
    const res = await lastValueFrom(this.http.post(`${baseUrl}${collection}/list`, reqParams)) as Promise<ITableList<any>>;
    if(!this.disabledCache) {
      this.cache[key] = res;
    }
    return res;
  }

  export(collectionName?: string, baseUrl = this.dashboardBaseUrl, selection?: string[], selectionKey?: string) {
    let payload = this.lastAppliedFilter;
    if(selection?.length) {
      payload = {filters: {[selectionKey]: {value: selection, matchMode: 'in' }}};
    }
    const collection = this.getCollection(collectionName);
    return this.httpService.download(`${baseUrl}${collection}/download`, payload);
  }

  async findById(id: string, collectionName?: string, baseUrl = this.dashboardBaseUrl): Promise<any> {
    const res: any = await lastValueFrom(this.http.get(`${baseUrl}${this.getCollection(collectionName)}/${id}`)) as Promise<any>
    return res?.data;
  }

  async find(queryParams?: LazyLoadEvent, fixedQuery = {}, collectionName?, baseUrl = this.dashboardBaseUrl): Promise<any[]> {
    queryParams = queryParams || {rows: 20};
    const collection = this.getCollection(collectionName);
    const key = `${collection}-list${queryParams ? `-${JSON.stringify(queryParams)}` : ''}${fixedQuery ? `-${JSON.stringify(fixedQuery)}` : ''}`;
    if(this.cache[key]) {
      return this.cache[key];
    }
    const res: any = await lastValueFrom(this.http.post(`${baseUrl}${collection}/search`, {...toRequestParams(queryParams), ...fixedQuery}))as Promise<any>;
    if(!this.disabledCache) {
      this.cache[key] = res?.data as any[];
    }
    return res?.data as any[];
  }

  async doesExist(key: string, value: string, baseUrl = this.dashboardBaseUrl): Promise<boolean> {
    const query: LazyLoadEvent = {filters: {[key]: {value, matchMode: '='}}};
    const res: any = await lastValueFrom(this.http.post(`${baseUrl}${this.getCollection()}/exists`, query)) as Promise<any>
    return res.data ? res.data.exists : false;
  }

  create(data: any, baseUrl = this.dashboardBaseUrl): Promise<any> {
    this.resetCache();
    return lastValueFrom(this.http.post(`${baseUrl}${this.getCollection()}`, data));
  }

  delete(id: string, baseUrl = this.dashboardBaseUrl): Promise<any> {
    this.resetCache();
    return lastValueFrom(this.http.delete(`${baseUrl}${this.getCollection()}/${id}`));
  }

  update(id: string, data: any, baseUrl = this.dashboardBaseUrl) : Promise<any> {
    this.resetCache();
    return lastValueFrom(this.http.patch(`${baseUrl}${this.getCollection()}/${id}`, data));
  }
  
  transformFilters(searchParams?: LazyLoadEvent): LazyLoadEvent{
    if(searchParams?.filters){
      const filters = {};
      Object.keys(searchParams.filters).forEach((filter: string) => {
        const meta = searchParams.filters[filter];
        let metadata: FilterMetadata = Array.isArray(meta) ? meta[0] : meta;
        // Has value key 
        if(has(metadata, "value")){
          // Has non null value
          if(!isNull(metadata.value) && !isUndefined(metadata.value)){
            const isMultiselect = metadata.matchMode === 'in';
            if(metadata.matchMode?.includes('date')){
              metadata.value = this.datePipe.transform(metadata.value, 'Y-MM-d');
            } else if(metadata.value instanceof Object && !isMultiselect){
              metadata.value = metadata.value?.id || metadata.value?.label;
            }
            if((isMultiselect && metadata.value?.length) || !isMultiselect) {
              filters[filter] = metadata;
            }
          }
        }else if(metadata?.matchMode && ['is_null', 'not_null'].includes(metadata.matchMode)) {
          filters[filter] = metadata; // Don't remove this. This is required when value attr is not present but matchmode is present
        }
      });
      searchParams.filters = Object.keys(filters).length ? filters : null;
    }
    return searchParams;
  }

  resetCache() {
    this.cache = {};
  }

}

