import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })
export class GTMService {

    constructor() {}

    push(data: any) {
        try {
            (<any>window).dataLayer.push(data);
        } catch (e) {
            console.log(e);
        }
    }
}
