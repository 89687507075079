<footer class="footer">
  <div class="container">
    <div class="d-flex justify-content-between" *ngIf="!isHome()">
      <nav class="footer-nav">
        <ul>
          <li>
            <a class="nav-link home_facebook_icon" rel="tooltip" title="Like us on Facebook" data-placement="bottom"
              href="https://www.facebook.com/JoinQatch/" target="_blank">
              <i class="fa fa-facebook-square"></i>
            </a>
          </li>
          <li class="tiktok-link">
            <a class="nav-link home_tiktok_icon" rel="tooltip" title="Follow us on Tiktok" data-placement="bottom"
              href="https://www.tiktok.com/@joinqatch" target="_blank">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="28" viewBox="14 12 44 46"
                  style="fill:#FFFFFF;">
                  <path
                    d="M42.5,14C56.875,14,58,15.125,58,29.5v13C58,56.875,56.875,58,42.5,58h-13C15.125,58,14,56.875,14,42.5v-13	C14,15.125,15.125,14,29.5,14H42.5z M45.616,34.045v-3.79c0,0-2.003,0.01-3.695-1.54c-1.422-1.302-1.54-3.743-1.54-3.743h-3.79	c0,0,0,12.934,0,14.994s-1.793,3.127-3.079,3.127c-0.969,0-3.174-0.76-3.174-3.151c0-2.51,2.535-3.15,3.198-3.15	c0.663,0,0.924,0.142,0.924,0.142v-4c0,0-0.616-0.059-1.066-0.074c-3.825-0.13-6.988,3.303-6.988,7.083	c0,3.193,2.497,7.059,7.059,7.059c4.859,0,7.083-4.052,7.083-7.035c0-2.179,0-7.438,0-7.438s1.398,0.782,2.535,1.137	C44.219,34.021,45.616,34.045,45.616,34.045z">
                  </path>
                </svg>
              </div>
            </a>
          </li>
          <li>
            <a class="nav-link home_instagram_icon" rel="tooltip" title="Follow us on Instagram" data-placement="bottom"
              href="https://www.instagram.com/joinqatch/" target="_blank">
              <i class="fa fa-instagram"></i>
            </a>
          </li>
          <li>
            <a class="nav-link home_linkedin_icon" rel="tooltip" title="Follow us on LinkedIn" data-placement="bottom"
              href="https://www.linkedin.com/company/joinqatch/" target="_blank">
              <i class="fa fa-linkedin"></i>
            </a>
          </li>
          <ng-container *ngIf="isDesktop">
            <li><a class="home_privacy_policy_footer" [routerLink]="['/privacy-policy']"> Privacy Policy</a></li>
            <li><a class="homer_terms_service_footer" [routerLink]="['/terms']"> Terms Of Service</a></li>
          </ng-container>
        </ul>
      </nav>
      <div class="credits my-auto" *ngIf="isDesktop">
        <span class="copyright">
          © QATCHCO INC. {{test | date: 'yyyy'}}
        </span>
      </div>
    </div>
  </div>
  <qatch-chat *ngIf="isLoggedIn && user && (isAdmin || isStylist)"></qatch-chat>
</footer>
<lib-footer *ngIf="isHome()"></lib-footer>