<div *ngIf="loading" class="mb-0 q-convo-card hover-disabled">
  <div class="px-1 py-3 m-1">
    <div class="title-area position-relative px-2">
      <p-skeleton height="2rem" width="12rem" styleClass="mb-2 mx-auto"></p-skeleton>
      <p-skeleton  width="10rem" styleClass="mb-3 mx-auto"></p-skeleton>
    </div>
    <div class="image-area">
      <div class="rounded-circle overflow-hidden my-1 w-200">
        <p-skeleton shape="circle" size="15rem" styleClass="mb-3 mx-auto"></p-skeleton>
      </div>
    </div>
    <div>
      <div class="d-flex align-items-center justify-content-center mb-3">
        <p-skeleton height="2rem" width="10rem" styleClass=""></p-skeleton>
        <p-skeleton shape="circle" size="2rem" styleClass="ms-3"></p-skeleton>
      </div>
      <div>
        <p-skeleton height="1.5rem" width="5rem" styleClass="mx-auto"></p-skeleton>
      </div>
    </div>
  </div>
</div>


<div *ngIf="!loading" class="mb-0 q-convo-card" [class.hover-disabled]="isMobile" [class.selectedCard]="card.selected"
  [class.no-selection]="!selection" (click)="onCardClick()">
  <div class="card-plain px-1 py-3 m-1 d-flex flex-column align-items-center justify-content-between" [style]="style">
    <div *ngIf="card.title" class="title-area text-center position-relative px-2">
      <h5 class="mt-1 mb-0 fw-bold text-white ">{{card.title}}</h5>
      <p *ngIf="card.sub_title" class="text-white">{{card.sub_title}}</p>
      <div *ngIf="card.is_premium" class="position-absolute translate-middle crown">
        <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 576 512">
          <path
            d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" />
        </svg>
      </div>
    </div>
    <div class="image-area">
      <div *ngIf="card.image"
        class="rounded-circle overflow-hidden d-flex align-items-center justify-content-center my-1 {{card.is_waitlist? 'w-200 ': 'w-110'}}">
        <img class="" [src]="card.image" alt="img" width="100%" height="100%" />
      </div>
      <ng-container *ngIf="card.image_label && !card.is_waitlist">
        <svg viewBox="0 60 100 300">
          <path id="curve" d="M30,170C.50,220,190,150,0.0.0" />
          <text x="25">
            <textPath xlink:href="#curve" (click)="card.is_premium? onCardImageLabelClick($event) : '' ">
              {{card.image_label}}
            </textPath>
          </text>
        </svg>
      </ng-container>
    </div>
    <div *ngIf="card.is_waitlist" class="position-absolute comingSoon-banner text-white">
      <marquee bgcolor="#e38f81" width="355" height="25px" direction="right" loop="50" scrollamount="5">
        COMING SOON • COMING SOON • COMING SOON • COMING SOON• COMING SOON • COMING SOON •
        COMING SOON • COMING SOON• COMING SOON • COMING SOON</marquee>
    </div>
    <div *ngIf="card.description && !card.is_waitlist" class="position-relative message-area ms-1 mt-3 mb-3">
      <div class="msg-box1">
        <div class="msg-box2 p-2 d-flex">
          <p class="m-auto text-start text-description" title="{{card.description}}">{{card.description}}</p>
          <div class="triangle-right"></div>
        </div>
        <div class="triangle-right"></div>
      </div>
      <div class="mt-2 text-end text-white vfs-4">{{card.delivery_label}}</div>
    </div>
    <div class="text-center">
      <div *ngIf="card.join_convo_label" class="ckeckbox-area d-flex align-items-center justify-content-center my-1">
        <h5 class="m-0 mt-1 me-2 text-white fw-bold ">{{card.join_convo_label}}</h5>
        <div class="check">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 512 512">
            <path fill="#32BEA6"
              d="M504.1,256C504.1,119,393,7.9,256,7.9C119,7.9,7.9,119,7.9,256C7.9,393,119,504.1,256,504.1C393,504.1,504.1,393,504.1,256z">
            </path>
            <path fill="#FFF"
              d="M392.6,172.9c-5.8-15.1-17.7-12.7-30.6-10.1c-7.7,1.6-42,11.6-96.1,68.8c-22.5,23.7-37.3,42.6-47.1,57c-6-7.3-12.8-15.2-20-22.3C176.7,244.2,152,229,151,228.4c-10.3-6.3-23.8-3.1-30.2,7.3c-6.3,10.3-3.1,23.8,7.2,30.2c0.2,0.1,21.4,13.2,39.6,31.5c18.6,18.6,35.5,43.8,35.7,44.1c4.1,6.2,11,9.8,18.3,9.8c1.2,0,2.5-0.1,3.8-0.3c8.6-1.5,15.4-7.9,17.5-16.3c0.1-0.2,8.8-24.3,54.7-72.7c37-39.1,61.7-51.5,70.3-54.9c0.1,0,0.1,0,0.3,0c0,0,0.3-0.1,0.8-0.4c1.5-0.6,2.3-0.8,2.3-0.8c-0.4,0.1-0.6,0.1-0.6,0.1l0-0.1c4-1.7,11.4-4.9,11.5-5C393.3,196.1,397,184.1,392.6,172.9z">
            </path>
          </svg>
        </div>
      </div>
      <div class="mt-2 text-white">
        <p>{{card.join_convo_fees_label}}</p>
      </div>
    </div>

  </div>
</div>