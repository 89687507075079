<div class="wrapper non-transparent-header">
    <div class="profile">
        <div class="filter"></div>
        <div class="container">
            <div class="row">
                <div class="mx-auto">
                    <div class="vmy-3 mt-0 q-page-container">
                        <app-loader [background]="false" [visible]="ploading" [type]="'full-page'" [text]="'Please wait while we setup your account...'"></app-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
