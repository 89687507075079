import { NgModule } from '@angular/core';
import { PrimengModule } from './primeng.module';
import { NGBBootstrapModule } from './ngb.module';

import { 
  UIKIT_COMPONENTS
} from './components';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ...UIKIT_COMPONENTS
  ],
  imports: [
    RouterModule,
    PrimengModule,
    NGBBootstrapModule
  ],
  exports: [
    ...UIKIT_COMPONENTS
  ]
})
export class UiKitModule { }
