import { Directive } from '@angular/core';
import { RouterLink } from '@angular/router';

@Directive({
  selector: '[routerLink]'
})
export class QueryParamsMergeDirective {

  constructor (routerLink: RouterLink) {
    routerLink.queryParamsHandling = 'preserve';
  }

}
