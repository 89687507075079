import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionControlService } from 'src/app/components/questions/service/question-control.service';
import { BaseQuizOnboardingComponent } from 'src/app/pages/public/quiz-onboarding/quiz-onboarding-base.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PhoneUtil } from 'src/app/shared/utils/phone-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChooseCancelReasonComponent } from './choose-cancel-reason/choose-cancel-reason.component';
import { AppService } from 'src/app/shared/services/app.service';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { PRODUCT_REACTION_CLICK_COUNT } from 'src/app/app.const';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ComponentsModule,
        ChooseCancelReasonComponent
    ]
})
export class ProfileComponent extends BaseQuizOnboardingComponent {
    @Input() quickQuiz = false;
    remoteQuestionsMapping = {
        '15': 'edit_account_pronouns'
    };
    progressBar = {
      total: 0,
      value: 0
    };
    user: any;
    countryCode: string;
    isPauseClicked = false;
    isSubscribeClicked = false;
    isReSubscribeClicked = false;
    step: string;
    cancelPremiumMemberShip: boolean = false;
    email: string;
    @Output() quizAnswered = new EventEmitter();

    constructor(
        authService: AuthService,
        router: Router,
        private fb: FormBuilder,
        toastService: ToastService,
        quizService: QuizService,
        protected override userService: UserService,
        questionControlService: QuestionControlService,
        protected override deviceService: DeviceDetectorService,
        private readonly ngbModal: NgbModal,
        private appService: AppService
    ) {
        super(authService, router, toastService, quizService, userService, questionControlService, deviceService);
        this.countryCode = PhoneUtil.getCountryCode();
        this.step = 'ASK_PREF';
    }

    override ngOnInit() {
        super.ngOnInit();
        this.getProfile();
    }

    getProfile() {
        this.ploading = true;
        this.authService.me(true, { referral_info: true }).then(res => {
            this.user = res;
            super.onInit();
            this.ploading = false;
            if(this.user.referral_link) {
                this.progressBar.value = this.user.referral_info.group_of-this.user.referral_info.missed_referrals;
                this.progressBar.total = this.user.referral_info.group_of;
            }
        }, () => {
            this.ploading = false
            this.toastService.error('Unable to load your profile details. Please try again.');
        }
        );
    }

    async updateProfile() {
        if (this.onboardingQuizForm.valid) {
            this.loading = true;
            let value = JSON.parse(JSON.stringify(this.onboardingQuizForm.getRawValue()));
            value = {
                ...value,
                ...value.quiz
            }
            delete value.quiz;    
            await this.userService.updateProfile({
                ...value,
                answers: [
                    ...this.answers(value)
                ]
            }).subscribe({
                next: (resp: any) => {
                    if(this.quickQuiz) {
                        if(resp['style_quiz_complete']) {
                            localStorage.removeItem(PRODUCT_REACTION_CLICK_COUNT);
                        }
                        this.quizAnswered.emit();
                    }

                    this.loading = false;
                    this.authService.patchCurrentUser({...this.user, ...resp});
                    const msg = this.quickQuiz ? 'Your style preferences has been updated succesfully.' : 'Your profile has been updated succesfully.'
                    this.toastService.success(msg);
                }, 
                error: () => {
                    this.loading = false
                    this.toastService.error(`Your profile details couldn't be updated. Please try again.`);
                }
            });
        }
    }

    async updateActivityStatus(status_id: any, membership?: string) {
        const activity_status = this.user.activity_status_id === status_id ? 1 : status_id;
        if (status_id === 8) {
            this.isPauseClicked = true;
        } else if (status_id === 2) {
            this.isSubscribeClicked = true;
        } else {
            this.isReSubscribeClicked = true;
        }
        this.userService.updateProfile({
            activity_status_id: activity_status,
            membership
        }).subscribe({
            next: resp => {
                this.user = {...this.user, ...resp};
                this.step = 'ASK_PREF';
                this.toastService.success('Your preferences has been updated succesfully.');
            },
            error: () => {
                this.resetLoaders();
                this.toastService.error(`Your preferences couldn't be updated. Please try again.`);
            },
            complete: () => {
                this.resetLoaders();
            }
        });
    }

    resetLoaders() {
        this.isPauseClicked = false;
        this.isSubscribeClicked = false;
        this.isReSubscribeClicked = false;
    }

    isResumed() {
        return this.user.activity_status_id !== 2 && this.user.activity_status_id !== 8;
    }

    extraFormElements() {
        return {
            first_name: new FormControl( this.user?.first_name , [Validators.required, Validators.minLength(3)]),
            last_name: new FormControl( this.user?.last_name ),
            instagram_handle: new FormControl( this.user?.instagram_handle ),
            phone_number: new FormControl( { value:PhoneUtil.withoutCountryCode(this.user?.phone_number) , disabled: true },
             [Validators.minLength(10), Validators.maxLength(10)] ),
            email: new FormControl( this.user?.email, [Validators.required, Validators.email] ),
            cancel_premium_membership: new FormControl(false),
            restart_premium_membership: new FormControl(false),
            quiz: this.fb.group({})
        }
    }

    updateFormControl(childFormControls: any) {
        this.onboardingQuizForm.setControl('quiz', childFormControls);
    }

    getQuizForm() {
        return this.onboardingQuizForm?.get('quiz');
    }

    getAnsweredQuestions() {
        return [];
    }

    redirectToForgotPasswordPage() {
        this.appService.navigateWithPreserve('/reset-password');
    }

    previousPage(): void {
        this.appService.navigateWithPreserve('/brand-quiz');
    }

    getType(): string {
        return '';
    }

    override questionsToBeFilteredById() {
        const questions = [];
        for (let qId in this.remoteQuestionsMapping) {
            questions.push(Number(qId));
        }
        return questions;
    };

    updateStep(step: any) {
        this.step = step;
    }

    chooseCancelReason() {
        const modalRef = this.ngbModal.open(ChooseCancelReasonComponent, { centered: true });
        modalRef.componentInstance.email = this.user.email;
        modalRef.componentInstance.close.subscribe(() => {
            this.updateActivityStatus(2, this.cancel_premium_membership.value ? 'CANCEL' : null);
        })
    }

    pauseMembership() {
        this.updateActivityStatus(8, this.cancel_premium_membership.value ? 'PAUSE' : null);
    }

    resubscribe(){
        this.updateActivityStatus(1, this.restart_premium_membership.value ? 'RESUME' : null);
    }

    updateBillingInfo(){
        if(this.user.paying_status_id !== 2){
            this.appService.navigateWithMerge('internal/stripe/session');
        }
    }

    get rewardEarned() {
       return this.user.referral_info.gift_cards_redeemed * this.user.referral_info.reward;
    }

    get profileTitle() {
        return this.user ? `${this.user.first_name}'s Preferences` : '';
    }

    toggleSwitch(control: AbstractControl){
        control.patchValue(!control.value);
    }

    toBeUpdatedPayingStatus(isResubscribe?: boolean){
        if(isResubscribe) {
            if(this.user.paying_status_id === 4){
                return `resume`;
            } else {
                return `subscribe to`;
            }
        } else {
            if(this.user.paying_status_id === 1){
                return `pause/cancel`;
            } else {
                return `cancel`;
            }
        }
    }

    get cancel_premium_membership() { return this.onboardingQuizForm.get('cancel_premium_membership'); }
    get restart_premium_membership() { return this.onboardingQuizForm.get('restart_premium_membership'); }
    get controls() {return this.onboardingQuizForm?.controls}
}
