import { Component, ViewChild } from '@angular/core';
import { UikitFormComponent } from '../../../uikit-form/uikit-form.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FORM_FIELDS } from './config.const';
import { cloneDeep } from 'lodash';
import { StylistsService } from 'src/app/pages/private/stylists/stylists.service';
import { LocalStorageService, STORAGE_CONSTANTS } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-chat-advance-filter',
  templateUrl: './chat-advance-filter.component.html',
  styleUrls: ['./chat-advance-filter.component.scss']
})
export class ChatAdvanceFilterComponent {
  loading = false;
  isNew = true;
  formData = {};
  formFields = cloneDeep(FORM_FIELDS);
  @ViewChild(UikitFormComponent) uikitFormComponent: UikitFormComponent;

  constructor(
    private activeModal: NgbActiveModal,
    private stylistsService: StylistsService,
    private storageService: LocalStorageService
  ) { 
    this.formData = this.storageService.getObject(STORAGE_CONSTANTS.CHAT_ADVANCE_FILTERS, {});
    this.isNew = this.formData ? false : true;
  }

  ngOnInit() {
    this.getFilterData();
  }

  getFilterData() {
    this.stylistsService.getFilters([{ filterId: 'stylist' }])
      .subscribe(({ filters }: any) => {
        this.formFields[0].data = filters['stylist'];
        this.uikitFormComponent.updateFormField(this.formFields[0], false);
      });
  }

  applyFilter() {
    this.loading = true;
    this.persistFiltersData();
    this.activeModal.close(true);
  }

  clearAll() {
    this.uikitFormComponent.form.reset();
    this.persistFiltersData();
  }

  persistFiltersData() {
    const payload = this.uikitFormComponent.getRawValues();
    this.storageService.setObject(STORAGE_CONSTANTS.CHAT_ADVANCE_FILTERS, payload);
  }

  clearAllButtonValidation() {
    return this.controls ?
      !this.formFields?.find(field => this.controls[field.fieldId]?.value?.length) : true;
  }

  onClose() {
    this.activeModal.dismiss('Cross click');
  }

  get controls() { return this.uikitFormComponent?.form?.controls; }
}