import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HThemeConfig } from '.';
import { LocalStorageService } from '@hss-m/uikit-common';

@Injectable({providedIn: 'root'})
export class HThemeService {
    lscKey = 'qatch_theme_config';
    config: HThemeConfig = {
        dark: false,
        inputStyle: 'outlined',
        ripple: true,
        menuMode: 'static',
        scale: 12
    };
    private configUpdate = new Subject<HThemeConfig>();
    configUpdate$ = this.configUpdate.asObservable();

    constructor(private lsc: LocalStorageService) {
        this.config = this.lsc.getObject(this.lscKey, this.config);
    }

    updateConfig(config: HThemeConfig) {
        this.config = config;
        this.lsc.setObject(this.lscKey, this.config);
        this.configUpdate.next(config);
    }

    getConfig() {
        return this.config;
    }

    isStatic() {
        return this.config.menuMode === 'static';
    }

    isOverlay() {
        return this.config.menuMode === 'overlay';
    }

}
