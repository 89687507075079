import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignupGuard  {

  waitlist_uri: string = `${environment.waitlist_uri}`;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isLoggedIn() || (route.queryParams['quiz_type'] && route.queryParams['ota_token'])) {
      return true;
    }
    return this.authService.verifySignupToken(route.queryParams)
      .then( ({ isValid }: any) => {
        if (isValid) {
          return true;
        } else {
          return this.errorHandle();
        }
      })
      .catch(this.errorHandle.bind(this));
  }

  errorHandle() {
    window.open(this.waitlist_uri, '_self');
    return false;
  }

}
