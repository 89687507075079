import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from 'src/app/app.const';
import { QatchComponent } from 'src/app/components/qatch.component';
import { Debounce } from 'src/app/shared/decorators/debounce.decorator';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { LocalStorageService, STORAGE_CONSTANTS } from 'src/app/shared/services/storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { PhoneUtil } from 'src/app/shared/utils/phone-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { interval, lastValueFrom, Subscription } from 'rxjs';
import { Auth, getAuth } from '@angular/fire/auth';
import { Logger } from 'src/app/shared/utils/log-util';

declare global {
  interface Window {
    oobCodeConfirmation:any;
  }
}
// Mask the global 'window' for this snippet file
const mWindow = {
  recaptchaVerifier: undefined
};

@Component({
  template: ''
})
export abstract class BaseVerifyPhone extends QatchComponent implements OnDestroy {

  loggedInUser: any;
  verifyForm: FormGroup;
  countryCode: string;
  ploading = false;
  loading = false;
  oobCode: string;
  oobCodeConfirmation?: any;
  errorMessages = {
    400: 'Invalid phone number. Please check your area code & mobile number.'
  }
  isIphone: boolean;
  smsText: string;
  smsShortCode: number;
  numberAuthpollingSubs: Subscription;
  constructor (
    protected firebaseAuth: Auth,
    protected override authService: AuthService,
    protected toastService: ToastService,
    protected route: ActivatedRoute,
    protected quizService: QuizService,
    protected storageService: LocalStorageService,
    protected deviceDetectorService: DeviceDetectorService
  ) {
    super(deviceDetectorService, authService);
    this.countryCode = PhoneUtil.getCountryCode();
    if (window.oobCodeConfirmation) {
      this.oobCodeConfirmation = window.oobCodeConfirmation;
    }
    this.firebaseAuth = getAuth();
    this.ploading = false;

    this.authService.me().then((res: any) => {
      this.loggedInUser = res;
    });
    this.isIphone = this.deviceDetectorService.os.toLowerCase() === 'ios';
    this.smsText = `sms:${APP_CONFIG.TWILIO.SMS_SHORT_CODE}${this.isIphone ? `&` : `?`}body=${ encodeURI(APP_CONFIG.TWILIO.SMS_TEXT) }`;
  }

  watchPhoneNumberVerification(){
    const request_token_id = this.storageService.getFromSession(STORAGE_CONSTANTS.REQUEST_TOKEN_ID);
    if(!request_token_id) return;

    this.numberAuthpollingSubs = interval(APP_CONFIG.TWILIO.INTERVAL)
    //.pipe(take(3))
    .subscribe(n => {
        this.authService.checkIfNumberAuthenticated({ request_token_id }).subscribe({
          next: (resp: any) => {
            if(resp['number_auth']){
              const phone_number = this.storageService.getFromSession(STORAGE_CONSTANTS.PHONE_NUMBER);
              this.storageService.removeFromSession(STORAGE_CONSTANTS.REQUEST_TOKEN_ID);
              this.postOTPVerifySuccess({ request_token_id, phone_number, token: resp.token });
            }
          },
          error: (err) => {
            Logger.error(`Not authenticated yet`);
          }
        });
    });
  }
  
  async verifyOTP(data: any, uri='ota-token/confirm') {
    const otp = this.form().value.otp;
    const request_token_id = this.storageService.getFromSession(STORAGE_CONSTANTS.REQUEST_TOKEN_ID);
    this.authService.confirmOTP(uri, {
      otp,
      request_token_id,
      ...data
    }).subscribe({
      next: (resp: any) => {
        //this.toastService.success(`Your phone ${this.withCountryCode()} has been verified succesfully.`);
        const phone_number = this.storageService.popFromSession(STORAGE_CONSTANTS.PHONE_NUMBER);
        this.storageService.removeFromSession(STORAGE_CONSTANTS.REQUEST_TOKEN_ID);
        this.postOTPVerifySuccess({ otp, request_token_id, phone_number, token: resp.token });
      },
      error: error => {
        this.toastService.error(error);
        this.postOTPVerifyFailed();
      }
    });
  }

  postOTPVerifySuccess(data){};

  postOTPVerifyFailed(){};

  @Debounce(APP_CONFIG.DEBOUNCE_INTERVAL_SHORT)
  async sendVerificationCodeOnMobile(showToast=true) {
    try {
      const phoneNumber = this.withCountryCode();
      const requestIdToken = this.storageService.getFromSession(STORAGE_CONSTANTS.REQUEST_TOKEN_ID);
      const tokenResult:any = await lastValueFrom(this.authService.sendOTP({ 
        phone_number: requestIdToken ? null : phoneNumber,
        intent: requestIdToken ? null : this.getOtpIntent(),
        request_token_id: requestIdToken
      }));
      this.storageService.storeInSession(STORAGE_CONSTANTS.REQUEST_TOKEN_ID, tokenResult['request_token_id']);
      this.storageService.removeFromSession(STORAGE_CONSTANTS.OTP_INTENT);
      if(showToast)this.toastService.success(`A verification code has been sent to your device. Please don't share this code with anyone.`);
    } catch (error) {
      this.toastService.error(error);
      throw(error);
    }
  }

  getOtpIntent() {
    let intent = this.storageService.getFromSession(STORAGE_CONSTANTS.OTP_INTENT, 'RESET_PASSWORD');
    return intent;
  }

  _phoneNumber(): string {
    let phone = this.loggedInUser ? this.loggedInUser.phone_number : '';
    let form = this.form();
    if (form && form.get('phone_number')) {
      phone = form.get('phone_number')?.value;
      phone = PhoneUtil.extractPhone(phone);
    } else if(this.storageService.hasInSession(STORAGE_CONSTANTS.PHONE_NUMBER)) {
      phone = this.storageService.getFromSession(STORAGE_CONSTANTS.PHONE_NUMBER);
    } else if (this.quizService && this.quizService.readExtraInfo()) {
      phone = this.quizService.readExtraInfo().phone_number;
    }  
    return phone;
  }

  form(): FormGroup {
    return this.verifyForm;
  }

  withCountryCode(): string {
    const phone = this._phoneNumber();
    const withCountryCode = PhoneUtil.withCountryCode(phone);
    return withCountryCode;
  }

  withoutCountryCode(): string {
    const phone = this._phoneNumber();
    const withoutCountryCode = PhoneUtil.withoutCountryCode(phone);
    return withoutCountryCode;
  }

  recaptchaRendered() {
    console.log('Re-Captcha Rendered');
  }

  override ngOnDestroy() {
    this.numberAuthpollingSubs?.unsubscribe();
    super.ngOnDestroy();
  }

}

