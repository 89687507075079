<div *ngIf="controls && (controls[name]?.touched || submitted) && controls[name]?.errors" class="alert p-1 m-0">

    <div class="p-error capitalize" *ngIf="(controls[name]?.touched || submitted) && controls[name]?.errors?.required">
        <!-- {{placeholder || name}} is required. -->
        This field is required.
    </div>
    
    <ng-container *ngIf="controls[name]?.touched && controls[name]?.errors && !controls[name]?.errors?.required">
        <div class="p-error" *ngIf="validationCaseAdded(controls[name]?.errors)">
            Please enter valid {{placeholder || name}} 
            <!-- {{controls[name]?.errors | json}} -->
        </div>
        <div class="p-error" *ngIf="controls[name].errors.minlength">
            Please enter valid {{placeholder || name}} - (Min {{controls[name].errors.minlength.requiredLength}} char required)
        </div>
        <div class="p-error" *ngIf="controls[name].errors.maxlength">
            Please enter valid {{placeholder || name}} - (Max {{controls[name].errors.maxlength.requiredLength}} char required)
        </div>
        <div class="p-error" *ngIf="controls[name].errors.invalid_date_time">
            {{controls[name].errors.message}}
        </div>
    </ng-container>

</div>