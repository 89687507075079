import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ITopbarData, UikitTopbarComponent } from '@hss-m/uikit-qp-ng';
import { DialogService } from 'primeng/dynamicdialog';
import { IMenuItem, LocalStorageService, RouteService, UikitMenuModule } from '@hss-m/uikit-common';
import { MENU_BOTTOM_ITEMS, MENU_DATA, NAVBAR_DATA } from './dashboard.const';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BaseDashboard } from '../../classes/base-dashboard';
import { HThemeService } from 'src/app/modules/h-theme/h-theme.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HThemeModule } from 'src/app/modules/h-theme/h-theme.module';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';
import { ChatModule } from 'src/app/shared/modules/chat/chat.module';
import { User } from 'src/app/shared/models/user';
import { AppService } from 'src/app/shared/services/app.service';
import { ManageRolesComponent } from 'src/app/components/manage-roles/manage-roles.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SummaryService } from 'src/app/shared/services/summary.service';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss',
  standalone: true,
  animations: [
    trigger('submenu', [
      state('hidden', style({
        height: '0px'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  imports: [
    CommonModule,
    RouterModule,
    HThemeModule,
    UikitMenuModule,
    PaginatorModule,
    DropdownModule,
    UikitTopbarComponent,
    ChatModule,
    ToastModule
  ]
})
export class DashboardLayoutComponent extends BaseDashboard implements AfterViewInit, OnDestroy, OnInit {
  routerEventsRef: Subscription
  menuItems: IMenuItem[] = [];
  menuBottomItems: IMenuItem[] = MENU_BOTTOM_ITEMS(this);
  navbar: ITopbarData = NAVBAR_DATA(this);
  activeRole: string;
  user: User = {};
  constructor(
    private ngbModal: NgbModal,
    private appService: AppService,
    public authService: AuthService,
    public routeService: RouteService,
    public dialogService: DialogService,
    public override renderer: Renderer2,
    public messageService: MessageService,
    public override lsc: LocalStorageService,
    public override hThemeService: HThemeService,
    private summaryService: SummaryService
  ) {
    super(renderer, hThemeService, lsc);
  }

  ngOnInit() {
    super.onInit();
    this.getUserProfile();
    this.authService.role$$.subscribe(role => {
      if (role) {
        this.activeRole = role;
        this.menuItems = this.filterMenuItems();
        this.onRoleChange();
      }
    });
    this.summaryService.summary$$.subscribe((summary: any) => {
      if(summary) {
        const {unresolved_grievances, unfulfilled_requests} = summary;
        const supportIndex = this.menuItems.length - 1;
        const item = this.menuItems[supportIndex].items[0].items[0];
        const badge = (item.id === 'grievances' ? unresolved_grievances : unfulfilled_requests) || 0;
        this.menuItems[supportIndex].items[0].badge = badge;
        item.badge = badge;
      }
    });
  }

  getUserProfile() {
    this.authService.me().then((res: any) => {
      this.user = {
        ...this.user,
        ...res
      };
      this.setActiveRole();
      this.menuItems = this.filterMenuItems();
    });
  }

  setActiveRole() {
    this.activeRole = this.authService.getActiveRole();
    const roles = this.user.roles;
    if (!roles?.includes(this.activeRole)) {
      this.activeRole = roles ? roles[0] : '';
      this.authService.setActiveRole(this.activeRole);
    }
    this.summaryService.summary(this.activeRole);
  }

  async loadMenuData() {
    this.navbar = NAVBAR_DATA(this);
  }

  filterMenuItems(items: any = cloneDeep(MENU_DATA)) {
    return items.filter(item => {
      if (item.accessCode) {
        const roles = item.accessCode.split(',');
        if (item.items) {
          item.items = this.filterMenuItems(item.items);
        }
        return roles.includes(this.activeRole);
      } else {
        if (item.items) {
          item.items = this.filterMenuItems(item.items);
        }
        return !item.label ? item.items?.length : true;
      }
    });
  }

  onRoleChange() {
    const route = this.authService.getNextRoute();
    this.changeRoute(route);
  }

  changeRoute(url: string) {
    if (url && url.length) {
      if (url.includes('?'))
        this.appService.navigateByURL(url);
      else
        this.appService.navigateWithPreserve(url);
    }
  }

  manageRolesPopup() {
    const modalRef = this.ngbModal.open(ManageRolesComponent, { centered: true, windowClass: 'app-manage-roles' });
    modalRef.componentInstance.roles = this.user.roles;
  }

  logout() {
    this.appService.navigateByURL('/signin');
    this.authService.logOut();
  }

  ngAfterViewInit(): void {
    this.afterViewInit();
  }

  ngOnDestroy(): void {
    this.destroy();
    this.routerEventsRef?.unsubscribe();
  }

}