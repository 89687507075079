import { Injectable } from "@angular/core";
import { Magic } from "magic-sdk";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MagicLinkService {

    private magicSdk: Magic;

    constructor(
    ) {
        this.magicSdk = new Magic(environment.magicLink.API_KEY);
        this.magicSdk.preload().then(() => {
            console.log('Magic <iframe> loaded.');
        }
        );
    }

    logout(){
        if(this.magicSdk && this.magicSdk.user){
            this.magicSdk.user.logout();
          }
    }

    login(data: any) {
        if(data.email) {
          return this.magicSdk.auth.loginWithEmailOTP(data);
        } else {
          return this.magicSdk.auth.loginWithSMS(data);
        }
    }
}