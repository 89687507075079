<form class="d-flex justify-content-start uikit-dform" [formGroup]="form">
  <div *ngFor="let field of fields; index as indx;" class="uikit-dform-item form-control-{{field.fieldId}}"
    [class.textarea-height]="field.type === formFieldTypes.textarea || field.type === formFieldTypes.editor"
    [class.datepicker-height]="field.type === formFieldTypes.date_time_picker">
    <div class="input-group form-group-no-border d-flex">

      <label class="label text-end uikit-dform-label" [title]="field.label" [innerText]="field.label"
        [class.required-field]="field.required"></label>

      <div class="form-check mb-0 uikit-dform-input flex-column" [ngSwitch]="field.type"
        [class.textarea-height]="field.type === formFieldTypes.textarea || field.type === formFieldTypes.editor"
        [class.datepicker-height]="field.type === formFieldTypes.date_time_picker"
        [class.d-flex]="field.type === formFieldTypes.tristate_checkbox"
        [class.has-validation-error]="controls && (controls[field.fieldId]?.touched || isSubmittedOnce) && controls[field.fieldId]?.errors">

        <label *ngSwitchCase="formFieldTypes.checkbox" class="form-check-label mb-2">
          <input class="form-check-input" type="checkbox" [formControlName]="field.fieldId">
          <span class="form-check-sign"></span>
        </label>

        <p-triStateCheckbox *ngSwitchCase="formFieldTypes.tristate_checkbox" inputId="field.fieldId"
          [formControlName]="field.fieldId" class="my-auto">
        </p-triStateCheckbox>

        <div *ngSwitchCase="formFieldTypes.toggle_switch" class="d-flex justify-content-start align-items-center"
          style="margin-top: 5px;">
          <span class="me-2 offLabel" (click)="ontoggleLabelClick(field.fieldId)"> {{ field.toggleSwitch?.offLabel || 'Draft'}} </span>
          <bSwitch [switch-on-color]="'primary'" [switch-off-color]="'default'" [switch-off-text]="field.toggleSwitch?.offText || ' '"
            [switch-on-text]="field.toggleSwitch?.onText || ' '" [formControlName]="field.fieldId">
          </bSwitch>
          <span class="ms-2 onLabel" (click)="ontoggleLabelClick(field.fieldId)"> {{ field.toggleSwitch?.onLabel || 'Publish'}} </span>
        </div>

        <p-chips *ngSwitchCase="formFieldTypes.textinput_chips" [formControlName]="field.fieldId"
          (onBlur)="onBlurEvent.emit(field.fieldId)"></p-chips>

        <div *ngSwitchCase="formFieldTypes.phone" class="d-flex justify-content-start align-items-center bg-default shadow grey-border phone-input-group">
          <span class="input-suffix">{{countryCode}}</span>
          <input type="number" class="bg-default pl-2 phone-input" appPhoneMask [formControlName]="field.fieldId"
            placeholder="(xxx)xxx-xxxx">
        </div>

        <input *ngSwitchCase="formFieldTypes.textinput" type="text"
          class="bg-default shadow pl-2 grey-border uikit-dform-input" [placeholder]="field.placeholder || ''"
          [formControlName]="field.fieldId" (blur)="onBlurEvent.emit(field.fieldId)">

        <ngb-dropdown *ngSwitchCase="formFieldTypes.dropdown" class="bg-default shadow grey-border uikit-dform-input"
          [data]="field.data" [settings]="field.settings" [formControlName]="field.fieldId">
        </ngb-dropdown>

        <ngb-dropdown *ngSwitchCase="formFieldTypes.multi_dropdown"
          class="bg-default shadow grey-border uikit-dform-input" [data]="field.data" [settings]="field.settings"
          [formControlName]="field.fieldId">
        </ngb-dropdown>

        <ngb-dropdown *ngSwitchCase="formFieldTypes.remote_dropdown"
          class="bg-default shadow grey-border uikit-dform-input" [data]="field.data" [settings]="field.settings"
          [formControlName]="field.fieldId">
          <c-search *ngIf="field.url">
            <ng-template>
              <div class="d-flex h-100">
                <input type="text" (keyup)="onRemoteSearchDropdownSearch($event.target?.value, field)"
                  placeholder="Search {{ field.label }}"
                  style="border: none;width: 100%; height: 100%;outline: none;" />
                <div class="my-auto mx-2">
                  <span class="spinner-border spinner-border-xs" role="status" aria-hidden="true"
                    *ngIf="field.loading"></span>
                </div>
              </div>
            </ng-template>
          </c-search>
        </ngb-dropdown>

        <q-editor *ngSwitchCase="formFieldTypes.textarea" showInputPanel="false" [formControlName]="field.fieldId"
          (onBlurEvent)="onBlurEvent.emit(field.fieldId)" [control]="form.get(field.fieldId)">
        </q-editor>

        <q-editor *ngSwitchCase="formFieldTypes.editor" [formControlName]="field.fieldId"
          (onBlurEvent)="onBlurEvent.emit(field.fieldId)">
        </q-editor>

        <div class="color-picker d-flex" *ngSwitchCase="formFieldTypes.colorPicker">
          <input type="color" class="bg-default shadow grey-border uikit-dform-input"
            [placeholder]="field.placeholder || ''" [formControlName]="field.fieldId"
            (blur)="onBlurEvent.emit(field.fieldId)">
          <!-- <p-colorPicker [formControlName]="field.fieldId"></p-colorPicker> -->
        </div>

        <uikit-date-time *ngSwitchCase="formFieldTypes.date_time_picker" [formControlName]="field.fieldId"
          [control]="form.get(field.fieldId)" [isNew]="isNew"></uikit-date-time>

        <div class="datepicker" *ngSwitchCase="formFieldTypes.datepicker">
          <input class="bg-default shadow pl-2 grey-border" [placeholder]="field.placeholder || 'Please Select'"
            readonly ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" [formControlName]="field.fieldId">
          <!-- <i class="bi bi-x my-auto close-icon" *ngIf="value" (click)="valueChange($event)"></i> -->
        </div>

        <ngb-dropdown *ngSwitchCase="formFieldTypes.dropdown_switch"
          [data]="[{id: 'yes',itemName: 'YES'},{id: 'no',itemName: 'NO'}]"
          class="bg-default shadow grey-border uikit-dform-input" [settings]="field.settings"
          [formControlName]="field.fieldId">
        </ngb-dropdown>

        <uikit-error [submitted]="isSubmittedOnce" [controls]="controls" [name]="field.fieldId"
          [placeholder]="field.placeholder"></uikit-error>

      </div>

    </div>
  </div>
</form>