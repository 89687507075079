import { NgModule } from '@angular/core';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';


const NGBODULES = [
  NgbCarouselModule
];

@NgModule({
  imports: NGBODULES,
  exports: NGBODULES
})
export class NGBBootstrapModule { }
