import { Component, HostListener, Renderer2 } from "@angular/core";
import { LocalStorageService } from "@hss-m/uikit-common";
import { Subject, takeWhile } from "rxjs";
import { HThemeConfig } from "src/app/modules/h-theme";
import { HThemeService } from "src/app/modules/h-theme/h-theme.service";

enum DEVICE_TYPES {
    DESKTOP = 'DESKTOP',
    MOBILE = 'MOBILE',
    TAB = 'TAB'
}

@Component({
    selector: 'base-dashboard',
    template: ''
})
export abstract class BaseDashboard {
    isMobile$ = false;
    isDesktop$ = false;
    isTab = false;
    sDevice: DEVICE_TYPES = DEVICE_TYPES.DESKTOP;
    device = new Subject<string>();
    isAndroid = false;
    isiPhone = false;
    menuInactiveDesktop: boolean;
    menuActiveMobile: boolean;
    overlayMenuActive: boolean;
    staticMenuInactive: boolean = false;
    profileActive: boolean;
    topMenuActive: boolean;
    topMenuLeaving: boolean;
    documentClickListener: () => void;
    menuClick: boolean;
    topMenuButtonClick: boolean;
    configActive: boolean;
    configClick: boolean;
    config: HThemeConfig;
    isDestroy = false;
    sideBarMenu: boolean;
    abstract loadMenuData(): Promise<void>;

    constructor(
        protected renderer: Renderer2,
        protected hThemeService: HThemeService,
        public lsc: LocalStorageService,
    ) { 
        this.setDeviceDetector();
        this.getMobileOperatingSystem();
    }

    onInit() {
        this.config = this.hThemeService.config;
        this.hThemeService.configUpdate$
            .pipe(takeWhile(() => !this.isDestroy))
            .subscribe(config => {
                this.config = config;
            });
        this.loadMenuData();
        if(this.config.sideBarMenu) {
            this.sideBarMenu = false;
            this.toggleMenu();
        }
    }

    afterViewInit() {
        // hides the overlay menu and top menu if outside is clicked
        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
            if (!this.isDesktop$) {
                if (!this.menuClick) {
                    this.menuActiveMobile = false;
                }

                if (!this.topMenuButtonClick) {
                    this.hideTopMenu();
                }
            }
            else {
                if (!this.menuClick && this.hThemeService.isOverlay()) {
                    this.menuInactiveDesktop = true;
                }
                if (!this.menuClick) {
                    this.overlayMenuActive = false;
                }
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.configClick = false;
            this.menuClick = false;
            this.topMenuButtonClick = false;
        });
    }

    toggleMenu(event?: Event) {
        this.menuClick = true;
        this.updateSideBarConfig();
        if (this.isDesktop$) {
            if (this.hThemeService.isOverlay()) {
                if (this.menuActiveMobile === true) {
                    this.overlayMenuActive = true;
                }

                this.overlayMenuActive = !this.overlayMenuActive;
                this.menuActiveMobile = false;
            }
            else if (this.hThemeService.isStatic()) {
                this.staticMenuInactive = !this.staticMenuInactive;
            }
        }
        else {
            this.menuActiveMobile = !this.menuActiveMobile;
            this.topMenuActive = false;
        }
        event?.preventDefault ? event?.preventDefault() : '';
    }

    updateSideBarConfig() {
        this.sideBarMenu = !this.sideBarMenu;
        this.config.sideBarMenu = this.sideBarMenu;
        this.lsc.setObject(this.hThemeService.lscKey, this.config);
    }

    closeSideMenu() {
        this.overlayMenuActive = false;
        this.menuActiveMobile = false;
        this.topMenuActive = false;
        this.staticMenuInactive = true;
    }

    isOverlay() {
        return this.hThemeService.isOverlay();
    }

    isStatic() {
        return this.hThemeService.isStatic();
    }

    toggleProfile(event: Event) {
        this.profileActive = !this.profileActive;
        event.preventDefault();
    }

    toggleTopMenu(event: Event) {
        this.topMenuButtonClick = true;
        this.menuActiveMobile = false;

        if (this.topMenuActive) {
            this.hideTopMenu();
        } else {
            this.topMenuActive = true;
        }
        
        event.preventDefault();
    }

    hideTopMenu() {
        this.topMenuLeaving = true;
        setTimeout(() => {
            this.topMenuActive = false;
            this.topMenuLeaving = false;
        }, 1);
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    onSearchClick() {
        this.topMenuButtonClick = true;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.setDeviceDetector();
    }

    setDeviceDetector() {
        this.isMobile$ = false;
        this.isDesktop$ = false;
        if ((innerWidth < 600)) {
            this.isMobile$ = true;
            this.sDevice = DEVICE_TYPES.MOBILE;
        } else if ((innerWidth < 1000)) {
            this.isTab = true;
            this.sDevice = DEVICE_TYPES.TAB;
        } else {
            this.isDesktop$ = true;
            this.sDevice = DEVICE_TYPES.DESKTOP;
        }
        setTimeout(() => {
            this.device.next(this.sDevice);
        });
    }

    getMobileOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            this.isAndroid = true; //"Windows Phone"
        }
        if (/android/i.test(userAgent)) {
            this.isAndroid = true;
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
            this.isiPhone = true;
        }
    }

    isDesktop() {
        return this.isDesktop$;
    }

    isMobile() {
        return this.isMobile$;
    }

    destroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
        this.device?.unsubscribe();
        this.isDestroy = false;
    }

}
