<hss-query-builder class="w-100"  [(ngModel)]="query" (ngModelChange)="onQueryChange();" [config]="config"
  [classNames]="queryBuilderClasses" [disabled]="disabled">
  <ng-container *queryInput="let rule; let field=field; let options=options; type: 'category'; let onChange=onChange">
    <p-dropdown  [options]="options"  [(ngModel)]="rule.value" optionLabel="name" optionValue="value" />
  </ng-container>
  <ng-container *queryInput="let rule; let field=field; type: 'chips'; let onChange=onChange">
    <p-chips [(ngModel)]="rule.value" />
  </ng-container>
  <ng-container *queryInput="let rule; let options=options; type: 'multiselect'; let onChange=onChange">
    <ng-container *ngTemplateOutlet="multiselectInput;context: {options:options,onChange:onChange,rule:rule}">
    </ng-container>
  </ng-container>
  <ng-container *queryInput="let rule; let options=options; type: 'date'; let onChange=onChange">
    <div class="q-control-size d-inline-block">
      <input *ngIf="rule.operator !== 'expr'" type="date" class="q-input-control" [(ngModel)]="rule.value" (change)="onChange()">
      <input *ngIf="rule.operator === 'expr'" type="text" class="q-input-control" [(ngModel)]="rule.value" (change)="onChange()">
    </div>
  </ng-container>
</hss-query-builder>

<ng-template #multiselectInput let-options="options" let-onChange="onChange" let-rule="rule">
  <ngb-dropdown [data]="options" [style.width.px]="300" (onSelect)="onChange()" (onDeSelect)="onChange()"
    (onDeSelectAll)="onChange()" [(ngModel)]="rule.value" class="bg-default shadow d-inline-block"
    [settings]="getSettings()">
  </ngb-dropdown>
</ng-template>