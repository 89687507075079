<div class="d-flex align-items-center justify-content-between collapsible-header mb-0 mt-5 q-page-filter-header"
    [attr.aria-expanded]="!isCollapsed" *ngIf="(!isDesktop || isAlwaysCollapsible) && !fullSizeForm && !usedAsForm" (click)="collapse.toggle()">
    <span class="m-0">Filter By</span>
    <div class="d-flex filter-header-actions">
        <button class="px-2 me-0 cursor-pointer my-auto btn btn-link text-danger" (click)="clearAll($event)" *ngIf="filterId" [disabled]="isDisabledClearAll()"> Clear All </button>
        <button *ngIf="applyFilterButton" class="px-2 apply--filter-btn cursor-pointer my-auto btn" (click)="onApplyFilter($event)" > Apply Filter </button>
        <i class="bi bi-chevron-{{ isCollapsed ? 'down' : 'up'}} my-auto cursor-pointer"></i>
    </div>
</div>

<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="justify-content-center q-page-filter-container">
    <form class="filters-form d-flex mx-auto" [ngClass]="{'always-collapsible': isAlwaysCollapsible, 'w-90': !isAlwaysCollapsible}">
        <div class="my-auto filter-label" *ngIf="isDesktop && !isAlwaysCollapsible && !fullSizeForm && !nonDashboardPage && !usedAsForm">Filter By</div>
        
        <div *ngIf="!loading" class="form-row justify-content-start mx-auto {{ fullSizeForm ? 'w-100' : ''}}">
            <ng-container *ngFor="let filter of filters;">
                <ng-container *ngIf="!isHidden(filter)">
                    <ng-container [ngSwitch]="filter.filterType">
                        <div class="my-2 {{filterClass(filter)}}" *ngSwitchCase="filterTypes.reaction">
                            <app-reaction [filter]="filter" (onChange)="onFiltersValueChange($event)" [isIndividual]="filter.isIndividual || fullSizeForm"></app-reaction>
                        </div>
                        <div class="my-2 {{filterClass(filter)}}" *ngSwitchCase="filterTypes.price">
                            <app-price [filter]="filter" (onChange)="onFiltersValueChange($event)" [value]="_cachedFilter[filter.filterId]"></app-price>
                        </div>
                        <div class="my-2 {{filterClass(filter)}}" *ngSwitchCase="filterTypes.dropdown">
                            <app-dropdown [filter]="filter" (onChange)="onFiltersValueChange($event)" [value]="_cachedFilter[filter.filterId]" [isIndividual]="filter.isIndividual || fullSizeForm"></app-dropdown>
                        </div>
                        <div class="my-2 {{filterClass(filter)}}" *ngSwitchCase="filterTypes.multi_dropdown">
                            <app-dropdown [filter]="filter" [singleSelection]="false" (onChange)="onFiltersValueChange($event)" [value]="_cachedFilter[filter.filterId]" [isIndividual]="filter.isIndividual || fullSizeForm"></app-dropdown>
                        </div>
                        <div class="my-2 {{filterClass(filter)}}" *ngSwitchCase="filterTypes.remote_dropdown">
                            <app-remote-dropdown [filter]="filter" (onChange)="onFiltersValueChange($event)" [value]="_cachedFilter[filter.filterId]" [isIndividual]="filter.isIndividual || fullSizeForm"></app-remote-dropdown>
                        </div>
                        <div class="my-2 {{filterClass(filter)}}" *ngSwitchCase="filterTypes.dropdown_switch">
                            <app-dropdown-switch [filter]="filter" (onChange)="onFiltersValueChange($event)" [value]="_cachedFilter[filter.filterId]" [isIndividual]="filter.isIndividual || fullSizeForm"></app-dropdown-switch>
                        </div>
                        <div class="my-2 checkbox-container {{filterClass(filter)}}" *ngSwitchCase="filterTypes.checkbox">
                            <app-checkbox [filter]="filter" [value]="_cachedFilter[filter.filterId]"
                            (onChange)="onFiltersValueChange($event)"
                            [filtered]="filtered[filter.filterId]" [isIndividual]="filter.isIndividual || fullSizeForm"></app-checkbox>
                        </div>
                        <div class="my-2 datepicker-container {{filterClass(filter)}}" *ngSwitchCase="filterTypes.datepicker">
                            <app-datepicker [filter]="filter" [value]="_cachedFilter[filter.filterId]"
                            (onChange)="onFiltersValueChange($event)" [isIndividual]="filter.isIndividual || fullSizeForm"></app-datepicker>
                        </div>
                        <div class="my-2 textinput-container {{filterClass(filter)}}" *ngSwitchCase="filterTypes.textinput">
                            <app-text-input [filter]="filter" [value]="_cachedFilter[filter.filterId]"
                            (onChange)="onFiltersValueChange($event)" [isIndividual]="filter.isIndividual || fullSizeForm"></app-text-input>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </form>
</div>