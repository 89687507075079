import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ChipQuestion } from '../types/chip/question-chip';
import { MultiSelectQuestion } from '../types/dropdown/question-MultiSelect';
import { SingleSelectQuestion } from '../types/dropdown/question-SingleSelect';
import { QuestionBase } from '../types/question-base';
import { SliderQuestion } from '../types/slider/question-slider';
import { SwitchQuestion } from '../types/switch/question-switch';
import { TextboxQuestion } from '../types/textbox/question-textbox';

@Injectable()
export class QuestionControlService {
  questionRuleRegx = /Q\.##(\d+)##/g;
  constructor() { }

  toFormGroup(questions: QuestionBase<string>[], groups: any) {
    const group: any = {
      ...groups
    };
    questions.forEach(question => {
      const validators = this.validators(question, question.validators);
      group[question.key] = new FormControl(question.answer, validators);
    });
    return new FormGroup(group);
  }

  validators(question: any, validators: any): ValidatorFn[] {
    return validators.map((validator: any) => {
      switch (validator.type) {
        case 'email': {
          return Validators.email;
        }
        case 'required': {
          question.required = true;
          return Validators.required;
        }
        case 'minLength': {
          return Validators.minLength(validator.value);
        }
        case 'maxLength': {
          return Validators.maxLength(validator.value);
        }
        default: {
          return () => {};
        }
      }
    });
  }

  toFormQuestions(questions, answeredQuestions): QuestionBase<string>[] {

    const formQuestions: QuestionBase<string>[] = questions.map((question) => {
      let formQuestion = null;
      try {
        const qConfig = question.config ? JSON.parse(question.config) : {};
        const validators =  qConfig.validators || [];
        const settings = qConfig.settings || {};
        const options = question.allowable_answers && question.allowable_answers.trim().length > 0 ? JSON.parse(question.allowable_answers).options : [];
        const genericFields = {
          question_id: question.question_id,
          key: `question-id-${question.question_id}`,
          label: question.question_text,
          type: question.input_type,
          controlType: question.input_type,
          validators: validators,
          placeholder: question.placeholder,
          helpText: question.helpText,
          options: options,
          settings: settings,
          answer: answeredQuestions?.find(que => question.question_id === parseInt(que.question_id))?.answer,
          visible: qConfig.visible,
          visible_rule: qConfig.visible_rule,
          default_value: qConfig.value
        };
        switch (question.input_type) {
          case 'multi-select': {
            formQuestion = new MultiSelectQuestion({
              ...genericFields
            });
            break;
          }
          case 'single-select': {
            formQuestion = new SingleSelectQuestion({
              ...genericFields
            });
            break;
          }
          case 'multi-select-chip': {
            formQuestion = new ChipQuestion({
              ...genericFields
            });
            break;
          }
          case 'single-select-chip': {
            formQuestion = new ChipQuestion({
              ...genericFields
            });
            break;
          }
          case 'switch': {
            formQuestion = new SwitchQuestion({
              ...genericFields
            });
            break;
          }
          case 'slider': {
            formQuestion = new SliderQuestion({
              ...genericFields
            });
            break;
          }
          case 'textbox': {
            formQuestion = new TextboxQuestion({
              ...genericFields
            });
            break;
          }
        }
      } catch (error) {
        console.error(`Error ocurred while parsing question with QID: ${question.question_id} and Q_text: ${question.question_text}`, error);
      }
      return formQuestion;
    });
    return formQuestions.filter(formQuestion => formQuestion != null && formQuestion.visible);
  }

  applyRules(questions, formGroup: FormGroup) {
    const answerMapping = questions.reduce((old, current) => {
      old[current.question_id] = current;
      return old
    }, {});
    return questions.map( question => {
      if(question.visible_rule) {
        const isVisible = this.executeRule(question.visible_rule, answerMapping);
        question.visible = isVisible;
        const control = formGroup.get(question.key);
        if(!isVisible && control && !control?.disabled) {
          control?.disable();
          control?.reset();
        } else if(isVisible && control?.disabled) {
          control?.enable();
        }
      }
      return question;
    });
  }

  parseQuestion(rule: string) {
    return rule.match(this.questionRuleRegx);
  }

  executeRule(rule: string, answerMapping) {
    const quets = this.parseQuestion(rule) || [];
    quets.forEach((str: string) => {
      let match = str.match(/\d+/g) || '';
      if (match) {
        const question_id = match[0];
        rule = rule.replace(str, `'${this.getAnswer(answerMapping[question_id])}'`);
      }
    })
    try {
      return eval(rule)
    } catch(err) {
      console.error(err);
      return false;
    };
  }

  getAnswer(question): string {
    let answer: any = '';
    switch (question.controlType) {
      case 'single-select':
      case 'multi-select':
      case 'multi-select-chip':
      case 'single-select-chip': {
        let ans = question.answer || [];
        if(question.controlType === 'multi-select-chip' && question.answer) {
          ans = question.answer?.filter( ans => ans.checked && ans.itemName);
        }
        answer = ans.map(a => a.itemName).join(',');
        break;
      }
      // case 'switch': {
      //   answer = question.answer.map(i => i.mmm);
      //   break;
      // }
      // case 'slider': {
      //   answer = question.answer.map(i => i.mmm);
      //   break;
      // }
      // case 'textbox': {
      //   answer = question.answer.map(i => i.mmm);
      //   break;
      // }
    }
    return answer;
  }

}
