import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToastService {
  modal = new Subject<any>();

  show(textOrTpl: string | TemplateRef<any>, success = true, autoClose: boolean=true) {
    this.modal.next({ textOrTpl, success, autoClose});
  }

  info(message: string, autoClose: boolean=true) {
    this.show(message, true, autoClose);
  }

  success(message: string, autoClose: boolean=true) {
    this.show(message, true, autoClose);
  }

  error(error: any, defErr?: string) {
    let message = '';
    if(typeof error === 'string') {
      message = error.toString();
    } else if (error.rawMessage){
      message = error.rawMessage;
    } else if (error.error){
      if(typeof error.error === 'string') {
        message = error.error.toString();
      } else if(error.error.errors && Array.isArray(error.error.errors) && error.error.errors.length) {
        message = error.error.errors[0].msg;
      } else if(typeof error.error.error === 'string') {
        message = error.error.error;
      }
    } 
    
    // if(!message) {
    //   message = "Something unexpected";
    // }

    if(!message) {
      message = defErr || 'Oops, Something unexpected has occurred. Please try again.';
    }

    this.show(message, false, false);
  }
}
