import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QatchComponent } from '../qatch.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class LoaderComponent extends QatchComponent {

  @Input() visible: boolean;
  @Input() type: 'full-page' | 'item' = 'item';
  @Input() text: string;
  @Input() background: boolean = true;

  constructor(
    protected override deviceService: DeviceDetectorService,
    protected override authService: AuthService,
  ) {
    super(deviceService, authService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

}
