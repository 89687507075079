import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { NgChatModule } from 'hss-chat';
import { GalleriaModule } from 'primeng/galleria';
import { DialogModule } from 'primeng/dialog';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ButtonModule} from 'primeng/button';
import {ListboxModule} from 'primeng/listbox';
import { LinkyModule } from 'ngx-linky';
import { HrefUpdatePipe } from './pipes/href-update.pipe';
import { ChatMessageSettingComponent } from './components/chat-message-setting/chat-message-setting.component';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { ChatAdvanceFilterComponent } from './components/chat-advance-filter/chat-advance-filter.component';
import { UikitFormModule } from '../uikit-form/uikit-form.module';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { QModalHeaderComponent } from 'src/app/components/modal/q-modal-header.component';
import { RemoteSearchDropdownComponent } from '../filters/filter-types/remote-search/dropdown.component';

const NGBMODULES = [
  GalleriaModule,
  DialogModule,
  OverlayPanelModule,
  ButtonModule,
  ListboxModule,
  LinkyModule,
  AvatarModule
];

const CHAT_COMPONENTS = [ ChatComponent, ChatMessageSettingComponent, ChatAdvanceFilterComponent, HrefUpdatePipe ]

@NgModule({
  declarations: CHAT_COMPONENTS,
  imports: [
    CommonModule,
    NgChatModule,
    DropdownModule,
    FormsModule,
    UikitFormModule,
    LoaderComponent,
    QModalHeaderComponent,
    RemoteSearchDropdownComponent,
    ...NGBMODULES
  ],
  exports: CHAT_COMPONENTS
})
export class ChatModule { }

@NgModule({
  imports: [
    CommonModule,
    ChatModule,
    RemoteSearchDropdownComponent,
    RouterModule.forChild([{path: '', component: ChatComponent}])
  ]
})
export class ChatDashboardModule { }