import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/shared/services/toast.service';

export enum BUTTONS {
  CONFIRM = 'Confirm',
  DONE = 'Done',
  CANCEL = 'Cancel',
}

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header" *ngIf="title">
      <h4 class="modal-title">{{title}}</h4>
      <button type="button" class="close btn btn-just-icon btn-link" aria-label="Close" (click)="activeModal.dismiss('cancel')">
        <i class="bi bi-x"></i>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
      <small *ngIf="note" class="text-muted"> {{note}} </small>
    </div>
    <div class="modal-footer">
      <div class="py-2 d-flex justify-content-end">
        <button *ngFor="let button of buttons" type="button" class="btn {{button.classes}}" (click)="onButtonClick(button)">{{ button.type }}</button>
      </div>
    </div>`,
    styles: [`
    .modal-footer .btn-link {
        padding: 5px;
        width: auto;
    }
  `],
  standalone: true,
  imports: [CommonModule]
})
export class ConfirmModalContent {

  @Input() message: string;
  @Input() note: string;
  @Input() title: string;
  @Input() buttons: {classes: string, type: BUTTONS}[];

  constructor(public activeModal: NgbActiveModal, private toastService: ToastService) {}

  onButtonClick(button){
    this.activeModal.close(button);
  }

}