import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { lastValueFrom } from 'rxjs';
import { APP_CONFIG } from 'src/app/app.const';
import { QuestionControlService } from 'src/app/components/questions/service/question-control.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { LocalStorageService } from 'src/app/shared/services/storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PhoneUtil } from 'src/app/shared/utils/phone-utils';
import { QATCH_CONSTS } from 'src/app/shared/utils/qatch-constants';
import { BaseQuizOnboardingComponent } from '../quiz-onboarding/quiz-onboarding-base.component';

@Component({
    selector: 'app-quiz-style-choice',
    templateUrl: './quiz-style-choice.component.html',
    styleUrls: ['./quiz-style-choice.component.scss']
})
export class QuizStyleChoiceComponent extends BaseQuizOnboardingComponent {

    formData: any;
    user: any;
    brands: any[] = [];
    brandTypes = [
        { name: 'Minority Owned', checked: false, id: 'quiz_style_choice_brand_minority' },
        { name: 'Female Owned', checked: false, id: 'quiz_style_choice_brand_female' },
        { name: 'Sustainable/Ethical', checked: false, id: 'quiz_style_choice_brand_sustainable' }
    ];
    brandLoading: boolean;
    BRAND_TYPE_QUE_ID = 16;
    quizType: string;
    user_meta: any = null;
    devices = PhoneUtil.getDevices();
    DEVICE_TYPE_QUE_ID = 18;
    remoteQuestionsMapping = {
        '5': 'almost_done_price_point_slide',
        '4': 'almost_done_size_requirements',
        '3': 'almost_done_age_group'
    };
    brandsVisible: number;
    dobValidation = {
        minDate: {year: 1950, month: 1, day: 1},
        maxDate: null
    };
    constructor(
        authService: AuthService,
        router: Router,
        toastService: ToastService,
        quizService: QuizService,
        userService: UserService,
        questionControlService: QuestionControlService,
        private storageService: LocalStorageService,
        deviceService: DeviceDetectorService,
        private datePipe: DatePipe,
        private calendar: NgbCalendar, 
        private formatter: NgbDateParserFormatter
    ) {
        super(authService, router, toastService, quizService, userService, questionControlService, deviceService);
        this.quizType = this.storageService.get(QATCH_CONSTS.STORAGE_KEYS.QUIZ_TYPE);
        this.formData = this.quizService.readTextLikeBffPageInfo();
        this.dobValidation.maxDate = this.calendar.getPrev(this.calendar.getToday(), 'y', 18);
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.authService.me().then(res => {
            this.user = res;
            const styles = this.quizService.readStyles();
            // Redirect to previous page if styles havent answered
            if (!styles && !this.authService.isLoggedIn()) {
                this.previousPage();
            } else {
                super.onInit();
                this.getBrands();
            }
        }, () => {
            this.ploading = false
            this.toastService.error('Unable to load your profile details. Please try again.');
        }
        );
    }

    getBrands() {
        this.brandLoading = true
        this.quizService.getOnboardingBrands().subscribe(res => {
            this.brandsVisible = this.isMobile ? 1 : 2;
            this.brands = res.brands;
            this.brandLoading = false;
            this.markSelected(this.getUserBrands(res.user_brands));
            if(res.user_brands.length){
                this.brands = this.brands.sort((br1, br2) => br1.checked === br2.checked ? 0 : (br1.checked ? -1 : 1));
            }
        }, () => {
            this.brandLoading = false
            this.toastService.error('Unable to load onboarding questions. Please try again.');
        }
        )
    }

    // Setup local page question-answers if any after Form is setup by base class
    override postFormGroupSetup() {
        const answers = this.quizService.readStyleChoice()?.answers;
        if (answers) {
            this.patchBrandType(answers);
        } else {
            this.patchBrandType(this.rawAnswers);
        }
    }

    patchBrandType(answers: any) {
        const brandTypeAnswer = answers.find((answer: any) => answer.question_id === 16);
        if (brandTypeAnswer) {
            if (typeof brandTypeAnswer.answer === 'string') {
                brandTypeAnswer.answer = JSON.parse(brandTypeAnswer.answer);
            }

            brandTypeAnswer.answer.forEach((sAns: any) => {
                const answer = this.brandTypes.find(bt => bt.name === sAns);
                if (answer) {
                    answer.checked = true;
                }
            });
        }
    }

    getUserBrands(userBrands: any) {
        if (this.authService.isLoggedIn()) {
            return userBrands;
        } else {
            return this.quizService.readStyleChoice()?.brands || [];
        }
    }

    markSelected(sBrands: any) {
        if (sBrands) {
            sBrands.forEach((sBrand: any) => {
                const brand: any = this.brands.find((brand: any) => brand.onboarding_brand_id === sBrand.onboarding_brand_id);
                if (brand) {
                    brand.checked = true;
                    brand.system_id = sBrand.system_id;
                }
            });
        }
    }

    async updateOnboardingQuizAnswers() {
        if (this.onboardingQuizForm.valid) {
            this.loading = true;
            try {
                if (this.authService.isLoggedIn()) {
                    const { brands, answers } = this.styleChoiceData();
                    await lastValueFrom(this.userService.updateOnboardingQuizAnswers(
                        answers,
                        brands,
                        this.brands.filter((brand: any) => !brand.checked && brand.system_id),
                        {
                            instagram_handle: this.instagram_handle.value,
                            dob: this.dateOfBirth(),
                            email: this.email.value
                        }
                    ));
                    if(this.user.paying_status_id == 2) {
                        this.router.navigate(['/internal/stripe/session'], {queryParamsHandling: 'merge'});
                    } else {
                        this.router.navigate(['/general/edit-account'], {queryParamsHandling: 'merge'});
                    }
                } else if (this.quizType === QATCH_CONSTS.STORAGE_KEYS.QUIZ_TYPE_SHORT) {
                    const user_meta = this.storageService.getObject(QATCH_CONSTS.STORAGE_KEYS.USER_DETAILS);
                    await this.updateDetailsForUsersInWaitlist({
                        ...user_meta,
                        ...this.styleChoiceData(),
                        styles: this.quizService.readStyles()
                    });
                } else {
                    this.quizService.writeStyleChoice(this.styleChoiceData());
                    this.router.navigate(['/almost-there'], { queryParamsHandling: 'merge' });
                }
            } catch (err) {
                this.loading = false;
                this.toastService.error(err);
            }
        }
    }

    dateOfBirth() {
        if(!this.dob?.value) return '';
        const dob = this.dob.value;
        return this.datePipe.transform(new Date(dob.year, dob.month-1, dob.day), APP_CONFIG.DATES.DATE_FORMAT);
    }

    styleChoiceData() {
        const brandTypes = this.brandTypes.filter(type => type.checked).map(type => type.name);
        const ansQue = this.findUsersAnswerToQuestion(this.BRAND_TYPE_QUE_ID);
        const value = this.onboardingQuizForm.value;
        return {
            brands: this.brands.filter((brand: any) => brand.checked),
            answers: [
                ...this.answers(JSON.parse(JSON.stringify(value))),
                { question_id: this.BRAND_TYPE_QUE_ID, answer: JSON.stringify(brandTypes), system_id: ansQue.system_id }
                // ,
                // { question_id: this.DEVICE_TYPE_QUE_ID, answer: JSON.stringify([this.devices.find(device => device.checked)?.title]), system_id: ansQue.system_id}
            ]
        }
    }

    toggle(obj: any) {
        obj.checked = !obj.checked;
    }

    extraFormElements() {
        const isLoggedIn = this.authService.isLoggedIn();
        const dob = this.formatter.parse(isLoggedIn ? this.user?.dob : this.user_meta?.dob);
        return {
            instagram_handle: new FormControl( isLoggedIn ? this.user?.instagram_handle : this.user_meta?.instagram_handle ),
            dob: new FormControl( dob, [Validators.required]),
            email: new FormControl( isLoggedIn ? this.user?.email : this.user_meta?.email, [Validators.required, Validators.email])
        }
    }

    getAnsweredQuestions() {
        const formData = this.quizService.readStyleChoice();
        return formData?.answers || [];
    }

    previousPage(): void {
        this.router.navigate(['/style-quiz'], {queryParamsHandling: 'merge'});
    }

    getType(): string {
        return 'text_like_bff';
    }

    toggleDevice(obj: any) {
        this.devices.forEach(device => device.checked = false);
        obj.checked = !obj.checked;
    }

    hasDeviceType(){
        return this.devices.find(device => device.checked);
    }

    override questionsToBeFilteredById(): any[] {
        return [4,5];
    };
    
    get instagram_handle() { return this.onboardingQuizForm.controls['instagram_handle']; }
    get dob() { return this.onboardingQuizForm.controls['dob']; }
    get email() { return this.onboardingQuizForm.controls['email']; }
    get controls() {return this.onboardingQuizForm?.controls}
}