import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ComponentsModule } from './components/components.module';
import { AuthModule } from './pages/auth/auth.module';
import { PublicModule } from './pages/public/public.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Firebase Modules
import { CustomReusingStrategy } from './shared/routes/routes-strategy';
import { QatchAppComponent } from './qatch-app.component';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { QGuidedTourComponent } from './components/q-guided-tour/q-guided-tour.component';
import { LOCAL_STORAGE_CONFIG, LocalStorageConfig } from '@hss-m/uikit-common';
import { DashboardLayoutComponent } from './pages/private/common/components/dashboard-layout/dashboard-layout.component';

export const LOCAL_STORAGE: LocalStorageConfig = {
  APP_BASE_URL: 'joinqatch-base-url',
  AUTH_TOKEN: 'joinqatch-secure-token',
  TABLE_COLUMNS_CONFIG_LSC_KEY: 'joinqatch-tables-config'
}

@NgModule({
  declarations: [
    AppComponent, QatchAppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgbModule,
    RouterModule,
    AppRoutingModule,
    AuthModule,
    PublicModule,
    ComponentsModule,
    BrowserAnimationsModule,
    QGuidedTourComponent,
    DashboardLayoutComponent,
    NgxPageScrollCoreModule.forRoot({duration: 300}),
    // Initialize Firebase
    provideFirebaseApp(() => {
      return initializeApp(environment.firebaseConfig)
    }),
    provideAuth(() => getAuth())
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomReusingStrategy },
    { provide: LOCAL_STORAGE_CONFIG, useValue: LOCAL_STORAGE },
  ],
  bootstrap: [QatchAppComponent]
})
export class AppModule { }
