import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormControlService {

    NUMBER_REGEX = /^\d*$/;
    URL_REGEX = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
        '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator

    getTermsControl(defValue: boolean = false): FormControl {
        return new FormControl(defValue, [
            Validators.required,
            Validators.requiredTrue
        ]);
    }

    getPhoneControl(defValue: string): FormControl {
        return new FormControl(defValue, [
            Validators.required, 
            Validators.minLength(10), 
            Validators.maxLength(10)
        ]);
    }

    getNameControl(defValue?: string): FormControl {
        return this.getStringTypeFormControl({defValue});
    }

    getDescriptionControl(defValue?: string, config = {}): FormControl {
        return this.getStringTypeFormControl({defValue, ...config});
    }

    getEmailControl(defValue?: string): FormControl {
        return new FormControl(defValue, [
            Validators.required,
            Validators.email
        ]);
    }

    getFullYearControl(defValue?: string): FormControl {
        const currentYear = new Date().getFullYear();
        return this.getNumberTypeFormControl({
            defValue,
            maxValue: currentYear-18,
            minValue: currentYear-100
        });
    }

    getOTPControl(defValue?: string): FormControl {
        return new FormControl(defValue, [
            Validators.required,
            Validators.pattern(this.NUMBER_REGEX),
            Validators.minLength(6),
            Validators.maxLength(6)
        ]);
    }

    getURLControl(defValue?: string): FormControl {
        return new FormControl(defValue, [
            Validators.required,
            Validators.pattern(this.URL_REGEX)
        ]);
    }

    getMultiInputControl(defValue?: string): FormControl {
        return this.getFormControl(defValue);
    }

    getFormControl(defValue?: string, validators = []): FormControl {
        return new FormControl(defValue, validators);
    }

    getDateControl(defValue?: Date | null, validators = []): FormControl {
        return new FormControl(defValue, validators);
    }

    getStringTypeFormControl(config: StringFormControlConfig): FormControl {
        return new FormControl(config.defValue, [
            Validators.required,
            Validators.minLength(config.minLength || 3),
            Validators.maxLength(config.maxLength || 50)
        ]);
    }

    getNumberTypeFormControl(config: NumberFormControlConfig): FormControl {
        return new FormControl(config.defValue, [
            Validators.required,
            Validators.pattern(this.NUMBER_REGEX),
            Validators.min(config.minValue|| 0),
            Validators.max(config.maxValue || 999999999)
        ]);
    }
}

export interface FormControlConfig {
    defValue?: string
}

export interface StringFormControlConfig extends FormControlConfig {
    minLength?: number,
    maxLength?: number
}

export interface NumberFormControlConfig extends FormControlConfig {
    minValue?: number,
    maxValue?: number
}