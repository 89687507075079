<p-carousel #pCarouselRef [value]="sItems" [numVisible]="_config.numVisible" [numScroll]="_config.numScroll" [circular]="_config.circular" 
[showIndicators]="_config.showIndicators"
[autoplayInterval]="_config.autoplayInterval"
[showNavigators]="_config.showNavigators"
[page]="page"
(onPage)="onPageChange($event)">
    <ng-template let-subItem let-index pTemplate="item">
        <div class="q-slider-item position-relative d-flex justify-content-evenly">
            <div class="q-slider-item-content position-relative {{subItem.styleClassss}}">
                <div class="text-center" data-aos="fade-left">
                    <ng-container *ngTemplateOutlet="itemTemplate ? itemTemplate : defaultItemTemplate;context: {subItem:subItem}"></ng-container>
                </div>
            </div>
        </div>
    </ng-template>
</p-carousel>

<ng-template #defaultItemTemplate let-subItem="subItem">
    <img *ngIf class="q-slider-item-img" src="{{_config.basePath}}{{subItem.image}}?alt=media"/>
    <div class="q-slider-item-description" [innerHTML]="subItem.description" [class]="!isMobile"></div>
</ng-template>