<div class="page-header">
    <div class="container">
        <div class="row">
            <div class="mx-auto">
                <div class="author text-center w-100 q-page-header">
                    <ng-container *ngIf="isDesktop">
                        <h1 class="title mt-0 mb-3 q-page-header-title">Reset Password</h1>
                        <span class="underline-text-dark w-200px"></span>
                    </ng-container>
                </div>
                <div class="my-4 mt-0 q-page-container">
                    <form class="register-form mx-auto" [formGroup]="resetPasswordForm" (ngSubmit)="submit()">
                        <div id="captcha-container"></div>

                        <label class="label mt-5">Choose Password</label>
                        <div class="input-group form-group-no-border bg-default shadow">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="nc-icon nc-key-25"></i>
                                </span>
                            </div>
                            <input type="password" class="form-control box-shadow-none" placeholder="Must be atleast 8 characters"
                                formControlName="password" appPassword>
                        </div>
                        <div *ngIf="isSubmittedOnce && password && !password.valid && (password.dirty || password.touched)"
                            class="alert alert-danger">
                            <div *ngIf="password.errors['required']">
                                Password is required.
                            </div>
                            <div *ngIf="password.errors['pattern']">
                                * Password must be 8 characters long
                                <br />
                                * Must include an Upper and lower case letters and a number
                            </div>
                        </div>

                        <label class="label mt-4">Confirm Password</label>
                        <div class="input-group form-group-no-border bg-default shadow">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="nc-icon nc-key-25"></i>
                                </span>
                            </div>
                            <input type="password" class="form-control box-shadow-none" placeholder="" formControlName="c_password"
                            placeholder="" appPassword>
                        </div>
                        <div *ngIf="isSubmittedOnce && password.valid && (!c_password.valid || c_password.value !== password.value) && (c_password.dirty || c_password.touched)"
                            class="alert alert-danger">
                            <div>
                                Passwords do not match.
                            </div>
                        </div>

                        <div class="form-row justify-content-center mt-5">
                            <button [disabled]="loading" type="submit"
                                class="btn btn-danger ms-2 mt-0 reset_password_change_password_click">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="loading"></span>
                                    Change Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="top-right-img">
        <img src="./assets/img/organic/Shape-6.png" />
    </div>
    <div class="bottom-left-img">
        <img src="./assets/img/organic/Shape-5.png" />
    </div>
</div>