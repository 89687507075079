import { OnChanges, ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

export enum FilterType {
  dropdown = 'dropdown',
  multi_dropdown = 'multi_dropdown',
  remote_dropdown = 'remote_dropdown',
  price =  'price',
  reaction = 'reaction',
  checkbox = 'checkbox',
  datepicker = 'datepicker',
  textinput = 'textinput',
  dropdown_switch = 'dropdown_switch'
}

export enum HostFunction{
  SUBSCRIBER = 'SUBSCRIBER',
  DASHBOARD = 'DASHBOARD',
  CHAT = 'CHAT',
}

export interface DropdownData{
  id: number | string,
  itemName: any;
}

export interface FilterItem {
   label: string,
   filterType: FilterType,
   link?: string,
   data?: any[],
   displayKey?: string,
   filterKey?: string,
   filterId: string,
   host?: HostFunction,
   url?: string,
   dataKey?: string,
   displayFunction?: Function
   noDataLabel?: string,
   hidden?: Function|boolean
   initData?: DropdownData[]|any[],
   config?: any
   isIndividual?: boolean,
   isNullable?: boolean,
   placeholder?: string,
   required?: boolean,
   disabled?: boolean,
   singleSelection?: boolean
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnChanges {
  filterTypes = FilterType;
  loading: boolean = true;
  isDesktop = false;
  isMobile = false;
  isCollapsed = false;
  _cachedFilter: any = {};

  @Input() filters: FilterItem[];
  @Input() isAlwaysCollapsible: boolean;
  @Input() retainValues: boolean;
  @Input() fullSizeForm: boolean = false; //Filter Components will be shown in individual rows
  @Input() filterId: string;
  @Input() nonDashboardPage: boolean = false;
  @Input() usedAsForm: boolean = false;
  @Input() formData: any = {};
  @Input() applyFilterButton = true;
  _filtered: any = {};
  get filtered () {
    return this._filtered;
  }
  @Input() set filtered(val) {
    this._filtered = val ? val : {};
  };

  @Output() valueChange = new EventEmitter();

  constructor(
    protected deviceService: DeviceDetectorService,
    private readonly ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.filterId || this.usedAsForm) {
      if(this.usedAsForm) {
        this._cachedFilter = this.formData || {};
      } else {
        const data = sessionStorage.getItem(this.filterId);
        this._cachedFilter = data ? JSON.parse(data) : {};
      }
      this.filters.map( filter => {
        const value = this.getCachedValue(filter);
        if (value) {
          this.onFiltersValueChange({...filter, value }, false);
        }
      })
      this.valueChange.next(null);
    }
    this.onResize();
    if (!this.isDesktop || this.isAlwaysCollapsible) {
      this.isCollapsed = true;
    }
    this.loading = false;
  }

  getCachedValue(filter: FilterItem){
    let value = this._cachedFilter[filter.filterId];
    if(!value && filter.initData){
      value = filter.initData;
    }
    return value;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
      this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnChanges() {
    this.ref.detectChanges();
  }

  clearAll(event: any) {
    for (let key in this.filtered) {
      delete this.filtered[key];
      delete this._cachedFilter[key]; 
    }
    sessionStorage.removeItem(this.filterId);
    this.valueChange.next({});
    event.stopPropagation();
  }

  onFiltersValueChange(event: any, emitChanges = true) {
    let value = null;
    switch (event.filterType) {
      case FilterType.dropdown:
        value = (event.value?.length) ? this.transformDropdownData(event.value, event)[0].id : null;
        break;
      case FilterType.dropdown_switch:
      case FilterType.multi_dropdown:
      case FilterType.remote_dropdown:
        if(event.value && event.value?.length) {
          value = typeof event.value === 'object' ?  event.value.map((it: { id: any; })=>it.id).join(',') : event.value;
        } else {
          value = '';
        };
        break;
      case FilterType.price:
        value = (event.value?.length) ? event.value.map((pr: any[])=>pr.join('-')) : null;
        break;
      default:
        value = event.value;
        break;
    };
    if (value || this.usedAsForm) {
      this.filtered[event.filterId] = value;
      if (this.filterId)
        this._cachedFilter[event.filterId] = event.value;
    } else {
      delete this.filtered[event.filterId];
      if (this.filterId)
        delete this._cachedFilter[event.filterId];
    }
    if (emitChanges && !this.applyFilterButton) {
      if (this.filterId) {
        sessionStorage.setItem(this.filterId, JSON.stringify(this._cachedFilter));
      }
      this.valueChange.next({
        [event.filterId]: value
      });
    }
  }

  transformDropdownData(data: any[], filter) {
    return data.map( i => {
      if(!i) return {};
      return {
        id: i.id ? i.id : (filter.filterKey ? i[filter.filterKey] : i),
        itemName: i.itemName ? i.itemName : (filter.displayKey ? i[filter.displayKey] : i)
      }
    });
  }

  isDisabledClearAll() {
    return Object.keys(this.filtered).length < 1;
  }

  isHidden(filter: any){
    if(filter.hidden && filter.hidden instanceof Function) {
      return filter.hidden();
    } else {
      return filter.hidden;
    }
  }
  
  filterClass(filter: any) {
    if(filter.class) return filter.class;

    if(filter.isIndividual || this.fullSizeForm) return 'w-100';

    return '';
  }

  onApplyFilter(event: any) {
    if (this.filterId) {
      sessionStorage.setItem(this.filterId, JSON.stringify(this._cachedFilter));
    }
    this.valueChange.next({});
    event.stopPropagation();
  }

}
