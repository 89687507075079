import { Component } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-signout',
  template: ''
})
export class SignoutComponent {

  constructor(
    private authService: AuthService,
    private appService: AppService
  ) {
    this.authService.logOut();
    this.appService.navigateWithPreserve('/signin');
  }
}
