import { Component, OnInit } from '@angular/core';
import { Auth, getAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RecaptchaVerifier } from 'firebase/auth';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { environment } from 'src/environments/environment';


// Mask the global 'window' for this snippet file
const window = {
  recaptchaVerifier: undefined
};

@Component({
  selector: 'app-verify-email-mobile',
  templateUrl: './verify-email-mobile.component.html',
  styleUrls: ['./verify-email-mobile.component.scss']
})
export class VerifyEmailAndMobileComponent implements OnInit {

  verifyForm: FormGroup;
  loading = false;
  mode = 'Phone';
  oobCode: string;
  oobCodeConfirmation?: any;
  windowRef: any;
  errorMessages = {
    400: 'Invalid phone number. Please check your area code & mobile number.'
  }
  constructor(
    private firebaseAuth: Auth,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private quizService: QuizService
  ) {

    this.windowRef = window;
    this.route.queryParams.subscribe(params => {
      this.oobCode = params['oobCode'];
      this.mode = params['mode'] === 'verifyEmail' && this.oobCode ? 'Email' : 'Phone';

      if (this.mode === 'Phone') {
        // Add listener for listening to Firebase auth state events
        this.authService.authState().subscribe(result => {
          this.initInvisibleCaptcha();
        });
      }
    });
    this.firebaseAuth = getAuth();
  }

  initInvisibleCaptcha() {
    // [auth_phone_recaptcha_verifier_invisible]
    this.windowRef.recaptchaVerifier = new RecaptchaVerifier('captcha-container', {
      'size': 'invisible',
      'callback': function () {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      }
    }, this.firebaseAuth);

    this.windowRef.recaptchaVerifier.render().then((widgetId) => {
      this.windowRef.recaptchaWidgetId = widgetId;
      // updateSignInButtonUI();
      console.log('Widget Id', widgetId);
    });
  }

  ngOnInit(): void {
    let { phone_number } = this.quizService.readExtraInfo() || {};
    phone_number = this.phoneWithoutCountryCode(phone_number);
    this.verifyForm = this.fb.group({
      email: ['', [Validators.email]],
      phone_number: [ { value: phone_number, disabled: phone_number != null }, [Validators.minLength(10), Validators.maxLength(10)]],
      otp: ['', [Validators.minLength(6)]]
    });
  }

  phoneWithoutCountryCode(phone_number: string) {
    const countrCode = environment.production ? `+1 ` : `+91 `;
    return phone_number.replace(countrCode, '');
}

  async verify() {
    if (this.verifyForm.valid) {
      this.loading = true;
      try {
        if (this.mode === 'Email') {
          await this.verifyEmail();
        } else if (this.mode === 'Phone') {
          if (!this.oobCodeConfirmation) {
            await this.sendVerificationCodeOnMobile();
          } else {
            await this.verifyPhone();
          }
        }
      } catch (error) {
        this.toastService.error(`This action couln't be completed. Please try again.`);
      }
      this.loading = false;
    }
  }

  async verifyEmail() {
    await this.authService.verifyEmail(this.oobCode);
    this.toastService.success('Your email has been verified succesfully. You may login to see latest Qatches & Trends.');
    await this.authService.logOut();
    this.router.navigate(['/signin']);
  }

  // Refer - https://github.com/firebase/snippets-web/blob/a6ecc6cc9b1beb73583a5180292e9680630005d9/auth/phone_number-auth.js
  async verifyPhone() {
    try {
      await this.oobCodeConfirmation.confirm(this.verifyForm.value.otp);
      // User phone_number verified successfully.
      // const user = result.user;
      this.toastService.success(`Your phone ${this.extractPhone()} has been verified succesfully.`);
      await this.authService.logOut();
      this.router.navigate(['/signup']);
    } catch (error) {
      this.toastService.error(`Your phone number couldn't be verified. Please try again.`);
    }
  }

  async sendVerificationCodeOnMobile() {
    try {
      this.oobCodeConfirmation = await this.authService.sendVerificationCodeOnMobile(this.extractPhone(), this.windowRef.recaptchaVerifier);
      this.toastService.success(`A verification code was sent to your device. Please enter the secret code in order to finalize your sign-up! Please don't share this code with anyone.`);
    } catch (error) {
      this.toastService.error(error);
    }
  }

  extractPhone() {
    let phone_number = this.verifyForm.get('phone_number').value;
    phone_number = phone_number.replace(/\D/g, '');
    return environment.production ? `+1${phone_number}` : `+91${phone_number}`;
  }

  get email() { return this.verifyForm.get('email'); }
  get phone_number() { return this.verifyForm.get('phone_number'); }
  get otp() { return this.verifyForm.get('otp'); }
}
