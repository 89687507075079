import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterItem } from '../../filters.component';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
  _filter: FilterItem;
  get filter(): FilterItem {
      return this._filter;
  }
  @Input()
  set filter(value: FilterItem) {
    this._filter = value;
  }
  @Input() filtered: boolean;
  @Input() isIndividual: boolean;
  @Output() onChange = new EventEmitter();
  @Input() value = '';
  @Input() disabled = false;
  onFocusValue = '';
  constructor() { 
  }

  ngOnInit(): void {
    // this.value = this._filter.data ? this._filter.data.join(',') : '';
  }

  onFocus () {
     this.onFocusValue = this.trim();
  }

  valueChange() {
    this.onChange.next({ ...this._filter , value: this.trim()});
  }

  trim() {
    return typeof this.value === 'string' ? this.value?.trim() : this.value
  }

}
