import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/shared/services/app.service';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header text-end px-0 pt-0 pb-2">
      <h4 class="modal-title"></h4>
      <button type="button" class="close btn btn-just-icon btn-link trending_image_popup_x" aria-label="Close" (click)="activeModal.dismiss('cancel')">
        <i class="bi bi-x trending_image_popup_x"></i>
      </button>
    </div>
    <div class="modal-body q-page-header text-center">
      <h3 class="q-page-header-sub-title">Sign up for personal recommendations & exclusive codes right to your phone</h3>
      <button class="pl-0 btn text-danger btn-link trending_image_popup_signup" (click)="signUp()">Sign up here!</button>
    </div>
    <div class="modal-footer d-flex justify-content-center">
      <span>Already have an account?</span>
      <button type="button" class="btn text-danger btn-link w-auto px-0 trending_image_popup_signin" (click)="signIn()">Sign In</button>
    </div>
  `
})
export class AskForSignupModalContent {

  constructor(public activeModal: NgbActiveModal, private appService: AppService) { }

  signIn() {
    this.activeModal.close(true);
    this.appService.navigateWithMerge('/signin');
  }

  signUp() {
    this.activeModal.close(true);
    this.appService.navigateWithMerge('/signup');
  }
}