import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/shared/services/app.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { LocalStorageService, STORAGE_CONSTANTS } from 'src/app/shared/services/storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PhoneUtil } from 'src/app/shared/utils/phone-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BaseVerifyPhone } from '../verify-phone/base-verify-phone';
import { Auth } from '@angular/fire/auth';
import { Logger } from 'src/app/shared/utils/log-util';

// Mask the global 'window' for this snippet file
const window = {
  recaptchaVerifier: undefined
};

@Component({
  selector: 'app-authenticate-using-phone',
  templateUrl: './authenticate-using-phone.component.html',
  styleUrls: ['./authenticate-using-phone.component.scss']
})
export class AuthenticateViaPhoneComponent extends BaseVerifyPhone implements OnInit {

  phoneNumberAccepted = false;
  phoneNumber: string;
  previous_page: string;
  next_page: string;
  maskedPhone: string;

  constructor(
    override firebaseAuth: Auth,
    protected fb: FormBuilder,
    protected userService: UserService,
    authService: AuthService,
    toastService: ToastService,
    route: ActivatedRoute,
    quizService: QuizService,
    protected override storageService: LocalStorageService,
    private appService: AppService,
    protected override deviceService: DeviceDetectorService,
  ) {
    super(firebaseAuth, authService, toastService, route, quizService, storageService, deviceService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.route.queryParams.subscribe(params => {
      this.phoneNumber = params['phone_number'] ? decodeURIComponent(params['phone_number'].replace(/\+/g, ' ')) : '';
      this.previous_page = params['previous_page'];
      this.next_page = params['next_page'];
      this.storageService.set(STORAGE_CONSTANTS.NEXT_ROUTE, `/${this.next_page}`);

      if (this.phoneNumber) {
        this.maskedPhone = `${PhoneUtil.getCountryCode()} ${PhoneUtil.maskPhone(this.phoneNumber)}`;
        this.phoneNumberAccepted = true;
      }

      this.verifyForm = this.fb.group({
        phone_number: [this.phoneNumber || '', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
      });
    });
  }

  onOtpChange(value: any) {
    this.verifyForm.patchValue({ otp: value })
  }

  async submit() {
    if (this.verifyForm.valid) {
      this.loading = true;
      super.verifyOTP({});
    }
  }

  override postOTPVerifySuccess(data: any){
    this.storageService.storeInAppCache(STORAGE_CONSTANTS.USER_META, data);
    this.nextPage();
  }

  override postOTPVerifyFailed(){
    this.loading = false;
  }

  async sendVerificationCode(): Promise<boolean> {
    try {
      this.loading = true;
      this.storageService.removeFromSession(STORAGE_CONSTANTS.REQUEST_TOKEN_ID);
      this.maskedPhone = `${PhoneUtil.getCountryCode()} ${PhoneUtil.maskPhone(this.withoutCountryCode())}`;
      await this.sendVerificationCodeOnMobile();
      this.phoneNumberAccepted = true;
      return true;
    } catch (error) {
      return false;
    } finally {
      this.loading = false;
    }
  }

  override recaptchaRendered() {
    Logger.debug('Re-Captcha Rendered');
  }

  previousPage() {
    this.appService.navigateWithMerge(`/${this.previous_page}`);
  }

  nextPage() {
    this.appService.navigateWithMerge(`/${this.next_page}`);
  }

  get phone_number() { return this.verifyForm?.get('phone_number'); }
}
