import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'uikit-error',
  templateUrl: './uikit-error.component.html',
  styleUrls: ['./uikit-error.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class UikitErrorComponent implements OnInit {
  @Input() controls;
  @Input() name: string
  @Input() submitted = false;
  @Input() placeholder: string;
  constructor() { }

  ngOnInit(): void {

  }

  validationCaseAdded(errors) {
    return !errors.minlength && !errors.maxlength && !errors.invalid_date_time; 
  }

}
