import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FilterItem } from '../../filters.component';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnChanges {
  @Input() isIndividual: boolean;
  _filter: FilterItem;
  get filter(): FilterItem {
      return this._filter;
  }
  @Input() 
  set filter(value: FilterItem) {
    this._filter = value;
    this.setfilteredata(value);
  }
  @Output() onChange = new EventEmitter();
  @Input() singleSelection: boolean = true;
  data: any[] = [];
  @Input() config = {};
  @Input() value: any;

  dropdownSettings = {
    text: 'Please Select',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'ms-bg',
    singleSelection: true,
    limitSelection: 5,
    badgeShowLimit: 1,
    tagToBody: false
  };
  
  constructor(private readonly ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.config) {
      this.dropdownSettings = {
        ...this.dropdownSettings,
        ...this.config
      }
    }
    if (this._filter.config) {
      this.dropdownSettings = {
        ...this.dropdownSettings,
        ...this._filter.config
      }
    }
    this.dropdownSettings.singleSelection = this.singleSelection;
  }

  ngOnChanges(changes) {
    this.ref.detectChanges();
    if(changes.value?.currentValue) {
      this.value = this.setDropdownData(changes.value.currentValue || []);
      if(this.value && changes.value.currentValue && (JSON.stringify(this.value) !== JSON.stringify(changes.value.currentValue))) {
        this.valueChange();
      }
    }
  }

  setfilteredata(filter: FilterItem) {
    if (filter.data && filter.data.length) {
      this.data = this.setDropdownData(filter.data);
    } else {
      //  TO DO Get Data Direct from api
    }
  }

  setDropdownData(data: any[] = []) {
    if(!Array.isArray(data)) {
      return [];
    }
    return data.map( i => {
      if(!i) return {};
      return {
        id: i.id ? i.id : (this.filter.filterKey ? i[this.filter.filterKey] : i),
        itemName: i.itemName ? i.itemName : (this.filter.displayKey ? i[this.filter.displayKey] : i)
      }
    });
  }

  valueChange() {
    this.onChange.next({ ...this._filter , value: this.value});
  }

}
