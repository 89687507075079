<div class="input-group form-group-no-border filter-item d-flex justify-content-between">
    <label class="label text-end" [title]="filter?.label" [innerText]="filter?.label" 
    [class.required-field]="filter?.required"></label>
    <input type="text" class="bg-default shadow {{ isIndividual ? '' : 'w-200px' }} mr-n2 pl-2 border-0"
    (focus)="onFocus()" 
    (blur)="valueChange()"
    (keyup.enter)="valueChange()"
    [(ngModel)]="value"
    [disabled]="disabled"
    [placeholder]="filter?.placeholder || ''">
</div>