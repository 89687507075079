import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reaction',
  templateUrl: './reaction.component.html',
  styleUrls: ['./reaction.component.scss']
})
export class ReactionComponent implements OnInit {
  @Input() filter = {};
  @Input() isIndividual: boolean;
  @Output() onChange = new EventEmitter();
  reactionTypes = [
    { reaction: 'Loved', icon: 'bi bi-heart-fill', checked: false },
    { reaction: 'Liked', icon: 'bi bi-hand-thumbs-up-fill', checked: false },
    { reaction: 'Disliked', icon: 'bi bi-hand-thumbs-down-fill', checked: false },
    { reaction: 'New', icon: 'fa fa-bullhorn', checked: false }
  ];
  constructor() { }

  ngOnInit(): void {
  }

  toogleReactions(type: { checked: boolean; }) {
    type.checked = !type.checked;
  }

}
