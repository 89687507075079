import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QueryBuilderConfig } from 'hss-query-builder';
import { QUERY_BUILDER_CLASSES } from 'src/app/app.const';

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss']
})
export class QQueryBuilderComponent implements OnInit {
  dropdownSettings = {
    labelKey: 'name',
    primaryKey: 'value',
    badgeShowLimit: 3,
    tagToBody: false
  };
  queryBuilderClasses = QUERY_BUILDER_CLASSES;
  @Input() query: any;
  @Input() config: QueryBuilderConfig;
  @Input() disabled = false;
  @Output() queryChange = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onQueryChange() {
    this.queryChange.emit(this.query);
  }

  getSettings() {
   return {...this.dropdownSettings, disabled: this.disabled}
  }

}
