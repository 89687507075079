import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbTimeStruct, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/shared/models/user';
import { APP_CONFIG, MESSAGES } from 'src/app/app.const';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'uikit-date-time',
  templateUrl: './scheduled-at.component.html',
  styleUrls: ['./scheduled-at.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => ScheduledAtComponent)
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => ScheduledAtComponent),
    multi: true,
  }]
})
export class ScheduledAtComponent implements OnInit, ControlValueAccessor, Validator {
  time: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  minDate = null;
  @Input() isNew = true;
  @Input() date: NgbDateStruct;
  @Input() control: AbstractControl;
  onChange: (value) => void = () => {};
  onTouched: () => void = () => {
     if(this.control) {
      this.control.markAsTouched();
     }
  };
  onValidatorChanged: any = () => { };
  disabled = false;
  timezone: string;
  constructor(
    private timepickerConfig: NgbTimepickerConfig,
    private datePipe: DatePipe,
    private authService: AuthService,
    private calendar: NgbCalendar
  ) {
    this.timepickerConfig.spinners = false;
    this.minDate = this.calendar.getToday();
  }

  ngOnInit(): void {
    this.authService.me().then((user: User) => {
      this.timezone = user.timezone;
    })
  }

  validate(control: AbstractControl<any, any>): ValidationErrors {
    if(!this.isNew) {
      this.control.markAsTouched();
    }
    const errorObj = { required: false, invalid_date_time: true, message: MESSAGES.SCHEDULE_DATE};
    if(this.isToday(this.date) && this.time) {
      const twoHoursAhead = this.getDateByHoursAhead();
      const isValid = (this.time.hour > twoHoursAhead.getHours()) || 
             (this.time.hour === twoHoursAhead.getHours() && this.time.minute >= twoHoursAhead.getMinutes());
      return isValid ? null : errorObj;
    } else if(this.isPastDate(this.date)) {
      return errorObj;
    } else {
      return this.date ? null : errorObj;
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChanged = fn;
  }

  writeValue(val): void {
    const tdate = val ? 
    new Date(this.datePipe.transform(val, APP_CONFIG.DATES.MONTH_TIMESTAMP_FORMAT, this.timezone)) : this.getDateByHoursAhead();
    this.time = {
      hour: tdate.getHours(),
      minute: tdate.getMinutes(),
      second: 0
    }
    this.date = new NgbDate(tdate.getFullYear(), tdate.getMonth()+1, tdate.getDate());
    if(!val) {
      setTimeout(() => {
        this.control.patchValue(tdate, {emitEvent: false});
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onChangeValue() {
    if(this.disabled) { return; }
    let finalVal;
    if(!this.validate(null)) {
      finalVal = new Date(`${this.date.year}-${this.date.month}-${this.date.day}`);
      finalVal.setHours(this.time.hour, this.time.minute);
    }
    this.onValidatorChanged();
    this.onChange(finalVal);
    this.onTouched();
  }

  getDateByHoursAhead() {
    const now = new Date();
    return new Date(now.getTime() + (environment.schedule_hrs_in_advance * 60 * 60 * 1000));
  }

  isToday(date: NgbDateStruct) {
    return this.calendar.getToday().equals(date);
  }

  isPastDate(date: NgbDateStruct) {
    return this.calendar.getToday().after(date);
  }

}
