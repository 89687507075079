<div class="wrapper non-transparent-header">
  <div class="profile m-auto">
    <div class="fireworks">
    </div>
    <div class="container position-absolute">
      <div class="mx-auto">
        <div class="author text-center w-100 q-page-header">
          <div class="vmt-6 sub-heading">thanks, babe</div>
          <h1 class="heading" [class.vmt-3]="!isDesktop">YOU'RE IN!</h1>
        </div>
        <div class="q-page-container vpx-3 text-center vmt-6">
          <div class="sub-heading">
            Now<mark> add us to your phone contacts </mark>so you don’t miss a thing 📲
          </div>
          <div class="vmt-6 m-auto" [class.w-80]="isDesktop">
            <div *ngIf="isVIPUser" class="bg-highlight sub-heading fw-bold m-auto">Here's What's Coming</div>
            <div *ngIf="!isVIPUser" class="bg-highlight description default-font-family m-auto">Have an upcoming vacation or event?</div>
            <div *ngIf="isVIPUser" class="bg-highlight description m-auto">to make the most of your VIP membership</div>
          </div>
          <div class="vmt-3 m-auto" [class.w-50]="isDesktop">
            <div class="description">
              <div *ngIf="!isVIPUser" class="text-center">
                Text<mark class="text-dark text-bold"> REQUEST </mark>to your Qatch stylist and we'll help you find what you're looking for within 72 hours!
              </div>
              <ul *ngIf="isVIPUser" class="text-start">
                <li>Each week you'll receive <mark>15-20 curated pieces.</mark> 
                  We'll send you a link via text to browse and rank them on your VIP 
                  <a routerLink="../internal/my-qatch">faves page</a>.
                </li>
                <li>All items you heart will be <mark>saved for 90 days</mark> so you can return and shop anytime.</li>
                <li>Get <mark>2 on-demand</mark> outfit requests! Text us a description or a photo of an outfit you want and we'll recreate it for you.</li>
              </ul>
            </div>
          </div>
          <div class="vmt-6 d-flex justify-content-center">
            <q-button [title]="isVIPUser? 'Start Rating Products' : 'Explore'" type="arrowButton" buttonStyleClass="btn-purple"
             iconStyleClass="btn-dark-purple" (click)="onExplore()"></q-button>
          </div>
          <div class="vmt-3 d-flex justify-content-center">
             <p-button label="Skip" styleClass="p-button-secondary p-button-text" (click)="onSkipTour()"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>