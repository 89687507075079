import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { QatchComponent } from 'src/app/components/qatch.component';
import { QuestionControlService } from 'src/app/components/questions/service/question-control.service';
import { QuestionBase } from 'src/app/components/questions/types/question-base';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { lastValueFrom } from 'rxjs';

export abstract class BaseQuizOnboardingComponent extends QatchComponent {

    onboardingQuizForm: FormGroup;
    loading: boolean; // For submit buttons
    questions: QuestionBase<string>[] = [];
    ploading: boolean; // For Page
    rawQuestions = [];
    rawAnswers = [];

    constructor (
        protected override authService: AuthService,
        protected router: Router,
        protected toastService: ToastService,
        protected quizService: QuizService,
        protected userService: UserService,
        protected questionControlService: QuestionControlService,
        protected override deviceService: DeviceDetectorService
    ) {
        super(deviceService, authService);
    }

    onInit(): void {
        this.getOnboardingQuestions();
    }

    abstract getType(): string;
    abstract previousPage(): void;
    abstract extraFormElements(): any;
    abstract getAnsweredQuestions(): any;

    getOnboardingQuestions() {
        this.ploading = true;
        this.quizService.getOnboardingQuestions(this.getType()).subscribe({
            next: (res) => {
                this.rawQuestions = res.questions;
                this.rawAnswers = res.user_answers;
                const quesToFilter = this.questionsToBeFilteredById();
                let questions: any[] = res.questions;
                if (quesToFilter.length > 0) {
                    questions = questions.filter(({question_id}) => {
                        return this.questionsToBeFilteredById().includes(question_id);
                    });
                }
                this.questions = this.questionControlService.toFormQuestions(questions, [...this.getAnsweredQuestions(), ...res.user_answers]);
                this.onboardingQuizForm = this.questionControlService.toFormGroup(this.questions, this.extraFormElements());
                this.postFormGroupSetup();
                this.ploading = false
            },
            error: (err) => {
                this.ploading = false
                this.toastService.error('Unable to load onboarding questions. Please try again.');
            }
        });
    }

    postFormGroupSetup() {

    }

    questionsToBeFilteredById(): any[] {
        return [];
    };

    answers(form) {
        const answers = [];
        for (const key in form) {
            let answer = form[key];
            if (key.startsWith('question-id-')) {
                const question_id = key.replace('question-id-', '');
              if(answer){
                if (answer instanceof Array) {
                    // If dropdown, find answers from selected values
                    if (answer[0] instanceof Object) {
                        answer = answer.filter(a => a.itemName).map(a => a.itemName);
                        if(!answer.length){
                            answer = null;
                        }
                    }
                  } else if(typeof answer === "string"){
                      // convert answers to array
                      answer = answer;
                  } else {
                    // convert answers to array
                    answer = answer ? [answer] : null;
                }
              }
              const ansQue = this.findUsersAnswerToQuestion(parseInt(question_id));
              if(answer){
                answers.push({
                    question_id: question_id,
                    answer: typeof answer === 'object' ? JSON.stringify(answer) : answer,
                    system_id: ansQue.system_id
                  });
              }
            }
        }
        return answers;
    }

    findUsersAnswerToQuestion(question_id: number, answers?: any[]) {
        if(!answers) {
            answers = this.rawAnswers;
        }
        return answers.find(ra => ra.question_id === question_id) || {};
    }

    parseUsersAnswerToQuestion(question_id: number, answers?: any[]) {
        const qa = this.findUsersAnswerToQuestion(question_id, answers);
        if (qa && qa.answer && qa.answer.includes('[')) {
            return JSON.parse(qa.answer);
        }
        return qa.answer;
    }

    async updateDetailsForUsersInWaitlist(data: any) {
        try {
            await lastValueFrom(this.userService.updateQatchUser(data));
            this.router.navigate(['/waitlist/you-are-in'], { queryParamsHandling: 'merge' });
        } catch (errResp) {
            this.toastService.error(`Something went wrong. Please try again.`);
        }
    }
}
