import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface IConvoCard {
  title: string,
  image: string,
  is_waitlist: boolean,
  is_premium: boolean,
  join_convo_label: string,
  join_convo_fees_label: string,
  sub_title?: string,
  image_label?: string,
  description?: string,
  delivery_label?: string,
  bg_color?: string,
  selected?: boolean,
  style?: any,
  status?: string,
  default_selected?: boolean;
}

@Component({
  selector: 'q-convo-card',
  templateUrl: './q-convo-card.component.html',
  styleUrls: ['./q-convo-card.component.scss']
})
export class QConvoCardComponent {
  @Input() loading: boolean = false;
  @Input() card: IConvoCard;
  @Input() style: any;
  @Input() styleClass: string;
  @Output() onClick = new EventEmitter();
  @Output() onImageLabelClick = new EventEmitter();
  @Input() selection = true;
  @Input() isMobile = false;
  constructor() {

  }

  onCardClick() {
    this.card.selected = !this.card.selected;
    this.onClick.emit();
  }

  onCardImageLabelClick(event) {
    event.stopPropagation();
    this.onImageLabelClick.emit(event);
  }

}
