import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
export interface IChip{
  id: string
  checked: boolean
  itemName: string
}
@Component({
  selector: 'q-chips',
  templateUrl: './q-chips.component.html',
  styleUrls: ['./q-chips.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => QChipComponent)
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => QChipComponent),
    multi: true,
  }]
})
export class QChipComponent implements OnInit, ControlValueAccessor, Validator{

  onChange: (value: IChip[]) => void = () => {};
  onTouched: () => void = () => {};
  onValidatorChanged: any = () => { };
  disabled = false;
  value: IChip[] = [];
  @Input() question: any = {};
  @Input() data: IChip[];
  @Input() singleSelection: boolean = false;
  
  @Input() readonly: boolean = false;
  @Input() itemName: string;

  constructor() { }

  validate(control: AbstractControl<any, any>): ValidationErrors {
    const valid = Array.isArray(this.value) && this.value.length >= 1;
    if (valid || !this.question.required) {
      return null;
    } else {
      return {
        minimumSize: 1
      }
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChanged = fn;
  }

  writeValue(value: IChip[]): void {
    this.value = value || [];
    this.data.map( chip => {
      chip.checked = this.value.find(i => i.itemName === (chip.itemName)) ? true : false;
      return chip;
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngOnInit(): void {
  }

  toggle(chip: IChip) {
    if(this.disabled) { return; }
    if(!this.singleSelection) {
      if(chip.checked) {
        this.value = this.value.filter(ch => ch.id !== chip.id);
      } else {
        this.value.push(chip);
      }
      chip.checked = !chip.checked;
    } else {
      if(chip.checked) return;
      this.data.forEach(ch => {
        if(ch.id === chip.id){
          ch.checked = true;
          this.value[0] = ch;
        } else {
          ch.checked = false;
        }
      });
    }
    this.onValidatorChanged();
    this.onChange(this.value);
  }
}
