import { QuestionBase } from '../question-base';
import { QuestionBaseConfig } from '../question-base-config';

export class SwitchQuestion extends QuestionBase<string> {
  override controlType = 'switch';

  constructor(config: QuestionBaseConfig<string> = {}) {
    super(config);
    this.answer = config.answer || false;
  }

}
