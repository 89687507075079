import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QuizService } from 'src/app/shared/services/quiz.service';
import { UserService } from 'src/app/shared/services/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseVerifyPhone } from 'src/app/pages/auth/verify-phone/base-verify-phone';
import { PhoneUtil } from 'src/app/shared/utils/phone-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageService, STORAGE_CONSTANTS } from 'src/app/shared/services/storage.service';
import { Auth } from '@angular/fire/auth';

@Component({
    selector: 'app-phone-number',
    templateUrl: './phone-number.component.html',
    styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent extends BaseVerifyPhone implements OnInit {

    devices = PhoneUtil.getDevices();

    constructor (
        firebaseAuth: Auth,
        protected fb: FormBuilder,
        protected userService: UserService,
        authService: AuthService,
        private router: Router,
        toastService: ToastService,
        quizService: QuizService,
        protected override deviceService: DeviceDetectorService,
        private activatedRoute: ActivatedRoute,
        protected override storageService: LocalStorageService
    ) {
        super(firebaseAuth, authService, toastService, activatedRoute, quizService, storageService, deviceService);
    }

    override ngOnInit(): void {
        // super.ngOnInit();
        const bffPageInfo = this.quizService.readTextLikeBffPageInfo();
        // Redirect to previous page if LikeBFF Page info havent answered
        if (!bffPageInfo) {
            this.previousPage();
        } else {
            const formData = this.quizService.readExtraInfo();
            this.verifyForm = this.fb.group({
                phone_number: [ formData?.phone_number || '', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                c_phone: [ formData?.c_phone || '', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                agree_to_terms: [formData?.agree_to_terms || false, [Validators.required]]
            });
        }
        
    }

    async submit() {
        if (this.verifyForm.valid) {
            this.loading = true;
            const value = this.formData();
            try {
                await this.userService.checkIfQatchUserExists(value.phone_number).toPromise();
                this.toastService.error(`User already exists. Please try again with another Phone Number.`);
            } catch (errResp: any) {
                if (errResp.status == 404) {
                    try {
                        const otpSent = await this.sendVerificationCode();
                        if (otpSent) {
                            const userDetails = {
                                ...value,
                                styles: this.quizService.readStyles(),
                                ...this.quizService.readStyleChoice(),
                                ...this.quizService.readTextLikeBffPageInfo(),
                                answers: [
                                    ...this.quizService.readStyleChoice().answers,
                                    ...this.quizService.readTextLikeBffPageInfo().answers,
                                    { question_id: 18, answer: JSON.stringify([this.devices.find(device => device.checked)?.title]) }
                                ]
                            };
                            await this.userService.createQatchUserWithPhone(userDetails, this.activatedRoute.snapshot.queryParams).toPromise();
                            this.quizService.clearAll();
                            this.storageService.set(STORAGE_CONSTANTS.NEXT_ROUTE, '/create-your-account');
                            this.router.navigate(['/verify-your-phone'], { queryParamsHandling: 'merge' });
                        }
                    } catch (errResp: any) {
                        this.toastService.error(errResp.error.error);
                    }
                }
            } finally {
                this.quizService.writeExtraInfo(this.formData());
                this.loading = false;
            }
        }
    }

    async sendVerificationCode(): Promise<boolean> {
        try {
            await this.sendVerificationCodeOnMobile(false);
            return true;
        } catch (error) {
            return false;
        }
    }

    formData() {
        const value = this.verifyForm.value;
        return {
            ...value,
            phone_number: this.withCountryCode()
        }
    }

    toggle(obj: any) {
        this.devices.forEach(device => device.checked = false);
        obj.checked = !obj.checked;
    }

    previousPage() {
        this.router.navigate(['/almost-done'], { queryParamsHandling: 'merge' });
    }

    get phone_number() { return this.verifyForm.get('phone_number'); }
    get c_phone() { return this.verifyForm.get('c_phone'); }
    get agree_to_terms() { return this.verifyForm.get('agree_to_terms'); }
}
