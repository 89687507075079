import { QuestionBase } from '../question-base';
import { QuestionBaseConfig } from '../question-base-config';

export class TextboxQuestion extends QuestionBase<string> {
  override controlType = 'textbox';

  constructor(config: QuestionBaseConfig<string> = {}) {
    super(config);
    this.answer = config.answer || config.default_value || '';
  }

}
