import {
    RouteReuseStrategy,
    ActivatedRouteSnapshot,
    DetachedRouteHandle
  } from "@angular/router";
  
  export class CustomReusingStrategy implements RouteReuseStrategy {
    private cache: { [key: string]: DetachedRouteHandle } = {};
  
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
      return route && route.routeConfig?.data && route.routeConfig.data["reuse"];
    }

    store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
      if (handler) {
        this.cache[this.getUrl(route)] = handler;
      }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
      return !!this.cache[this.getUrl(route)];
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
      if (!route.routeConfig || route.routeConfig.loadChildren) {
        return {};
      }
      return this.cache[this.getUrl(route)];
    }

    shouldReuseRoute(
      future: ActivatedRouteSnapshot,
      current: ActivatedRouteSnapshot
    ): boolean {
      if (
        future.routeConfig &&
        future.routeConfig.data &&
        future.routeConfig.data["reuse"] !== undefined
      ) {
        return future.routeConfig.data["reuse"];
      }
      return future.routeConfig === current.routeConfig;
    }

    getUrl(route: ActivatedRouteSnapshot): string {
      if (route.routeConfig) {
        return route.routeConfig.path || '';
      } else {
        return '';
      }
    }
    
  }