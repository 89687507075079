import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appPassword]'
})
export class PasswordShowHideDirective {

    isVisible: boolean = false;

    constructor (
        private el: ElementRef,
        private renderer: Renderer2) 
    {
        this.createIcon();
    }

    createIcon() {
        const input = this.el.nativeElement.parentNode;
        const container = document.createElement('span');
        container.innerHTML = `Show`;
        this.renderer.addClass(container, 'show-hide-password')
        container.addEventListener('click', (event) => {
            this.toggle(container);
        });
        input.appendChild(container);
    }

    toggle(container: any) {
        this.isVisible = !this.isVisible;
        if (this.isVisible) {
            this.renderer.setAttribute(this.el.nativeElement, 'type', 'text');
            container.innerHTML = 'Hide';
        } else {
            this.renderer.setAttribute(this.el.nativeElement, 'type', 'password');
            container.innerHTML = 'Show';
        }
    }
}
