import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Style } from '../models/style';
import { Observable } from 'rxjs'
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppService } from './app.service';
import { ViewTablePage, ViewTablePagedData, ViewTableService } from '../modules/data-view-table/view-table.service';
import { ProductService } from './product.service';
import { ToastService } from './toast.service';
import { ClipboardService } from 'ngx-clipboard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReferalProgramModalContent } from '../modal/referral-program.modal';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  collection: any;
  baseUrl: string;

  constructor(
    private http: HttpClient,
    private deviceDetectorService: DeviceDetectorService,
    private viewTableService: ViewTableService,
    private appService: AppService,
    private productService: ProductService,
    private toastService: ToastService,
    private deviceService: DeviceDetectorService,
    private clipboardService: ClipboardService,
    private modalService: NgbModal
  ) { 
    this.baseUrl = `${this.appService.getAppBaseUrl()}/user`;
  }

  markLoggedIn() {
    return this.http.post(`${this.baseUrl}/mark-logged-in`, {
      device_meta: this.deviceDetectorService.getDeviceInfo()
    });
  }

  updateProfile(user: any) {
    return this.http.patch(`${this.baseUrl}/profile`, user)
  }

  updateOnboardingQuizAnswers(quizAnswers: any, brands: any[] = [], deleted_brands: any[] = [], user_meta= {}): Observable<any> {
    return this.http.post(`${this.baseUrl}/onboarding-quiz`, { answers: quizAnswers, brands: brands, deleted_brands: deleted_brands, ...user_meta })
  }
  
  updateStylePreferences(styles: Partial<Style>[], deleted_styles: Partial<Style>[]= []): Observable<any> {
    return this.http.post(`${this.baseUrl}/styles`, { styles: styles, deleted_styles: deleted_styles })
  }

  createQatchUserWithPhone(userDetails: any, queryParams={}): Observable<any> {
    return this.http.post(`${this.baseUrl}`, userDetails,  {params: queryParams})
  }

  // createQatchUser(userDetails: any, queryParams={}): Observable<any> {
  //   return this.http.post(`${this.baseUrl}/create`, userDetails,  {params: queryParams})
  // }

  // createQatchVIPUser(userDetails: any, queryParams={}): Observable<any> {
  //   return this.http.post(`${this.baseUrl}/vip`, userDetails,  {params: queryParams})
  // }

  createUserWithConvo(userDetails: any, queryParams={}): Observable<any> {
    return this.http.post(`${this.baseUrl}/signup`, userDetails,  {params: queryParams})
  }

  updateQatchUser(userDetails: any): Observable<any> {
    return this.http.patch(`${this.baseUrl}`, userDetails)
  }

  checkIfQatchUserExists(userIdentifier: string): Observable<any> {
    return this.http.head(`${this.baseUrl}/${userIdentifier}`)
  }

  createFirebaseUser(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/firebase`, data)
  }

  updatePassword(uri: string, data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/${uri}`, data)
  }

  raiseSupportRequest(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/grievances`, data)
  }

  raiseContactUsRequest(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl.replace('/user','')}/grievances`, data)
  }

  getMyQatches(page: Partial<ViewTablePage>, sort: any, filters: any): Observable<ViewTablePagedData> {
    return this.viewTableService.getData(`${this.baseUrl}/qatches`, page, sort, filters, 'qatches');
  }
  
  getFilters(filters: any, params: any): Observable<any> {
    return this.http.post(`${this.appService.getAppBaseUrl()}/filters`, {filters}, {params});
  }

  generateReferralLink(): Observable<any> {
    return this.http.get(`${this.baseUrl}/referral-link`)
  }

  addToMyQatches(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/qatches`, data)
  }

  addOrUpdateReaction(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/reaction`, data)
  }

  addToMyQatchWithReaction(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/qatch-with-reaction`, data)
  }

  logUserClick(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/log-product-click`, {
      device_meta: this.deviceDetectorService.getDeviceInfo(),
      text_id: data.text_id,
      product_id: data.product_id,
      collection_id: this.collection?.collection_id || null
    })
  }

  lookupUser(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/lookup`, data)
  }

  lookupByUsername(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/search`, data)
  }

  continueToExplore() {
    return this.http.get(`${this.baseUrl}/continue-to-explore`)
  }

  referAProduct(product: any) {
    this.productService.referAProduct(product);
  }

  referAFriend(referral_link: string){
    const title = 'Refer A Friend';
    const text = `Hey, join Qatch like I did to get cute outfits via text! Trust me, you’re gonna love it.`;
    if(this.deviceService.isDesktop()){
      const modalRef = this.modalService.open(ReferalProgramModalContent, { centered: true });
      modalRef.componentInstance.referral_link = referral_link;
      modalRef.componentInstance.text = text;
      modalRef.result.then((result) => {
      }, (reason) => {});
    } else {
      this.shareWithFriends(title, text, referral_link, 'Referral link has been copied.');
    }
  }

  getUserConvos(): Observable<{convos:any}> {
    return this.http.get<{convos:any}>(`${this.baseUrl}/convos`);
  }

  updateUserConvos(convos) {
    return this.http.put(`${this.baseUrl}/convos`, {convos});
  }

  private shareWithFriends(title: string, text: string, branded_link: string, success_message?: string) {
    const isMobile = this.deviceService.isMobile();
    if (isMobile && navigator.share) {
        navigator.share({
          title: title,
          text: text,
          url: branded_link
        }).then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
        this.clipboardService.copy(`${text} ${branded_link}`);
        if(success_message) this.toastService.success(success_message);
    }
  }
}
