import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileComponent } from 'src/app/pages/private/general/profile/profile.component';

@Component({
  standalone: true,
  selector: 'app-quiz',
  templateUrl: './quick-quiz.component.html',
  styleUrls: ['./quick-quiz.component.scss'],
  imports: [CommonModule, ProfileComponent],
})
export class QuickQuizComponent {

  constructor( private activeModal: NgbActiveModal ) { }

  onClose(submitted = false) {
    if (submitted) {
      this.activeModal.close(true);
    } else {
      this.activeModal.dismiss('Cross click');
    }
  }
}
