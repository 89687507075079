import { CommonModule } from '@angular/common';
import { Component, forwardRef, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { InputPanelComponent } from '../input-panel/input-panel.component';

@Component({
  selector: 'q-editor',
  templateUrl: './q-editor.component.html',
  styleUrls: ['./q-editor.component.scss'],
  standalone: true,
  imports:[CommonModule, FormsModule, InputPanelComponent],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => QEditorComponent)
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => QEditorComponent),
    multi: true,
  }]
})
export class QEditorComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() required;
  @Input() value;
  @Input() label;
  cursorLocation = 0;
  @Input() showInputPanel = true;
  @Input() control: AbstractControl;
  @Output() valueChange = new EventEmitter();
  @Output() onBlurEvent = new EventEmitter();
  onChange: (value) => void = () => { };
  onTouched: () => void = () => {
    if (this.control) {
      this.control.markAsTouched();
    }
  };
  onValidatorChanged: any = () => { };
  disabled = false;
  @ViewChild('textArea') textAreaRef: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  validate(control: AbstractControl<any, any>): ValidationErrors {
    const value = this.value.trim();
    if (control.hasValidator(Validators.required)) {
      return value.length >= 1 ? null : { required: true };
    } else {
      return null;
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChanged = fn;
  }

  writeValue(value): void {
    this.value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  valChange() {
    if (this.disabled) { return; }
    this.onValidatorChanged();
    this.onChange(this.value);
    this.onTouched();
    this.valueChange.emit(this.value);
  }

  onFocus() {
    this.showCaretPos();
    this.onTouched();
  }

  onBlur() {
    this.onBlurEvent.emit();
    this.showCaretPos();
    this.onTouched();
  }

  showCaretPos() {
    const el = this.textAreaRef?.nativeElement;
    this.cursorLocation = el?.selectionStart;
  }

}
