<div class="wrapper non-transparent-header">
    <div class="container">
            <div class="mx-auto profile-container w-fit-content">
                <div *ngIf="ploading" class="text-center vmt-5">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
                <div *ngIf="!quickQuiz" class="author text-center w-100 q-page-header">
                    <ng-container *ngIf="isDesktop && !ploading">
                        <page-header title="{{ profileTitle | titlecase }}"></page-header>
                    </ng-container>
                </div>
                <div class="{{quickQuiz? 'vmt-4 vmb-1': 'vmy-4'}} q-page-container mx-auto">
                    <form *ngIf="!ploading && onboardingQuizForm" class="register-form mx-auto" [formGroup]="onboardingQuizForm"
                        (ngSubmit)="updateProfile()">
                        <ng-container *ngIf="!quickQuiz">
                            <div class="owner d-none">
                                <div class="avatar">
                                    <img src="./assets/img/faces/joe-gardner-2.jpg" alt="Circle Image"
                                        class="img-circle img-responsive">
                                </div>
                                <div class="name">
                                    <h4 class="title btn btn-link btn-dark"><u>Change Photo</u></h4>
                                </div>
                            </div>
                            <!-- <ng-container *ngIf="user.referral_link">
                                <label class="label w-100"><u>Referral Program</u></label>
    
                                <div class="form-row vmb-2 referral-box">
                                    <div class="col-md-10 mx-auto text-center">
                                        <div class="form-group">
                                            <h3 class="vmy-2 referral-top-label vfs-10"><strong class="fw-bold">{{progressBar.total}} Friends = ${{user.referral_info.reward}}</strong> </h3>
                                            <h4 class="vmt-3 vmb-2 referral-top-label vfs-12" *ngIf="user.referral_info.total_referrals">Thanks {{user.first_name}}, you referred <strong class="fw-bold">{{user.referral_info.total_referrals}}</strong> friend(s) to Qatch!</h4>
                                            <div class="progress">
                                                <div [style.width.%]="((progressBar.value/progressBar.total)*100)" class="progress-bar progress-bar-warning"></div>
                                            </div>
                                            <ng-container>
                                                <h3 class="vmy-2 referral-top-label vfs-10"><strong class="fw-bold">You've earned</strong> </h3>
                                                <div class="d-flex mx-auto rewards-display-board">
                                                    <h2 class="m-auto text-white"><strong class="fw-bold vfs-20">{{rewardEarned | currency}}</strong></h2>
                                                </div>
                                            </ng-container>
                                            <p class="vmy-2">Text <strong class="fw-bold">REDEEM</strong> to Qatch to get your Gift Card</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container> -->
    
                            <label class="label w-100 fs-size text-center  fs-family">Personal Information</label>
                            <div class="form-row row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="label label-align vmt-4 Public-Sans-N fs-size">First Name<span class="required">*</span></label>
                                        <div class="input-group form-group-no-border ">
                                            <input type="text" class="form-control bg-color text-dark edit_account_first_name_click" placeholder=""
                                                formControlName="first_name">
                                        </div>
                                    </div>
                                    <uikit-error [submitted]="false" [controls]="controls" name="first_name" placeholder="First Name"></uikit-error>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="label label-align vmt-4 Public-Sans-N fs-size">Last Name</label>
                                        <div class="input-group form-group-no-border">
                                            <input type="text" class="form-control text-dark bg-color edit_account_last_name_click" placeholder=""
                                                formControlName="last_name">
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <!-- <div *ngFor="let question of questions" class="form-row {{ remoteQuestionsMapping[question.question_id] }}">
                                <app-question [spacing]="'vmt-3'" [question]="question" [form]="onboardingQuizForm"
                                    class="vpx-1 w-100">
                                </app-question>
                            </div> -->
    
                            <label class="label vmt-4 label-align Public-Sans-N fs-size">Instagram (Optional)</label>
                            <div class="input-group form-group-no-border rounded insta-gradient">
                                <span class="input-suffix fs-24">&#64;</span>
                                <input type="text" class="form-control bg-color bg-transparent txt-white edit_account_instagram_click"
                                    formControlName="instagram_handle">
                            </div>
    
                            <!-- <label class="label vmt-4 w-100"><u>Communication Methods</u></label> -->
                    

                        <label class="label vmt-4 label-align Public-Sans-N fs-size">Email<span class="required">*</span></label>
                        </ng-container>
                        <div class="input-group form-group-no-border">
                            <input type="email" class="form-control bg-color text-dark edit_account_email_click" placeholder="Email" formControlName="email">
                        </div>
                        <uikit-error [submitted]="false" [controls]="controls" name="email" placeholder="Email"></uikit-error>
                       <ng-container *ngIf="!quickQuiz">
                        <label class="label vmt-4 label-align Public-Sans-N fs-size">Phone Number<span class="required">*</span></label>
                        <div class="input-group form-group-no-border rounded bg-color shadow">
                            <span class="input-suffix fs-14 text-dark pe-0 rounded bg-color" style="width: 40px;">{{countryCode}}</span>
                            <input type="text" class="form-control ps-1 bg-color text-dark rounded box-shadow-none edit_account_phone_number_click" formControlName="phone_number"
                                placeholder="(xxx)xxx-xxxx">
                        </div>

                        <!-- <div class="vmt-3">
                            <a class="btn btn-link text-danger ps-0 edit_account_change_password_click"
                                (click)="redirectToForgotPasswordPage()"><u>Change Password</u></a>
                        </div> -->

                        <label class="label vmt-4 w-100 text-center fs-size fs-family">Style Information</label>
                        </ng-container>
                        <uikit-form *ngIf="controls" formGroupName="quiz" [quizPage]="'sign-up,edit-account'" [uikitForm]="getQuizForm()"
                            (updateFormControl)="updateFormControl($event);" class="d-block" style="min-height: 23em;"></uikit-form>

                        <div class="form-row justify-content-center vmt-4 custom-mt">
                            <button *ngIf="!quickQuiz" [disabled]="!onboardingQuizForm.valid || loading" type="submit"
                                class="btn btn-danger edit_account_save_changes">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="loading"></span>
                                Save Changes
                            </button>
                            <q-button *ngIf="quickQuiz" [disabled]="!onboardingQuizForm.valid || loading"
                               title="SAVE & CONTINUE" (click)="updateProfile();" [loading]="loading">
                            </q-button>
                        </div>

                        <!-- <label class="label vmt-4 w-100"><u>Style Quiz</u></label>
                        <p class="vfs-8">
                            Seasons change & so does our styles! You can retake the style quiz anytime by clicking the below button.
                        </p>
                        <div class="form-row justify-content-center vmt-3">
                            <a class="btn btn-outline-danger btn-round edit_account_retake_quiz" [routerLink]="['/style-quiz']" [queryParamsHandling]="'merge'">
                                Retake the style quiz
                            </a>
                        </div> -->

                        <!-- <ng-container *ngIf="user.paying_status_id !== 2 && user.has_stripe_acct">
                            <label class="label vmt-4 w-100"><u>Payment Information</u></label>
                            <p class="vfs-8">
                                If you'd like to update your payment information or cancel your premium membership, please click the below button.
                            </p>
                            <div class="form-row justify-content-center vmt-3"> 
                                <button (click)="updateBillingInfo()" class="btn btn-outline-danger btn-round edit_account_update_payment_info">
                                    Update Payment Info
                                </button>
                            </div>
                        </ng-container> -->

                        <div class="text-center bg-default vmt-4 custom-mt" *ngIf="user.paying_status_id !== 2 && user.activity_status_id !== 10 && !quickQuiz">
                            <div class="d-flex justify-content-end" *ngIf="(step === 'ACTION') && isResumed()"><button type="button" class="close vpr-1" (click)="updateStep('ASK_PREF')">&times;</button></div>
                            <div class="form-row {{step === 'ACTION' ? 'vpb-4' : 'vp-4'}}">
                                <div class="mx-auto q-page-header" *ngIf="(step === 'ASK_PREF') && isResumed()">
                                    <h3 class="text-muted q-page-header-sub-title">Need a break from Qatch?</h3>
                                    <a class="btn btn-link text-danger m-0 edit_account_pause_or_cancel_click" (click)="updateStep('ACTION')">
                                        <span class="spinner-border spinner-border-sm vmr-1" role="status" aria-hidden="true" *ngIf="isPauseClicked"></span>
                                        Pause OR Cancel
                                    </a>
                                </div>
                                <div class="mx-auto q-page-header" *ngIf="(step === 'ACTION') && isResumed()">
                                    <div class="d-flex" *ngIf="user.paying_status_id !== 5">
                                        <bSwitch [switch-on-color]="'danger'" [switch-off-color]="'danger'" [switch-off-text]="'No'"
                                        [switch-on-text]="'Yes'" formControlName="cancel_premium_membership" required>
                                        </bSwitch>
                                        <span class="text-start vml-1" (click)="toggleSwitch(cancel_premium_membership)">
                                            Do you also like to {{toBeUpdatedPayingStatus()}} Premium membership?
                                        </span>
                                    </div>

                                    <a class="btn btn-outline-danger btn-round vm-2 edit_account_pause_click" (click)="pauseMembership()">
                                        <span class="spinner-border spinner-border-sm vmr-1" role="status" aria-hidden="true"
                                            *ngIf="isPauseClicked"></span>
                                        Pause My Account
                                    </a>
                                    <h3 class="text-muted vm-1 q-page-header-sub-title">Your style preferences will be saved.</h3>
                                    <a class="btn btn-round btn-outline-danger vmt-3 edit_account_cancel_click" (click)="chooseCancelReason()">
                                        <span class="spinner-border spinner-border-sm vmr-1" role="status" aria-hidden="true"
                                            *ngIf="isSubscribeClicked"></span>
                                        No thanks, I want to cancel
                                    </a>
                                </div>
                                <div class="mx-auto q-page-header" *ngIf="!isResumed()">
                                    <div class="d-flex justify-content-center" *ngIf="user.paying_status_id !== 1">
                                        <bSwitch [switch-on-color]="'danger'" [switch-off-color]="'danger'" [switch-off-text]="'No'"
                                        [switch-on-text]="'Yes'" formControlName="restart_premium_membership" required>
                                        </bSwitch>
                                        <span class="text-start vml-1" (click)="toggleSwitch(restart_premium_membership)">
                                            Do you also like to {{toBeUpdatedPayingStatus(true)}} Premium membership?
                                        </span>
                                    </div>
                                    <a class="btn btn-link text-danger m-0 edit_account_resubscribe_click" (click)="resubscribe()">
                                        <span class="spinner-border spinner-border-sm vmr-1 edit_account_resubscribe_click" role="status" aria-hidden="true" *ngIf="isReSubscribeClicked"></span>
                                        ReSubscribe
                                    </a>
                                    <h3 class="text-muted mt-0 q-page-header-sub-title">Get inspired again!  Pick up exactly where you left off, we've got you 😉</h3>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
    </div>
</div>