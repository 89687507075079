import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { DeviceDetectorService } from "ngx-device-detector";
import { AppService } from "./app.service";
import { ToastService } from "./toast.service";

@Injectable({
  providedIn: 'root',
})
export class ProductService {

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private deviceService: DeviceDetectorService,
    private clipboardService: ClipboardService,
    private toastService: ToastService,
  ) {}

  referAProduct(product: any) {
    const title = `Qatch - Personalized Shopper at your fingertips`;
    const text = `Check out this amazing product in my Qatch recommendations! 😍`;
    const branded_link = `${product.branded_link}`;
    this.shareWithFriends(title, text, branded_link, 'Product link has been copied.');
  }

  shareWithFriends(title: string, text: string, branded_link: string, success_message?: string) {
    const isMobile = this.deviceService.isMobile();
    if (isMobile && navigator.share) {
        navigator.share({
          title: title,
          text: text,
          url: branded_link
        }).then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
        this.clipboardService.copy(`${text} ${branded_link}`);
        if(success_message) this.toastService.success(success_message);
    }
  }

  getProductsWithLoveCount(productIds: string[]) {
    return this.http.post(`${this.appService.getAppBaseUrl()}/inventory/loved-counts`, productIds);
  }
}
