<div class="container text-center">
  <h1 style="font-size: 1.5rem" class="preFlex flexIn text-theme-color">
    <svg class="Marquee-svg" fill="#f08e80" aria-hidden="true" style="height: 91px;width: 100%; overflow: visible;">
      <g class="Marquee-path-group" transform="translate(0, 1.55)">
        <path class="Marquee-path" fill="none" stroke="transparent" stroke-width="0"
          d="M-550,20.522000000000002  L-550,20.522000000000002 S-183.15,80.522 0,80.522 S366.85,20.522000000000002 550,20.522000000000002 S916.85,80.522 1100,80.522 S1466.85,20.522000000000002 1650,20.522000000000002"
          stroke-dasharray="159 26.6016 30 26.6016 146 26.6016 30 26.6016 137 26.6016 30 26.6016 162 26.6016 30 26.6016 112 26.6016 30 26.6016 144 26.6016 30 26.6016 "
          id="yui_3_17_2_1_1680889465002_5510" stroke-dashoffset="440"></path>
      </g>
      <text>
        <textPath class="Marquee-svg-text" href="#yui_3_17_2_1_1680889465002_5510" startOffset="-440">
          <ng-container *ngFor="let text of svgWaveText;">
            <tspan class="Marquee-svg-text" style="display: block;"> CLOTHING &nbsp;</tspan>
            <tspan class="Marquee-svg-text" style="display: block;"> 〰️ &nbsp;</tspan>
            <tspan class="Marquee-svg-text" style="display: block;"> JEWELRY &nbsp;</tspan>
            <tspan class="Marquee-svg-text" style="display: block;"> 〰️ &nbsp;</tspan>
            <tspan class="Marquee-svg-text" style="display: block;"> MAKEUP &nbsp;</tspan>
            <tspan class="Marquee-svg-text" style="display: block;"> 〰️ &nbsp;</tspan>
            <tspan class="Marquee-svg-text" style="display: block;"> SKINCARE &nbsp;</tspan>
            <tspan class="Marquee-svg-text" style="display: block;"> 〰️ &nbsp;</tspan>
            <tspan class="Marquee-svg-text" style="display: block;"> SHOES &nbsp;</tspan>
            <tspan class="Marquee-svg-text" style="display: block;"> 〰️ &nbsp;</tspan>
            <tspan class="Marquee-svg-text" style="display: block;"> LINGERIE &nbsp;</tspan>
            <tspan class="Marquee-svg-text" style="display: block;"> 〰️ &nbsp;</tspan>
          </ng-container>
          <animate attributeName="startOffset" from="0%" to="-100%" dur="50s" repeatCount="indefinite" type="rotate"
            begin="0s;" />
        </textPath>
      </text>
      <g class="Marquee-hitbox" data-hitbox="" aria-labelledby="ariaLabelyui_3_17_2_1_1680889465002_5510">
        <g class="Marquee-path-hitbox-group" transform="translate(0, -5.130500000000001)">
          <path class="Marquee-path-hitbox" fill="none" stroke="transparent"
            d="M-550,20.522000000000002  L-550,20.522000000000002 S-183.15,80.522 0,80.522 S366.85,20.522000000000002 550,20.522000000000002 S916.85,80.522 1100,80.522 S1466.85,20.522000000000002 1650,20.522000000000002"
            id="yui_3_17_2_1_1680889465002_5510" stroke-width="31"></path>
          <path class="Marquee-path-hitbox-focus-outline" fill="none" stroke="transparent" stroke-width="2px" d="
                  M-550,91
                   L-550,27.9 S-183.15,87.9 0,87.9 S366.85,27.9 550,27.9 S916.85,87.9 1100,87.9 S1466.85,27.9 1650,27.9
                  M-550,60
                   L-550,3.1 S-183.15,63.1 0,63.1 S366.85,3.1 550,3.1 S916.85,63.1 1100,63.1 S1466.85,3.1 1650,3.1
                " style="transform: translateY(3.1px);"></path>
        </g>
      </g>
    </svg>
  </h1>
</div>