import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'q-button',
  templateUrl: './q-button.component.html',
  styleUrls: ['./q-button.component.scss'],
  imports: [CommonModule, ButtonModule],
  standalone: true
})
export class QButtonComponent {
  @Input() type: 'button' | 'arrowButton' = 'button';
  @Input() title: string;
  @Input() icon: string;
  @Input() iconStyleClass: string;
  @Input() buttonStyleClass: string;
  @Input() isDesktop: boolean = true;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Output() onBtnClick = new EventEmitter();

  onClick(){
    if(!this.disabled) {
      this.onBtnClick.emit();
    }
  }

}
