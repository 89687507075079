<div [formGroup]="form" class="form-group" *ngIf="question && question.visible">
  <label class="label" [attr.for]="field" [ngClass]="spacing">{{question.label}}<span class="required" *ngIf="question.required"> *</span></label>
  <div [ngSwitch]="question.controlType" class="w-100">
    
    <div class="input-group form-group-no-border" *ngSwitchCase="'multi-select'">
      <ngb-dropdown [data]="question.options" [formControlName]="fieldName" class="w-100 bg-default shadow {{ getAnalyticClass(question) }}"
        [settings]="question.settings">
      </ngb-dropdown>
    </div>

    <div class="input-group form-group-no-border" *ngSwitchCase="'single-select'">
      <ngb-dropdown [data]="question.options" [formControlName]="fieldName" class="w-100 bg-default shadow {{ getAnalyticClass(question) }}"
        [settings]="question.settings">
      </ngb-dropdown>
    </div>

    <div class="form-group-no-border px-2 mb-4 {{ isDesktop ? 'w-100' : 'w-95' }}" *ngSwitchCase="'slider'">
      <nouislider class="slider slider-danger {{ getAnalyticClass(question) }}" [config]="question.settings" [formControl]="field"></nouislider>
    </div>

    <div class="form-group-no-border" *ngSwitchCase="'switch'">
      <bSwitch [switch-on-color]="'danger'" [switch-off-color]="'danger'" [switch-off-text]="'No'"
        [switch-on-text]="'Yes'" formControlName="fieldName">
      </bSwitch>
    </div>

    <div class="input-group form-group-no-border" *ngSwitchCase="'textbox'">
      <input type="{{question.type}}" class="form-control {{ getAnalyticClass(question) }}" [formControlName]="fieldName">
    </div>

    <div class="form-group-no-border" *ngSwitchCase="'multi-select-chip'">
      <q-chips [question]="question" [data]="question.options" class="{{ getAnalyticClass(question) }}" [formControlName]="fieldName"></q-chips>
    </div>

    <div class="form-group-no-border" *ngSwitchCase="'single-select-chip'">
      <q-chips [question]="question" [data]="question.options" class="{{ getAnalyticClass(question) }}" [singleSelection]="true" [formControlName]="fieldName"></q-chips>
    </div>

  </div>

  <uikit-error [submitted]="false" [controls]="form.controls" [name]="fieldName" placeholder="Value"></uikit-error>

</div>