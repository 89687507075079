<div *ngIf="visible && type=='full-page'" class="text-center q-loader full-page" [class.q-loader-wb]="background">
    <br><br><br><br>
    <span class="spinner-border spinner-border-sm" [class.mt-n5]="text" role="status" aria-hidden="true"></span>
    <ng-container *ngIf="text">
        <br/>
        <div>
            <span *ngIf="isMobile" class="w-80 d-block mx-auto text-dark">{{text}}</span>
            <h3 *ngIf="isDesktop" class="w-80 mx-auto text-dark">{{text}}</h3>
        </div>
    </ng-container>
    <br><br>
</div>

<div *ngIf="visible && type=='item'" class="text-center w-100 my-5">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <ng-container *ngIf="text">
        <br/>
        <span class="text text-dark">{{text}}</span>
    </ng-container>
</div>