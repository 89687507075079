import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/shared/services/app.service';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-body q-page-header text-center">
      <h5 class="q-page-header-sub-title">Looking for a more personal Qatch experience?</h5>
      <p class="mt-4">Take our style quiz to get matched with a stylist!</p>
      <p class="mt-2">You'll get weekly recommendations picked just for you, delivered right to your phone via text, for free.</p>
      <button type="button" class="btn btn-yellow mt-4" (click)="takeQuiz()">Let's Get Started</button>
    </div>
  `
})
export class TakeQuizModalContent {

  constructor(
    public activeModal: NgbActiveModal, 
    private appService: AppService
  ) { }

  takeQuiz() {
    this.activeModal.close(true);
    this.appService.navigateWithPreserve('/style-quiz');
  }
}