import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QatchComponent } from 'src/app/components/qatch.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AskForSignupModalContent } from '../../modal/ask-for-signup.modal';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent extends QatchComponent {
  @Input() index: any;
  @Input() data: any;
  @Input() maxShowIndex: number;
  @Input() type = 'style-product';
  @Input() page = '';
  @Input() pageType = null;
  @Input() pageOwnerId = null;
  @Input() uiTaggingKey = '';
  shareBtnIcon: string;
  shareBtn: any = {
    icon: 'bi-cursor-fill',
    text: `Share with friends`
  };
  loading = false;
  constructor(
    private userService: UserService,
    private toastService: ToastService,
    protected override deviceService: DeviceDetectorService,
    protected override authService: AuthService,
    private modalService: NgbModal
  ) {
    super(deviceService, authService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.evalshareBtnIcon();
  }

  goToLink() {
    if (this.data.availability_id === 2 || !this.data.affiliate_link) { return; }
    window.open(this.data.affiliate_link, '_blank');
    this.userService.logUserClick(this.data).toPromise();
  }

  addToQatch() {
    if (this.ifActionAllowed()) {
      this.userService.addToMyQatches(this.prepareAddToQatchRequestData()).subscribe(res => {
        this.data.added = this.data.added ? true : !this.data.added;
      }, (error) => {
        this.toastService.error((error.error && error.error.error) || `Couldn't be added to your Qatch. Please try again.`);
      }
      )
    }
  }

  async addToQatchWithReaction(reaction: any, event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if(this.loading || (reaction === this.data.reaction_type)) return;
    this.loading = true;
    if (this.ifActionAllowed()) {
      try {
        await this.userService.addToMyQatchWithReaction({
          reaction_type: reaction,
          ...this.prepareAddToQatchRequestData()
        }).toPromise();
        this.data.reaction_type = reaction;
        this.loading = false;
      } catch (err) {
        this.toastService.error(err, `Your reaction couldn't be updated.`);
        this.loading = false;
      } finally {
      }
    }
  }

  prepareAddToQatchRequestData() {
    return {
      product_id: this.data.product_id,
      retailer_id: this.data.retailer_id,
      affiliate_link: this.data.affiliate_link,
      queueing_method_detail: {
        "page": this.page,
        "page_type": this.pageType,
        "page_owner_id": this.pageOwnerId
      }
    }
  }

  ifActionAllowed() {
    if (this.isLoggedIn) {
      return true;
    } else {
      // this.askForSignup();
      return false;
    }
  }

  askForSignup() {
    const modalRef = this.modalService.open(AskForSignupModalContent, { centered: true });
    modalRef.result.then((result) => {
      if(result === true) { }
    }, (reason) => {
      this.loading = false;
    });
  }

  evalshareBtnIcon() {
    const date = new Date();
    if([10, 11].includes(date.getMonth())) {
        this.shareBtn.icon = 'bi-gift-fill';
        this.shareBtn.text = 'Tell someone you want this';
    }
  }

  getAnalyticClass(cls: any) {
    return this.uiTaggingKey ? `${this.uiTaggingKey.toLowerCase()}_product_${cls}` : '';
  }

  async shareWithFriends(product: any) {
    if(this.ifActionAllowed()) {
      this.userService.referAProduct(product);
    }
  }
}
