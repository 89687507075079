import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { interval } from 'rxjs';
import { User } from '../models/user';
import { LocalStorageService, STORAGE_CONSTANTS } from './storage.service';
import { HttpClient } from '@angular/common/http';
import { PRODUCT_CLICK_COUNT, PRODUCT_REACTION_CLICK_COUNT, ROLES } from 'src/app/app.const';
import { AppService } from './app.service';
import { applyActionCode, createUserWithEmailAndPassword, onAuthStateChanged, signInWithCustomToken, signInWithEmailAndPassword, signInWithPhoneNumber, signOut } from 'firebase/auth';
import { Auth, RecaptchaVerifier } from '@angular/fire/auth';
import { MagicLinkService } from './magiclink.service';
import { Logger } from '../utils/log-util';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HAvatar, StringUtil } from '@hss-m/uikit-common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  onProfileUpdate = new BehaviorSubject(false);
  defaultBrandSelected$ = new Subject();
  guideTour$ = new Subject();
  user$$ = new BehaviorSubject<User|null>(null);
  role$$ = new BehaviorSubject<string|null>(null);
  private QATCH_COOKIE = STORAGE_CONSTANTS.QATCH_COOKIE;
  private AUTH_ACTION = 'auth.action';
  private ACTIVE_ROLE = 'user.role';
  private baseUrl: string;
  private me$: Promise<any> = null;
  private token_verified$: Promise<any> = null;
  private _activeRole = '';
  private autoRefreshSubs: any;
  private user: any;
  private domainSuffix = environment.production ? "" : "-uat";

  constructor(
    private firebaseAuth: Auth,
    private storageService: LocalStorageService,
    private http: HttpClient,
    private appService: AppService,
    private magicLinkService: MagicLinkService,
    private router: Router
  ) {
    this.baseUrl = `${this.appService.getAppBaseUrl()}`;
    this._activeRole = this.storageService.get(this.ACTIVE_ROLE) || '';
  }

  authState(): Observable<User|null> {
    return new Observable((subscribe) => {
      onAuthStateChanged(this.firebaseAuth, (user: User|null) => {
        let userInfo: User|null = null;
        if (user) {
          userInfo = {
            email: user.email,
            uid: user.uid
          };
        } else {
          this.logOut();
        }
        subscribe.next(userInfo);
        this.user$$.next(userInfo);
      });
    });
  }

  currentUser() {
    return this.firebaseAuth.currentUser;
  }

  patchCurrentUser(user: any){
    this.user = user;
    this.user$$.next(user);
  }

  setAutoRefreshAuthToken() {
    // Refresh Auth token every 10(600000 - millis) minutes
    this.autoRefreshSubs = interval(600000).subscribe(() => {
      this.writeAuthToken();
    });
  }

  async writeAuthToken(): Promise<void> {
    const currentUser = await this.currentUser();
    if (currentUser) {
      Logger.debug(`Refreshed Auth Token at ${new Date()}`);
      const authToken = await currentUser.getIdToken(true);
      this.storeAuthToken(authToken);
    }
  }

  storeAuthToken(authToken: string): void {
    this.storageService.set(this.QATCH_COOKIE, authToken);
  }

  isLoggedIn(): boolean {
    return this.storageService.has(this.QATCH_COOKIE) //&& this.storageService.hasValue(this.AUTH_ACTION, this.AUTH_ACTION_LOGIN);
  }

  getAuthToken(): string {
    return this.storageService.get(this.QATCH_COOKIE) || '';
  }

  loginWithUser(username: string, password: string) {
    return signInWithEmailAndPassword(this.firebaseAuth, username, password);
  }

  logOut() {
    this.user = null;
    this.me$ = Promise.resolve(null);
    this._activeRole = '';
    this.storageService.remove(this.ACTIVE_ROLE);
    this.storageService.remove(this.QATCH_COOKIE);
    this.storageService.remove(this.AUTH_ACTION);
    this.magicLinkService.logout();
    this.autoRefreshSubs?.unsubscribe();
    return signOut(this.firebaseAuth);
  }

  signupWithEmailAndPassword(email: string, password: string) {
    return createUserWithEmailAndPassword(this.firebaseAuth, email, password);
  }

  async verifyEmail(oobCode: string) {
    return applyActionCode(this.firebaseAuth, oobCode);
  }

  async sendVerificationCodeOnMobile(phone: string, appVerifier: RecaptchaVerifier) {
    return signInWithPhoneNumber(this.firebaseAuth, phone, appVerifier);
  }

  confirmOTP(uri: string, data: any) {
    return this.http.post(`${this.baseUrl}/${uri}`, data);
  }

  checkIfNumberAuthenticated(data: any) {
    return this.http.post(`${this.baseUrl}/number-authenticated`, data);
  }

  sendOTP(data: any) {
    return this.http.post(`${this.baseUrl}/ota-token/generate`, data);
  }

  createCustomToken(data: any){
    return this.http.post(`${this.baseUrl}/create-custom-token`, data);
  }

  signInWithCustomToken(data: any) {
    return signInWithCustomToken(this.firebaseAuth, data.token);
  }

  me(reload?: boolean, queryParams={}): Promise<any> {
    if (reload) {
      this.me$ = null;
    }
    if (!this.me$) {
      this.me$ = lastValueFrom(this.http.get(`${this.baseUrl}/auth/me`, { params: queryParams })).then(
        (res: any) => {
          this.user = res;
          this.onProfileUpdate.next(true);
          localStorage.removeItem(PRODUCT_CLICK_COUNT);
          if(res['style_quiz_complete']) {
            localStorage.removeItem(PRODUCT_REACTION_CLICK_COUNT);
          }
          if (res.roles.includes(ROLES.SUPER_USER)) {
            res.roles = [ROLES.SUBSCRIBER, ROLES.ADMIN, ROLES.INFLUENCER]
          }
          this.user$$.next(res);
          return res;
        }
      );
    }
    return this.me$;
  }

  getUserAvatar() {
    const avatar: HAvatar = {};
    const user = this.user;
    if (!user) {
      return avatar;
    }
    if (user?.photoURL) {
      avatar.url = user.photoURL;
    } else {
      avatar.label = StringUtil.avatarLabel(user?.first_name);
    }
    return avatar;
  }

  async isAdmin() {
    return await this.hasRole(ROLES.ADMIN);
  }

  async isSubscriber() {
    return await this.hasRole(ROLES.SUBSCRIBER);
  }

  async isStylist() {
    return await this.hasRole(ROLES.STYLIST);
  }

  async isInfluencer() {
    return await this.hasRole(ROLES.STYLIST);
  }

  async hasRole(role: string) {
    const user = await this.me();
    return user.roles.includes(role);
  }

  verifySignupToken(queryParams: any) {
    if (!this.token_verified$) {
      this.token_verified$ = lastValueFrom(this.http.get(`${this.baseUrl}/verify-signup-token`, { params: queryParams }));
    }
    return this.token_verified$;

  }

  setActiveRole(role: string) {
    if(role === ROLES.PARTNER){
      this.redirectToPartnerConsole();
      return;
    }
    this.storageService.set(this.ACTIVE_ROLE, role);
    this._activeRole = role;
    this.role$$.next(role);
  }

  redirectToPartnerConsole(){
    this.appService.navigateToExternalUrl(`https://partners${this.domainSuffix}.joinqatch.com`, "_blank");
  }

  getActiveRole() {
    return this._activeRole;
  }

  getNextRoute(): string {
    // If login/Signup are via Poup from Collection related pages, do not allow to redirect
    if(this.router?.url.includes("discover/collections")){
      return this.router?.url;
    }

    const role = this.getActiveRole();
    // Redirect to Original uri from which we were redirected due to Signin page
    let redirectTo = this.storageService.pop(STORAGE_CONSTANTS.REDIRECT_TO_URL_ON_SIGNIN)
    if (redirectTo) {
      return redirectTo;
    }

    // IF workflows are present, send user to specific route to complete workflows
    if (this.user.workflows) {
      return this.user.workflows[0].path;
    }

    // For newly created accounts, redirect to thank-you
    const hasSignedUp = this.storageService.getFromAppCache(STORAGE_CONSTANTS.JUST_SIGNEDUP_EVENT, false);
    if (this.user?.first_login && hasSignedUp) {
      this.storageService.removeFromAppCache(STORAGE_CONSTANTS.JUST_SIGNEDUP_EVENT);
      return '/internal/thank-you';
    }

    // For next route if any
    const nextRoute = this.storageService.pop(STORAGE_CONSTANTS.NEXT_ROUTE);
    if (nextRoute) {
      return nextRoute;
    }

    if (role === ROLES.ADMIN) {
      return '/console/summary';
    } else if (role === ROLES.STYLIST) {
      return '/console/overview';
    }
    else if (role === ROLES.INFLUENCER) {
      return '/influencer/favorites';
    }
    else if (role === ROLES.SUBSCRIBER) {
      return '/internal/my-qatch';
    }
    else {
      return '/';
    }
  }

  toNextRoute() {
    const route = this.getNextRoute();
    this.appService.navigateWithPreserve(route);
  }

  /**
   * Could be null, use cautiosly
   */
  loggedInUser() {
    return this.user;
  }

  getDefaultBrand() {};

  onPersonalSettingsClick() {}
  
}
