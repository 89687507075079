import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { BlockUIModule } from 'primeng/blockui';
import { PanelModule } from 'primeng/panel';
import { SidebarModule } from 'primeng/sidebar';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { PrimeNGConfig } from 'primeng/api';

const PMODULES = [
  ButtonModule,
  CarouselModule,
  CardModule,
  DividerModule,
  BlockUIModule,
  PanelModule,
  SidebarModule,
  VirtualScrollerModule
];

@NgModule({
  imports: PMODULES,
  exports: PMODULES
})
export class PrimengModule {
  constructor(private primengConfig: PrimeNGConfig) {
    this.primengConfig.ripple = true;
  }
}
