<div class="page-header">
    <div class="container">
        <div class="row">
            <div class="mx-auto">
                <div class="card card-register shadow-none text-center px-2">
                    <ng-container *ngIf="isDesktop">
                        <h1 class="title mt-0 q-page-header-title">Verify Your Phone</h1>
                        <span class="underline-text-dark w-250px"></span>
                    </ng-container>
                    <label class="label vmt-3">
                        <p>
                            The verification code was sent to <span class="phone-number">{{maskedPhone}}</span>
                            <br/>
                            <small class="vmt-1">Not you? 
                                <a href="javascript:void(0);" class="btn txt-qatch-blue btn-link m-0 p-0 verify_number_verify_otp_back" (click)="previousPage()">
                                    <u>Click here</u>
                                </a> to update your number!
                            </small>
                        </p>
                    </label>
                    <form class="register-form vmt-3" [formGroup]="verifyForm" (ngSubmit)="submit()">
                        <div id="captcha-container"></div>

                        <div class="form-group-no-border">
                            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6,allowNumbersOnly:true}" class="mx-auto otp verify_number_verify_otp">
                            </ng-otp-input>
                        </div>

                        <div class="form-row justify-content-center">
                            <button [disabled]="!verifyForm.valid || loading || ploading" type="submit"
                                class="btn btn-danger btn-move-right verify_number_submit_otp_code" [class.btn-lg]="isDesktop">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="loading"></span>
                                    Verify Code
                            </button>
                        </div>

                        <div class="text-center">
                            <p class="vmt-3">
                                <label class="label">
                                    Didn’t receive a verification code?
                                    <br/>
                                    <a *ngIf="isDesktop; else resendCode" class="btn btn-neutral vmt-1 txt-dark verify_number_resend_verify_otp" (click)="sendVerificationCodeOnMobile()">
                                        <u>Resend Verification Code</u>
                                    </a>
                                    <ng-template #resendCode>
                                        <a class="btn vmt-1 btn-danger verify_number_resend_verify_otp">
                                            Resend Verification Code
                                        </a>
                                    </ng-template>
                                </label>
                            </p>

                            <p class="vmt-3">
                                <label class="label mt-0">
                                    Trouble Verifying?
                                    <ng-container *ngIf="isDesktop; else verificationByMobile">
                                        Scan this QR code to verify by text instead
                                        <br/>
                                        <img class="vmt-1" width="100" height="100" src="./assets/svg/text_to_verify_phone.svg"/>
                                    </ng-container>
                                    <ng-template #verificationByMobile>
                                        <br/>
                                        Try <a href="{{smsText}}" class="btn btn-link txt-qatch-blue m-0 p-0"><u>Verification By Text</u></a> instead
                                    </ng-template>
                                </label>
                            </p>
                            
                        </div>

                        <div class="text-center" *ngIf="showBackBtn">
                            <a (click)="previousPage()" class="btn btn-dark btn-link verify_number_verify_otp_back">
                                <u>Back</u>
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="top-left-img" *ngIf="isDesktop">
        <img src="./assets/img/organic/Shape-3.png" />
    </div>
    <div class="bottom-right-img" *ngIf="isDesktop">
        <img src="./assets/img/organic/Shape-4.png" />
    </div>
</div>