import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'src/app/shared/services/auth.service';
import { QatchComponent } from '../qatch.component';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent extends QatchComponent implements OnInit {
  @Input() title = '';
  @Input() description = '';
  constructor(
    protected override authService: AuthService,
    protected override deviceService: DeviceDetectorService,
  ) {
    super(deviceService, authService);
  }

  override ngOnInit() {
    super.ngOnInit();
  }

}
