<div class="input-group form-group-no-border filter-item d-flex justify-content-between" [class.individual-remote-filter]="isIndividual">
    <label *ngIf="filter?.label" class="label text-end" [title]="filter?.label" [innerText]="filter?.label" 
    [class.required-field]="filter.required"></label>
    <ngb-dropdown [data]="data" 
        (onSelect)="valueChange($event)" 
        (onDeSelect)="valueChange($event)"
        (onDeSelectAll)="valueChange($event)"
        [(ngModel)]="value"
        (onOpen)="onOpen()"
        class="bg-default shadow {{ isIndividual ? '' : 'w-200px' }} mr-n2" [settings]="dropdownSettings">
        <c-search *ngIf="filter.url">
            <ng-template>
                <div class="d-flex h-100">
                    <input type="text" (keyup)="onSearch($event)" placeholder="Search {{ filter.label }}" style="border: none;width: 100%; height: 100%;outline: none;"/>
                    <div class="my-auto mx-2">
                        <span class="spinner-border spinner-border-xs" role="status" aria-hidden="true" *ngIf="loading"></span>
                    </div>
                </div>
            </ng-template>
       </c-search>
    </ngb-dropdown>
</div>