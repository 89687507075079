import { environment } from 'src/environments/environment';

export class PhoneUtil {

    static maskPhone(phone_number: any) {
        phone_number = phone_number.toString();
        phone_number = PhoneUtil.withoutCountryCode(phone_number).replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        phone_number = !phone_number[2] ?
        phone_number[1] : '(' + phone_number[1] + ') ' + phone_number[2] + (phone_number[3] ? '-' + phone_number[3] : '');
        return phone_number.trim();
    }

    static getCountryCode() {
        const CountryCode = localStorage.getItem('COUNTRY_CODE');
        if(CountryCode){
            return CountryCode;
        }
        return environment.production ? `+1` : `+91`;
    }

    static extractPhone(phone: string, countryCode?: string) {
        phone = phone.toString();
        return phone ? phone.replace(countryCode ? countryCode : /\D/g, '') : '';
    }

    static withCountryCode(phone: string): string {
        phone = phone.toString();
        const withCountryCode =  phone.startsWith('+') ? phone : `${PhoneUtil.getCountryCode()}${phone}`;
        return withCountryCode.trim();
    }

    static withoutCountryCode(phone: string): string {
        if(!phone) return '';
        phone = phone.toString();
        return phone.replace(PhoneUtil.getCountryCode(), '').trim();
    }

    static isPhone(phone: string): boolean {
        phone = phone.toString();
        return /^[0-9]+$/.test(phone);
    }

    static emailFromPhone(phone: string): string {
        phone = phone.toString();
        return `${phone.trim()}@qatchcode.com`;
    }

    static getDevices() {
        return [
            {title: 'Apple', image_url: './assets/img/devices/AppleLogo.png', checked: false},
            {title: 'Android', image_url: './assets/img/devices/AndroidLogo.png', checked: false},
            {title: 'Others', image_url: './assets/img/devices/OtherDevice.png', checked: false}
        ];
    }
}
