import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { APP_CONFIG } from 'src/app/app.const';
import { FilterItem } from '../../filters.component';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  
  _filter: FilterItem;
  get filter(): FilterItem {
      return this._filter;
  }
  @Input() 
  set filter(value: FilterItem) {
    this._filter = value;
  }
  @Input() minDate = null;
  @Input() filtered: boolean;
  @Input() isIndividual: boolean;
  @Output() onChange = new EventEmitter();

  _value: any;
  get value(): any {
      return this._value;
  }
  @Input()
  set value(value: any) {
    // To init value using initData
    if (value && Array.isArray(value)) {
      value = value[0];
    }

    // Sets current date as def value in case field is not Nullable
    if(!value && this._filter.isNullable === false) {
      value = this.datePipe.transform(Date.now(), APP_CONFIG.DATES.DATE_FORMAT);
    }
    
    if (value && typeof value == 'object') {
      this._value = value;
    } else {
      const tokens = value ? value.split('-').map((tk: string) => parseInt(tk)) : [];
      this._value = tokens.length ? {year: tokens[0], month: tokens[1], day: tokens[2] } : null;
    }
  }

  constructor(
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {}

  valueChange(event: any) {
    const { year, month, day} = event;
    let sdate;
    if(year && month) {
      sdate = this.datePipe.transform(new Date(year, month-1, day), APP_CONFIG.DATES.DATE_FORMAT);
    } else {
      this.value = sdate = null;
    }
    this.onChange.next({ ...this._filter , value: sdate});
  }

}
